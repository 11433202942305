import { BET_TYPE } from '@/enums/betType';
import { ROUND_STATE } from '@/enums/roundState';
import { selectHasBetAtSeat, selectSeatBelongsToPlayer } from '@/store/selectors/gameSelectors';
import cl from 'clsx';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import styles from './BetPlaceholders.module.scss';

const TwentyOnePlusThree = ({ seatNumber, executeAddBet, getAreaDescription }) => {
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const mainBetsExists = useSelector(selectHasBetAtSeat(seatNumber, BET_TYPE.MAIN));
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const seatBelongsToPlayer = useSelector(selectSeatBelongsToPlayer(seatNumber, publicPlayerId));

  const twentyOnePlusThreeBetsExists = useSelector(selectHasBetAtSeat(seatNumber, BET_TYPE.TWENTYONE_PLUS_THREE));

  const { current: twentyOnePlusThreeId } = useRef(uuid());

  const isActive = useMemo(() => {
    if (mainBetsExists && seatBelongsToPlayer && roundState === ROUND_STATE.BETTING_PHASE) return true;

    return false;
  }, [roundState, mainBetsExists, seatBelongsToPlayer]);

  const isPulsing = useMemo(() => {
    if (isActive && !twentyOnePlusThreeBetsExists) return true;

    return false;
  }, [isActive, twentyOnePlusThreeBetsExists]);

  return (
    <>
      <path
        id={twentyOnePlusThreeId}
        className={cl('fieldBackground', isActive && 'active', isPulsing && 'pulsing')}
        d={getAreaDescription(BET_TYPE.TWENTYONE_PLUS_THREE)}
        onClick={() => executeAddBet(BET_TYPE.TWENTYONE_PLUS_THREE)}
      />
      <text className={styles.betText} fontSize={12} dy="-6">
        <textPath
          data-placeholder="twentyOnePlusThreeRefPoint"
          xlinkHref={`#${twentyOnePlusThreeId}`}
          startOffset="18px"
          textLength="40"
        >
          21+3
        </textPath>
      </text>
    </>
  );
};

export default TwentyOnePlusThree;
