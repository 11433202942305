import { DECISION_TYPE } from '@/enums/decisionType';
import { getHandValues, isBlackjack, isBust } from './cardService';

export const isPlayed = (hand) => {
  const { cards, lastDecision } = hand;
  const handPlayedDecisionTypes = [DECISION_TYPE.DOUBLE, DECISION_TYPE.STAND, DECISION_TYPE.TIMEOUT_STAND];

  if (cards?.length < 2) return false;

  if (
    isBlackjack(cards) ||
    isBust(cards) ||
    handPlayedDecisionTypes.includes(lastDecision) ||
    getHandValues(cards).includes(21)
  ) {
    return true;
  }

  return false;
};
