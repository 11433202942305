export const getInnerColor = (value) => {
  if (value >= 1 && value < 2) return '#5b5b5b';
  if (value >= 2 && value < 5) return '#cc68ab';
  if (value >= 5 && value < 25) return '#ad000d';
  if (value >= 25 && value < 100) return '#009245';
  if (value >= 100 && value < 500) return '#000000';
  if (value >= 500 && value < 1000) return '#8800c1';
  return '#ff7300';
};

export const getOuterColor = (value) => {
  if (value >= 1 && value < 2) return '#757575';
  if (value >= 2 && value < 5) return '#ff82d6';
  if (value >= 5 && value < 25) return '#db0000';
  if (value >= 25 && value < 100) return '#39b54a';
  if (value >= 100 && value < 500) return '#000000';
  if (value >= 500 && value < 1000) return '#a700ff';
  return '#ff9d00';
};
