import Card from '../Card';
import { motion } from 'framer-motion';
import cl from 'clsx';
import styles from './Hands.module.scss';
import { DECISION_TYPE } from '@/enums/decisionType';

const Hands = ({ hands, isSimulation }) => {
  const cardAnimations = {
    init: ({ index, isDoubled }) => ({
      x: index === 2 && isDoubled ? `${-0.2 * index}rem` : `${index * 1.2}rem`,
      y: `calc(${index * -1.5}rem - 110%)`,
    }),
    animate: ({ index, isDoubled }) => ({
      x: index === 2 && isDoubled ? `${-0.1 * index}rem` : `${index * 1}rem`,
      y: `calc(${index * -1.5}rem - 100%)`,
    }),
  };

  const isHandDoubled = (hand) => hand?.lastDecision === DECISION_TYPE.DOUBLE;

  return (
    <div className={cl(styles.hands, hands.length > 1 && styles.splitted)}>
      {hands &&
        hands?.map((hand, index) => (
          <div key={hand.handId} className={styles.hand} data-cy={`hand_${hand.seatNumber}_${index}`}>
            {isSimulation && (
              <div className={styles.cardContainer}>
                {hand.cards.map(({ cardId, suit, rank }, index) => (
                  <motion.div
                    key={cardId}
                    variants={cardAnimations}
                    initial="init"
                    animate="animate"
                    custom={{ index, isDoubled: isHandDoubled(hand) }}
                    className={styles.card}
                  >
                    <Card suit={suit} rank={rank} />
                  </motion.div>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default Hands;
