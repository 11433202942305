import { SOUND_TYPE } from '@/enums/sound';
import { formatMoney } from '@/lib/moneyService';
import { selectPlayerTotalBet } from '@/store/selectors/gameSelectors';
import { openLowBalancePopup } from '@/store/uiSlice';
import soundService from '@/lib/soundService';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import LowBalancePopup from '../LowBalancePopup';
import styles from './Wallet.module.scss';

const Wallet = () => {
  const balanceRef = useRef();
  const { t } = useTranslation('wallet');
  const balance = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.user.balance);
  const totalBet = useSelector(selectPlayerTotalBet);
  const roundId = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundId);
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const [renderLowBalancePopup, setRenderLowBalancePopup] = useState(false);
  const dispatch = useDispatch();
  const prevRoundId = useRef(roundId);

  useEffect(() => {
    if (balanceRef.current) {
      setRenderLowBalancePopup(true);
    }
  }, [balanceRef]);

  useEffect(() => {
    if (!selectedChip && prevRoundId.current !== roundId) {
      soundService.playSound(SOUND_TYPE.OUT_OF_MONEY);
      dispatch(openLowBalancePopup());
      prevRoundId.current = roundId;
    }
  }, [balance, dispatch, roundId, selectedChip]);

  return (
    <>
      {renderLowBalancePopup && <LowBalancePopup referenceElement={balanceRef.current} />}
      <div className={styles.wallet}>
        <Button ref={balanceRef} className={styles.button}>
          <div className={styles.label}>{t('balance')}</div>
          <div className={styles.amount}>{formatMoney(balance)}</div>
        </Button>
        <Button className={styles.button}>
          <div className={styles.label}>{t('bet')}</div>
          <div className={styles.amount}>{formatMoney(totalBet)}</div>
        </Button>
      </div>
    </>
  );
};

export default Wallet;
