import styles from './StreamView.module.scss';
import { useSelector } from 'react-redux';
import RTCPlayer from '@/components/RTCPlayer';

const StreamView = () => {
  const { domain, streamId } = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.settings.stream
  );

  return (
    <div className={styles.stream}>
      {streamId && <RTCPlayer domain={domain} streamId={streamId} isCovered={true} />}
      {!streamId && (
        <img src="/images/fake-stream.png" alt="fake stream" placeholder="blur" style={{ objectFit: 'cover' }} />
      )}
    </div>
  );
};

export default StreamView;
