const WinCounter = ({ count = 0 }) => {
  return (
    <svg viewBox="0 0 100.23 129.45">
      <defs>
        <style>{'.awardPath{fill:#ffda00}'}</style>
      </defs>
      <g>
        <g>
          <path
            d="M50.37 0c2.76 1.87 5.51 3.76 8.3 5.6a1.84 1.84 0 0 0 1.19 0c2.79-.51 5.59-1 8.37-1.61a1.47 1.47 0 0 1 1.83.74c1.49 2.3 3 4.6 4.63 6.82a3.53 3.53 0 0 0 1.84 1.24c2.71.65 5.46 1.18 8.2 1.7A1.32 1.32 0 0 1 86 15.77c.49 2.65 1.12 5.28 1.59 7.94A3.15 3.15 0 0 0 89.1 26c2.24 1.43 4.39 3 6.63 4.42a1.51 1.51 0 0 1 .75 1.85c-.54 2.55-1 5.12-1.54 7.67a3.31 3.31 0 0 0 .53 2.86c1.51 2.13 2.85 4.37 4.36 6.5a1.64 1.64 0 0 1 0 2.2c-1.51 2.18-3 4.38-4.36 6.66A3.91 3.91 0 0 0 95 60.6c.4 2.62 1 5.22 1.51 7.82a1.58 1.58 0 0 1-.77 2c-2.29 1.44-4.47 3-6.75 4.49A2.9 2.9 0 0 0 87.58 77c-.51 2.65-1.12 5.29-1.61 7.94a1.35 1.35 0 0 1-1.29 1.32c-2.7.49-5.4 1-8.06 1.68a3.72 3.72 0 0 0-2 1.3c-1.59 2.18-3.09 4.43-4.54 6.71a1.6 1.6 0 0 1-1.95.82c-2.6-.56-5.21-1-7.81-1.57a3 3 0 0 0-2.6.52c-2.25 1.57-4.59 3-6.86 4.57a1.19 1.19 0 0 1-1.6 0c-2.31-1.57-4.63-3.14-7-4.59a3.68 3.68 0 0 0-2.3-.46q-3.87.58-7.68 1.49a1.89 1.89 0 0 1-2.37-1c-1.39-2.21-2.85-4.38-4.4-6.48a3.7 3.7 0 0 0-1.95-1.29c-2.66-.66-5.36-1.19-8.06-1.68a1.38 1.38 0 0 1-1.29-1.33c-.51-2.75-1.06-5.49-1.7-8.21a3 3 0 0 0-1.08-1.64c-2.31-1.65-4.67-3.23-7-4.78a1.32 1.32 0 0 1-.67-1.61c.59-2.78 1.14-5.57 1.6-8.37A3 3 0 0 0 5 58.39c-1.47-2.37-3-4.7-4.61-7a1.4 1.4 0 0 1 0-1.85c1.58-2.3 3.13-4.63 4.61-7a3.07 3.07 0 0 0 .38-1.92c-.45-2.75-1-5.5-1.57-8.23a1.57 1.57 0 0 1 .81-1.93c2.35-1.51 4.67-3.08 6.94-4.7a2.77 2.77 0 0 0 1-1.53c.64-2.76 1.2-5.55 1.71-8.34a1.5 1.5 0 0 1 1.42-1.39c2.75-.49 5.49-1 8.21-1.69a3.28 3.28 0 0 0 1.73-1.17c1.6-2.23 3.12-4.53 4.62-6.83A1.47 1.47 0 0 1 32 4c2.82.62 5.67 1.13 8.51 1.64a2.05 2.05 0 0 0 1.3-.17c2.76-1.8 5.5-3.66 8.24-5.5Zm38.89 50.74a38.86 38.86 0 1 0-39 38.77c21.32.2 38.82-17.2 39-38.77Zm-5.26 0a33.6 33.6 0 1 0-33.73 33.51A33.64 33.64 0 0 0 84 50.73Z"
            className="awardPath"
          />
          <path
            d="M23.23 129.45c0-1.16-.06-2.32-.06-3.49v-27a3.38 3.38 0 0 1 .3-1.63c.89 1.31 1.86 2.58 2.63 4a1.86 1.86 0 0 0 2.38 1c3.33-.69 6.67-1.34 10-1.92a3 3 0 0 1 1.91.39c2.91 1.84 5.8 3.73 8.63 5.69a1.66 1.66 0 0 0 2.2 0c2.77-1.89 5.61-3.67 8.37-5.57a2.91 2.91 0 0 1 2.46-.49c3.38.7 6.77 1.3 10.14 2a1.39 1.39 0 0 0 1.7-.73c1.43-2.21 2.93-4.37 4.62-6.87v34.39c-.5-.23-1-.43-1.38-.66q-12.39-6.56-24.8-12.98a2.27 2.27 0 0 0-2.43 0q-12.55 6.56-25.16 13a14.6 14.6 0 0 0-1.23.82Z"
            className="awardPath"
          />
          <text x="50%" y="40%" fontSize="55" fontWeight={800} dominantBaseline="central" textAnchor="middle">
            {count}
          </text>
        </g>
      </g>
    </svg>
  );
};

export default WinCounter;
