import { createSlice } from '@reduxjs/toolkit';
import { setUser } from '@sentry/react';
import { Logger } from '@vpmedia/simplify';
import { setPayout } from './gameSlice';

const logger = new Logger('userSlice');

/**
 * @typedef {object} UserState - TBD.
 * @property {boolean} isSocketConnected - TBD.
 * @property {boolean} isUserAuthenticated - TBD.
 * @property {boolean} isUserConnected - TBD.
 * @property {string} publicPlayerId - TBD.
 * @property {number} balance - TBD.
 * @property {string} screenName - TBD.
 */

/** @type {UserState} */
const initialState = {
  isSocketConnected: false,
  isUserAuthenticated: false,
  isUserConnected: false,
  publicPlayerId: null,
  balance: 0,
  screenName: null,
};

export const handlePayoutThunk =
  ({ results }) =>
  (dispatch, getState) => {
    /** @type {{ user: import('./userSlice').UserState }} */
    const { user: userState } = getState();

    let sumPayout = 0;
    for (const resultItem of results) {
      const { prize, bet, publicPlayerId } = resultItem;
      if (publicPlayerId === userState.publicPlayerId && prize > 0) {
        sumPayout += prize + bet;
      }
    }

    dispatch(increaseBalance(sumPayout));

    if (sumPayout > 0) {
      dispatch(setPayout(sumPayout));
    }
  };

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {UserState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<{publicPlayerId: string, balance: number}>} action - TBD.
     */
    initUser: (state, action) => {
      const { publicPlayerId, balance } = action.payload;
      state.publicPlayerId = publicPlayerId;
      state.balance = balance;
      state.isUserAuthenticated = true;
      setUser({ username: publicPlayerId });
    },
    /**
     * TBD.
     * @param {UserState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - TBD.
     */
    setScreenName: (state, action) => {
      state.screenName = action.payload;
    },
    /**
     * TBD.
     * @param {UserState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<number>} action - TBD.
     */
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    /**
     * TBD.
     * @param {UserState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<number>} action - TBD.
     */
    increaseBalance: (state, action) => {
      state.balance += action.payload;
    },
    /**
     * TBD.
     * @param {UserState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<number>} action - TBD.
     */
    decreaseBalance: (state, action) => {
      state.balance -= action.payload;
    },
    /**
     * TBD.
     * @param {UserState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - TBD.
     */
    setIsSocketConnected: (state, action) => {
      logger.info('setIsSocketConnected', { isSocketConnected: action.payload });
      state.isSocketConnected = action.payload;
    },
    /**
     * TBD.
     * @param {UserState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setIsUserAuthenticated: (state, action) => {
      logger.info('setIsUserAuthenticated', { isUserAuthenticated: action.payload });
      state.isUserAuthenticated = action.payload;
    },
    /**
     * TBD.
     * @param {UserState} state - State data.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - Store action.
     */
    setIsUserConnected: (state, action) => {
      logger.info('setIsUserConnected', { isUserAuthenticated: action.payload });
      state.isUserConnected = action.payload;
    },
  },
});

export const {
  initUser,
  setScreenName,
  increaseBalance,
  decreaseBalance,
  setBalance,
  setIsSocketConnected,
  setIsUserAuthenticated,
  setIsUserConnected,
} = userSlice.actions;

export default userSlice.reducer;
