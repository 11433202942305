import { SOUND_TYPE } from '@/enums/sound';
import soundService from '@/lib/soundService';
import { useCallback } from 'react';
import styles from './Checkbox.module.scss';

const Checkbox = ({ label, value, onChange, ...props }) => {
  const handleOnChange = useCallback(
    (checked) => {
      soundService.playSound(SOUND_TYPE.PRESS);
      onChange(checked);
    },
    [onChange]
  );

  return (
    <label className={styles.container} {...props}>
      <input
        type="checkbox"
        name="checkbox"
        checked={value}
        onChange={(event) => handleOnChange(event.target.checked)}
      />
      {label}
    </label>
  );
};

export default Checkbox;
