import { SOUND_TYPE } from '@/enums/sound';
import soundService from '@/lib/soundService';
import cl from 'clsx';
import { useCallback } from 'react';
import styles from './IconButton.module.scss';

const IconButton = ({ icon, onClick, disabled }) => {
  const handleOnClick = useCallback(() => {
    if (disabled || !onClick || typeof onClick !== 'function') return;
    soundService.playSound(SOUND_TYPE.PRESS);
    onClick();
  }, [disabled, onClick]);

  return (
    <div className={cl(styles.container, disabled && styles.disabled)} onClick={handleOnClick}>
      {icon}
    </div>
  );
};

export default IconButton;
