import { useModal } from '@/context/ModalContext';
import socketApi from '@/lib/socketApi';
import { setScreenName } from '@/store/settingsSlice';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import TextInput from '../../../TextInput';
import styles from './ScreenNameModalView.module.scss';

const ScreenNameModalView = () => {
  const { t } = useTranslation('common');
  const settings = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.settings);
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const maxLength = 20;
  const { screenName } = settings || {};

  const handleScreenNameChanged = useCallback(
    (event) => {
      const name = event.target.value;
      if (maxLength < name.length) return;
      dispatch(setScreenName(name));
    },
    [dispatch]
  );

  const handleFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      closeModal();
      await socketApi.savePlayerSettings(settings);
    },
    [closeModal, settings]
  );

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleFormSubmit}>
        <div>{t('screenName.title')}</div>
        <TextInput value={screenName ?? ''} onChange={handleScreenNameChanged} maxLength={maxLength} autoFocus={true} />
        <Button disabled={!screenName} text="OK" className={styles.submitButton} />
      </form>
    </div>
  );
};

export default ScreenNameModalView;
