import { BET_TYPE } from '@/enums/betType';
import { ROUND_STATE } from '@/enums/roundState';
import { selectHasBetAtSeat, selectSeatBelongsToPlayer } from '@/store/selectors/gameSelectors';
import cl from 'clsx';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import styles from './BetPlaceholders.module.scss';

const PerfectPairs = ({ seatNumber, executeAddBet, getAreaDescription }) => {
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const mainBetsExists = useSelector(selectHasBetAtSeat(seatNumber, BET_TYPE.MAIN));
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const seatBelongsToPlayer = useSelector(selectSeatBelongsToPlayer(seatNumber, publicPlayerId));
  const perfectPairsBetsExists = useSelector(selectHasBetAtSeat(seatNumber, BET_TYPE.PERFECT_PAIRS));
  const { current: perfectPairsId } = useRef(uuid());

  const isActive = useMemo(() => {
    if (mainBetsExists && seatBelongsToPlayer && roundState === ROUND_STATE.BETTING_PHASE) return true;

    return false;
  }, [roundState, mainBetsExists, seatBelongsToPlayer]);

  const isPulsing = useMemo(() => {
    if (isActive && !perfectPairsBetsExists) return true;

    return false;
  }, [isActive, perfectPairsBetsExists]);

  return (
    <>
      <path
        id={perfectPairsId}
        className={cl('fieldBackground', isActive && 'active', isPulsing && 'pulsing')}
        d={getAreaDescription(BET_TYPE.PERFECT_PAIRS)}
        onClick={() => executeAddBet(BET_TYPE.PERFECT_PAIRS)}
      />
      <text data-placeholder="perfectPairsRefPoint" className={styles.betText} fontSize={6} dy="-12">
        <textPath xlinkHref={`#${perfectPairsId}`} startOffset="12px" textLength="50">
          Perfect
        </textPath>
      </text>
      <text className={styles.betText} fontSize={9} dy="-3">
        <textPath xlinkHref={`#${perfectPairsId}`} startOffset="16px" textLength="40">
          Pairs
        </textPath>
      </text>
    </>
  );
};

export default PerfectPairs;
