import { SOUND_TYPE } from '@/enums/sound';
import soundService from '@/lib/soundService';
import { forwardRef, useCallback } from 'react';
import styles from './Button.module.scss';

const ButtonComponent = ({ text, className, children, onClick, ...rest }, ref) => {
  const handleOnClick = useCallback(() => {
    soundService.playSound(SOUND_TYPE.PRESS);
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <button ref={ref} className={`${styles.button} ${className}`} onClick={handleOnClick} {...rest}>
      {text}
      {children}
    </button>
  );
};

const Button = forwardRef(ButtonComponent);

export default Button;
