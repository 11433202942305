import cl from 'clsx';
import styles from './BetPlaceholderChip.module.scss';

const BetPlaceholderChip = ({ isPulsing, ...props }) => {
  const pathClassNames = cl(styles.chipPath, isPulsing && styles.pulsing);

  return (
    <svg viewBox="0 0 357.08 356.89" style={{ pointerEvents: 'none' }} {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g className="cls-1">
            <path
              d="M65.85 249s16.07 26.42 42.68 41.91L85 330.31s-37.22-20.37-58.38-58.39Z"
              className={pathClassNames}
            />
          </g>
          <g className="cls-1">
            <path d="M114.55 62s-27.31 14.52-44.32 40.18l-38-25.82s22.49-36 61.67-54.9Z" className={pathClassNames} />
          </g>
          <g className="cls-1">
            <path
              d="M287.78 103.08s-17.22-25.7-44.47-40l21.82-40.4S303.21 41.39 326 78.44Z"
              className={pathClassNames}
            />
          </g>
          <g className="cls-1">
            <path
              d="M247.42 292s26.64-15.72 42.48-42.11L329 274s-20.86 36.94-59.16 57.59Z"
              className={pathClassNames}
            />
          </g>
          <path
            d="m134.27 5.73 11.93 44.34a111.9 111.9 0 0 1 21.42-3.73L162.25.57a155.7 155.7 0 0 0-27.98 5.16ZM216.82 4.36A139.21 139.21 0 0 0 189.36 0l-4.08 46.07A130.94 130.94 0 0 1 206 48.53ZM350.55 132l-44.17 12.53A111 111 0 0 1 310.3 165l45.64-6.3a154.48 154.48 0 0 0-5.39-26.7ZM310.93 182.62a132.92 132.92 0 0 1-2.21 21.67L353 214.52a141.32 141.32 0 0 0 4-28.76ZM145.27 307.1l-12.72 43.67a140.66 140.66 0 0 0 28.24 5.64l5.81-45.89a132.36 132.36 0 0 1-21.33-3.42ZM215.09 353l-10-44.81a111.28 111.28 0 0 1-20.82 2.77l3.64 45.93a154 154 0 0 0 27.18-3.89ZM49.42 146.77 5.61 134.54A140.7 140.7 0 0 0 .3 162.83l46 5.3a132 132 0 0 1 3.12-21.36ZM4.36 217.1l44.69-10.51a111.84 111.84 0 0 1-3-20.8L.16 190a154.64 154.64 0 0 0 4.2 27.1Z"
            className={pathClassNames}
          />
          <circle
            cx={178.42}
            cy={178.47}
            r={178.42}
            className="cls-1"
            style={{
              fill: 'none',
            }}
          />
          <path
            d="M178.42 59.83a118.64 118.64 0 1 0 118.63 118.64A118.77 118.77 0 0 0 178.42 59.83Zm0 235.35a116.71 116.71 0 1 1 116.7-116.71 116.71 116.71 0 0 1-116.7 116.71Z"
            className={pathClassNames}
          />
          <path
            d="M178.42 61.76a116.71 116.71 0 1 0 116.7 116.71 116.71 116.71 0 0 0-116.7-116.71Zm0 231.49A114.78 114.78 0 1 1 293.2 178.47a114.91 114.91 0 0 1-114.78 114.78Z"
            className={pathClassNames}
          />
        </g>
      </g>
    </svg>
  );
};

export default BetPlaceholderChip;
