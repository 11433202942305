import { USER_TYPE } from '@/enums/userType';
import socketApi from '@/lib/socketApi';
import { createSlice } from '@reduxjs/toolkit';
import { Logger } from '@vpmedia/simplify';
import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {object} ChatMessage
 * @property {string} chatId - UUIDv4 identifier.
 * @property {string} message - Message text.
 * @property {string} screenName - Screen name.
 * @property {string} userId - Like 'GR_1'.
 * @property {string} userType - Enum player, dealer, or pitBoss.
 * @property {boolean} [isHidden] - Do not broadcast message to other players.
 */

/**
 * @typedef {object} ChatState - TBD.
 * @property {string} inputMessage - TBD.
 * @property {ChatMessage[]} messages - TBD.
 */

const logger = new Logger('chatSlice');

export const addNewMessageThunk =
  ({ message }) =>
  async (dispatch, getState) => {
    /** @type {{ user: import('./userSlice').UserState, settings: import('./settingsSlice').SettingsState }} */
    const { user: userState, settings: settingsState } = getState();
    const { publicPlayerId } = userState;
    const { screenName } = settingsState;

    const payload = {
      chatId: uuidv4(),
      userId: publicPlayerId,
      userType: USER_TYPE.PLAYER,
      screenName,
      message,
    };

    dispatch(addNewMessage(payload));
    await socketApi.sendMessage(payload);
  };

/** @type {ChatState} */
const initialState = {
  inputMessage: '',
  messages: [],
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {ChatState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - TBD.
     */
    setInputMessage: (state, action) => {
      state.inputMessage = action.payload;
    },
    /**
     * TBD.
     * @param {ChatState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<ChatMessage>} action - TBD.
     */
    addNewMessage: (state, action) => {
      const newMessage = action.payload;
      state.messages.push(newMessage);
    },
  },
});

export const { setInputMessage, addNewMessage } = chatSlice.actions;

export default chatSlice.reducer;
