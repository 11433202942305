import TableText from '@/assets/svg/table-text.svg?react';
import Logo from '@/assets/svg/logo.svg?react';
import styles from './TableFigure.module.scss';

const TableFigure = (props) => {
  return (
    <div className={styles.table}>
      {/* <Logo className={styles.logo} /> */}
      <TableText className={styles.text} />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1440 600"
        {...props}
      >
        <defs>
          <filter id="a" width="101.7%" height="105.7%" x="-.8%" y="-2.9%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation={4} />
          </filter>
          <filter id="c" width="102.5%" height="106%" x="-1.3%" y="-3%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius={2} result="shadowSpreadOuter1" />
            <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={4} />
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          </filter>
          <filter id="f" width="102.2%" height="105.2%" x="-1.1%" y="-2.6%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" radius={2} result="shadowSpreadInner1" />
            <feGaussianBlur in="shadowSpreadInner1" result="shadowBlurInner1" stdDeviation={2} />
            <feOffset dy={1} in="shadowBlurInner1" result="shadowOffsetInner1" />
            <feComposite
              in="shadowOffsetInner1"
              in2="SourceAlpha"
              k2={-1}
              k3={1}
              operator="arithmetic"
              result="shadowInnerInner1"
            />
            <feColorMatrix
              in="shadowInnerInner1"
              result="shadowMatrixInner1"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feOffset dy={-4} in="SourceAlpha" result="shadowOffsetInner2" />
            <feComposite
              in="shadowOffsetInner2"
              in2="SourceAlpha"
              k2={-1}
              k3={1}
              operator="arithmetic"
              result="shadowInnerInner2"
            />
            <feColorMatrix
              in="shadowInnerInner2"
              result="shadowMatrixInner2"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feOffset dy={3} in="SourceAlpha" result="shadowOffsetInner3" />
            <feComposite
              in="shadowOffsetInner3"
              in2="SourceAlpha"
              k2={-1}
              k3={1}
              operator="arithmetic"
              result="shadowInnerInner3"
            />
            <feColorMatrix
              in="shadowInnerInner3"
              result="shadowMatrixInner3"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feMerge>
              <feMergeNode in="shadowMatrixInner1" />
              <feMergeNode in="shadowMatrixInner2" />
              <feMergeNode in="shadowMatrixInner3" />
            </feMerge>
          </filter>
          <filter id="h" width="101.3%" height="105.8%" x="-.7%" y="-2.9%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation={3} />
          </filter>
          <linearGradient id="e" x1="100%" x2="20%" y1="20%" y2="60%" gradientTransform="rotate(10)">
            <stop offset="0%" stopColor="#8D7A48" />
            <stop offset="35%" stopColor="#A99765" />
            <stop offset="65%" stopColor="#A99765" />
            <stop offset="100%" stopColor="#8D7A48" />
          </linearGradient>
          <linearGradient id="g" x1="0%" y1="3.25%" y2="0%">
            <stop offset="0%" stopColor="#FFF" stopOpacity={0} />
            <stop offset="20%" stopColor="#FFF" />
            <stop offset="45%" stopColor="#FFF" stopOpacity={0} />
            <stop offset="55%" stopColor="#FFF" stopOpacity={0} />
            <stop offset="80%" stopColor="#FFF" />
            <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
          </linearGradient>
          <radialGradient
            id="b"
            cy="26.14%"
            r="72.81%"
            fx="50%"
            fy="26.14%"
            gradientTransform="matrix(0 1 -.66823 0 .67 -.24)"
          >
            <stop offset="0%" stopColor="#35A953" />
            <stop offset="100%" stopColor="#359750" />
          </radialGradient>
          <path
            id="d"
            d="M720 490C276 490 46 336.76 46 150c0-49.31 36.8-108.03 75.91-130.06h1196.18C1357.2 41.97 1394 100.69 1394 150c0 186.76-230 340-674 340zM104 0C62 24 0 94.92 0 184c0 226 250 416 720 416s720-190 720-416c0-89.08-62-160-104-184H104z"
          />
        </defs>
        <g fill="none">
          <path
            fill="url(#b)"
            d="M122 18c-40 22-78 82-78 132 0 186 226 342 676 342s676-156 676-342c0-50-39-110-78-132H122z"
          />
          <use xlinkHref="#d" fill="#000" filter="url(#c)" />
          <use xlinkHref="#d" fill="url(#e)" />
          <use xlinkHref="#d" fill="#000" filter="url(#f)" />
          <path
            fill="url(#g)"
            d="M730 561c336 0 612-116 680-308-34 62-32 92-146 166S943.47 551 730 551c-213.47 0-434-58-554-132S64 315 30 253c50 192 364 308 700 308z"
            filter="url(#h)"
            opacity={0.3}
          />
        </g>
      </svg>
    </div>
  );
};

export default TableFigure;
