import cl from 'clsx';
import { motion } from 'framer-motion';
import styles from './DecisionPanelButton.module.scss';

const DecisionPanelButton = ({
  action,
  lastDecision,
  onClick,
  disabled,
  icon,
  text,
  colorClass,
  dataCy,
  size = 'large',
}) => {
  const buttonAnimations = {
    hidden: {
      opacity: 0,
      transition: { duration: 0.5 },
    },
    visible: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
    exit: (action) => {
      if (lastDecision === action) {
        return {
          opacity: 0,
          scale: 1.5,
          y: '-3rem',
          transition: { duration: 1 },
        };
      }
      return {
        opacity: 0,
        y: '1.5rem',
        transition: { duration: 0.5 },
      };
    },
  };

  return (
    <button
      // initial="hidden"
      // animate="visible"
      // exit="exit"
      // custom={action}
      // variants={buttonAnimations}
      data-cy={dataCy}
      className={cl(styles.button, styles[colorClass], size === 'small' && styles.small)}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {text && <div className={styles.text}>{text}</div>}
    </button>
  );
};

export default DecisionPanelButton;
