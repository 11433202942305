import { MENU_VIEW } from '@/enums/ui';
import { closeSlidePopup, openSlidePopup, setCompactViewOpened, setSlidePopupView, toggleSound } from '@/store/uiSlice';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GoHistory } from 'react-icons/go';
import { IoMdChatboxes, IoMdSettings } from 'react-icons/io';
import { MdEmojiPeople, MdOutlineQuestionMark } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '../IconButton';
import SlidePopupUI from '../SlidePopupUI';
import SoundIndicator from '../SoundIndicator';
import Tooltip from '../Tooltip/Tooltip';
import styles from './MenuButtons.module.scss';

const MenuButtons = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const mainSound = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.settings.soundVolume.main
  );
  const slidePopupView = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupView
  );
  const slidePopupOpened = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupOpened
  );
  const compactViewOpened = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.compactViewOpened
  );
  // TODO: remove it from production
  const soundEnabled = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.soundEnabled);

  const toggleSlidePopup = useCallback(
    (view) => {
      if (slidePopupOpened && view === slidePopupView) {
        dispatch(closeSlidePopup());
      } else {
        dispatch(setSlidePopupView({ view }));
        dispatch(openSlidePopup());
      }
    },
    [dispatch, slidePopupOpened, slidePopupView]
  );

  const handleRGClicked = useCallback(() => {
    window.open('/', '_blank');
  }, []);

  const handleCompactViewClicked = useCallback(() => {
    dispatch(setCompactViewOpened(!compactViewOpened));
  }, [compactViewOpened, dispatch]);

  const handleToggleSound = useCallback(() => {
    console.log('handleToggleSound');
    dispatch(toggleSound());
  }, [dispatch]);

  return (
    <div className={styles.menu}>
      <div className={clsx(compactViewOpened && styles.hidden)}>
        <Tooltip content={t('chat')} distance={6}>
          <IconButton icon={<IoMdChatboxes size="1.25em" />} onClick={() => toggleSlidePopup(MENU_VIEW.CHAT)} />
        </Tooltip>
      </div>

      <Tooltip content={t('responsibleGaming')} distance={6}>
        <IconButton icon={<MdEmojiPeople size="1.25em" />} onClick={handleRGClicked} />
      </Tooltip>
      {/* TODO: fixed it when live game session has finished */}
      {/* <Tooltip content={t('changeView')} distance={6}>
        <IconButton icon={<CameraIcon height="1.25em" />} onClick={handleCompactViewClicked} />
      </Tooltip> */}
      {/* <Popover content={<SoundView />} distance={6}> */}
      <IconButton
        onClick={handleToggleSound}
        icon={<SoundIndicator height="1.25em" level={soundEnabled ? 100 : 0} isMuted={false} />}
      />
      {/* </Popover> */}
      <Tooltip content={t('settings')} distance={6}>
        <IconButton icon={<IoMdSettings size="1.25em" />} onClick={() => toggleSlidePopup(MENU_VIEW.SETTINGS)} />
      </Tooltip>
      <Tooltip content={t('history')} distance={6}>
        <IconButton icon={<GoHistory size="1.25em" />} onClick={() => toggleSlidePopup(MENU_VIEW.HISTORY)} />
      </Tooltip>
      <Tooltip content={t('help')} distance={6}>
        <IconButton icon={<MdOutlineQuestionMark size="1.25em" />} onClick={() => toggleSlidePopup(MENU_VIEW.HELP)} />
      </Tooltip>
      <SlidePopupUI />
    </div>
  );
};

export default MenuButtons;
