import { createContext, useCallback, useContext, useState } from 'react';

export const ModalContext = createContext({});

export function ModalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [modalProps, setModalProps] = useState(null);
  const [onCloseCallback, setOnCloseCallback] = useState(null);
  const [onConfirmCallback, setOnConfirmCallback] = useState(null);
  const [displayCloseButton, setDisplayCloseButton] = useState(true);
  const [displayConfirmButton, setDisplayConfirmButton] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState('Ok');

  const openModal = useCallback(
    (
      title,
      type,
      props,
      hasCloseButton = true,
      hasConfirmButton = false,
      closeCallback,
      confirmCallback,
      confirmButtonText = 'Ok'
    ) => {
      setModalTitle(title);
      setModalType(type);
      setModalProps(props);
      setDisplayCloseButton(hasCloseButton);
      setDisplayConfirmButton(hasConfirmButton);
      setOnCloseCallback(() => closeCallback);
      setOnConfirmCallback(() => confirmCallback);
      setConfirmButtonText(confirmButtonText);
      setIsOpen(true);
    },
    []
  );

  const closeModal = useCallback(() => {
    if (!isOpen) return;

    setIsOpen(false);
    if (onCloseCallback) {
      onCloseCallback();
      setOnCloseCallback(null);
    }
  }, [isOpen, onCloseCallback]);

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        modalTitle,
        openModal,
        modalType,
        modalProps,
        closeModal,
        displayCloseButton,
        displayConfirmButton,
        onCloseCallback,
        onConfirmCallback,
        confirmButtonText,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);
