import { useSelector } from 'react-redux';
import SeatInfo from '../SeatInfo/SeatInfo';

const SeatInfoContainer = () => {
  const seatsCount = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.settings.seatsCount
  );
  const positions = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.betPlaceholderPositions
  );

  return (
    <>
      {positions &&
        Array.from({ length: seatsCount }).map((_, index) => {
          const { x, y, degree } = positions[index] || {};
          const seatInfoStyle = {
            position: 'absolute',
            top: `${y}%`,
            left: `${x}%`,
            transform: `translate(-50%, -50%) rotate(${degree}deg) translateY(4rem) rotate(${-1 * degree}deg)`,
          };

          return (
            <div key={`seat-info-${index}`} style={seatInfoStyle}>
              <SeatInfo seatNumber={index} />
            </div>
          );
        })}
    </>
  );
};

export default SeatInfoContainer;
