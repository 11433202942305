import StateObserver from '@/components/StateObserver';
import { ModalProvider } from '@/context/ModalContext';
import { TooltipContextProvider } from '@/context/tooltip-context';
import { store } from '@/store/index';
import '@/styles/globals.scss';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import { ErrorBoundary } from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import AppError from './AppError';
import { initI18n } from './i18n';
import { initOpenTelemetry } from './lib/opentelemetryService';
import { initLog } from './lib/paramService';
import { initSentry } from './lib/sentryService';

initLog();
initSentry();
initOpenTelemetry();
initI18n();

createRoot(document.querySelector('#root')).render(
  <ErrorBoundary fallback={({ error }) => <AppError error={error} />}>
    <Provider store={store}>
      <ModalProvider>
        <TooltipContextProvider>
          <App />
        </TooltipContextProvider>
        <StateObserver />
      </ModalProvider>
    </Provider>
  </ErrorBoundary>
);
