import { useSelector } from 'react-redux';
import Seat from '../Seat/Seat';
import styles from './Seats.module.scss';
import HalfCircleLayout from '../HalfCircleLayout';
import SeatInfoContainer from '../SeatInfoContainer';
import ChipsContainer from '../ChipsContainer';

const Seats = () => {
  const seatsCount = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.settings.seatsCount
  );

  return (
    <div id="seats" className={styles.seats}>
      <div className={styles.perspectiveContainer}>
        <div className={styles.cardsAndChipPlaceholders}>
          <HalfCircleLayout radius={70} distance={16}>
            {Array.from({ length: seatsCount }).map((_, index) => (
              <Seat key={`seat-${index}`} seatNumber={index} />
            ))}
          </HalfCircleLayout>
        </div>
      </div>
      <ChipsContainer />
      <SeatInfoContainer />
    </div>
  );
};

export default Seats;
