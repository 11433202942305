import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} SettingsState - TBD.
 * @property {object} general - TBD.
 * @property {object} stream - TBD.
 * @property {object[]} soundVolume - TBD.
 * @property {string} screenName - TBD.
 */

/** @type {SettingsState} */
const initialState = {
  general: {},
  stream: {},
  soundVolume: [],
  screenName: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {SettingsState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - TBD.
     */
    setHideChatFromOtherPlayers: (state, action) => {
      state.general.hideChatFromOtherPlayers = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - TBD.
     */
    setAutoZoom: (state, action) => {
      state.general.autoZoom = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - TBD.
     */
    setLeaveWinningBets: (state, action) => {
      state.general.leaveWinningBets = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - TBD.
     */
    setStreamAutomaticAdjustment: (state, action) => {
      state.stream.automaticAdjustment = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - TBD.
     */
    setStreamQuality: (state, action) => {
      state.stream.quality = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<object>} action - TBD.
     */
    setSoundVolume: (state, action) => {
      const { type, level } = action.payload;
      const isMuted = state.soundVolume[type].isMuted;
      if (isMuted && level === 0) return;

      state.soundVolume[type].level = level;
      state.soundVolume[type].isMuted = level === 0 ? true : false;
    },
    /**
     * TBD.
     * @param {SettingsState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<object>} action - TBD.
     */
    muteSound: (state, action) => {
      const { type } = action.payload;
      state.soundVolume[type].isMuted = true;
    },
    /**
     * TBD.
     * @param {SettingsState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<object>} action - TBD.
     */
    unmuteSound: (state, action) => {
      const { type } = action.payload;
      const soundLevel = state.soundVolume[type].level;

      state.soundVolume[type].isMuted = false;
      state.soundVolume[type].level = soundLevel === 0 ? 50 : soundLevel;
    },
    /**
     * TBD.
     * @param {SettingsState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action - TBD.
     */
    setScreenName: (state, action) => {
      state.screenName = action.payload;
    },
    /**
     * TBD.
     * @param {SettingsState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<object>} action - TBD.
     */
    setPlayerSettings: (state, action) => {
      const { general, stream, soundVolume, screenName } = action.payload;
      state.screenName = screenName;
      state.general = general;
      state.stream = stream;
      state.soundVolume = soundVolume;
    },
  },
});

export const {
  setHideChatFromOtherPlayers,
  setAutoZoom,
  setLeaveWinningBets,
  setStreamAutomaticAdjustment,
  setStreamQuality,
  setSoundVolume,
  muteSound,
  unmuteSound,
  setScreenName,
  setPlayerSettings,
} = settingsSlice.actions;

export default settingsSlice.reducer;
