import { Logger } from '@vpmedia/simplify';
import { config } from './configService';
import { BASE_URL, LANGUAGE } from './paramService';

const logger = new Logger('urlService');

/**
 * Redirects the user to the specified URL.
 * @param {string} url - The URL to redirect to.
 */
export const redirectUrl = (url) => {
  try {
    logger.info('redirectUrl', { url });
    // Can throw 'NS_ERROR_DOM_PROP_ACCESS_DENIED: Access to property denied'.
    if (window.parent && window.parent.window) {
      window.parent.window.location.href = url;
    } else {
      window.location.href = url;
    }
  } catch (error) {
    // @ts-ignore
    logger.exception(error);
  }
};

/**
 * Redirects the user to the home page.
 */
export const redirectHome = () => {
  const url = LANGUAGE === 'nl' ? `${BASE_URL}/` : `${BASE_URL}/${LANGUAGE}`;
  redirectUrl(url);
};

/**
 * Redirects the user to the live casino home page.
 */
export const redirectLiveCasinoHomePage = () => {
  const url = LANGUAGE === 'nl' ? `${BASE_URL}/livecasino` : `${BASE_URL}/${LANGUAGE}/livecasino`;
  redirectUrl(url);
};

/**
 * Open responsible gaming page.
 */
export const openResponsibleGamingPage = () => {
  const url =
    LANGUAGE === 'nl' ? `${BASE_URL}/help/verantwoord-spelen` : `${BASE_URL}/${LANGUAGE}/support/responsible-gaming`;
  window.open(url, '_blank');
};

/**
 * Adds a version parameter to the given URL.
 * @param {string} url - The URL to add the version parameter to.
 * @returns {string} The URL with the version parameter added.
 */
export const versionedUrl = (url) => {
  const version = config.VITE_APP_REVISION;
  return `${url}?v=${version}`;
};
