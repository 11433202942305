import { SOUND_TYPE } from '@/enums/sound';
import { closeLimits, openLimits, pinLimits, unpinLimits } from '@/store/uiSlice';
import soundService from '@/lib/soundService';
import cl from 'clsx';
import { useCallback } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import GameLabel from '../GameLabel';
import IconButton from '../IconButton/IconButton';
import InfoPanel from '../InfoPanel';
import Limits from '../Limits';
import MenuButtons from '../MenuButtons/MenuButtons';
import PinButton from '../PinButton';
import styles from './Header.module.scss';

const Header = () => {
  const limitsOpen = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.limitsOpen);
  const limitsPinned = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.limitsPinned);
  const dispatch = useDispatch();

  const handlePinClicked = useCallback(() => {
    soundService.playSound(SOUND_TYPE.PRESS);
    limitsPinned ? dispatch(unpinLimits()) : dispatch(pinLimits());
  }, [dispatch, limitsPinned]);

  const handleExit = useCallback(() => {
    if (window !== window.top) {
      soundService.playSound(SOUND_TYPE.PRESS);
      window.top.location.href = window.location.ancestorOrigins[0];
    }
  }, []);

  return (
    <div className={styles.header}>
      <div
        className={styles.leftSection}
        onMouseEnter={() => dispatch(openLimits())}
        onMouseLeave={() => dispatch(closeLimits())}
      >
        <div className={cl(styles.limitsContainer, (limitsOpen || limitsPinned) && styles.open)}>
          <Limits />
        </div>
        <div className={styles.closeButton}>
          <IconButton icon={<IoMdClose size="1.25rem" />} onClick={handleExit} />
        </div>
        <GameLabel />
        <PinButton onClick={handlePinClicked} />
      </div>
      <div className={styles.menu}>
        <MenuButtons />
        <div className={styles.info}>
          <InfoPanel />
        </div>
      </div>
    </div>
  );
};

export default Header;
