import { SOUND_TYPE } from '@/enums/sound';
import { closeSlidePopup } from '@/store/uiSlice';
import soundService from '@/lib/soundService';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useCallback } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import styles from './SlidePopup.module.scss';

const SlidePopup = ({ title, icon, showCloseButton = true, direction = 'left', className, children }) => {
  const dispatch = useDispatch();

  const variants = {
    open: {
      opacity: 1,
      x: 0,
    },
    closed: {
      opacity: 0,
      x: '100%',
    },
  };

  const handleCloseSlidePopup = useCallback(() => {
    soundService.playSound(SOUND_TYPE.PRESS);
    dispatch(closeSlidePopup());
  }, [dispatch]);

  return (
    <motion.div
      animate={'open'}
      initial={'closed'}
      transition={{ duration: 0.4, type: 'tween' }}
      variants={variants}
      className={clsx(styles.container, className)}
    >
      {title && (
        <div className={styles.header}>
          {icon && <div className={styles.icon}>{icon}</div>}
          <div>{title}</div>
          {showCloseButton && <IoMdClose size="1em" className={styles.closeButton} onClick={handleCloseSlidePopup} />}
        </div>
      )}

      <div className={styles.content}>{children}</div>
    </motion.div>
  );
};

export default SlidePopup;
