import { useSelector } from 'react-redux';
import { GiPin } from 'react-icons/gi';
import cl from 'clsx';
import styles from './PinButton.module.scss';

const PinButton = ({ onClick }) => {
  const limitsPinned = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.limitsPinned);

  return (
    <div className={cl(styles.container, limitsPinned && styles.pinned)} onClick={onClick}>
      <GiPin size="1.25rem" />
    </div>
  );
};

export default PinButton;
