import { SOUND_TYPE } from '@/enums/sound';
import { leaveSeatThunk } from '@/store/thunks/gameThunks';
import soundService from '@/lib/soundService';
import { useCallback } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import styles from './LeaveSeatButton.module.scss';

const LeaveSeatButton = ({ seatNumber }) => {
  const dispatch = useDispatch();

  const handleLeaveSeat = useCallback(() => {
    soundService.playSound(SOUND_TYPE.PRESS);
    dispatch(leaveSeatThunk({ seatNumber }));
  }, [dispatch, seatNumber]);

  return (
    <button className={styles.leaveButton} onClick={handleLeaveSeat} data-cy="leaveSeatButton">
      <MdOutlineClose size="1.5rem" className={styles.icon} />
    </button>
  );
};

export default LeaveSeatButton;
