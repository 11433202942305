export const BET_TYPE = Object.freeze({
  MAIN: 'main',
  BET_BEHIND: 'betBehind',
  PERFECT_PAIRS: 'perfectPairs',
  TWENTYONE_PLUS_THREE: 'twentyOnePlusThree',
  INSURANCE: 'insurance',
  DOUBLE: 'double',
  SPLIT: 'split',
  HOT_3: 'hot3',
  ANY_PAIR: 'anyPair',
  BUST_IT: 'bustIt',
});

export const getBetTypeName = (betType) => {
  switch (betType) {
    case BET_TYPE.MAIN:
      return 'Main Bet';
    case BET_TYPE.BET_BEHIND:
      return 'Bet Behind';
    case BET_TYPE.PERFECT_PAIRS:
      return 'Perfect Pairs';
    case BET_TYPE.TWENTYONE_PLUS_THREE:
      return '21+3';
    case BET_TYPE.INSURANCE:
      return 'Insurance';
    case BET_TYPE.DOUBLE:
      return 'Double Down';
    case BET_TYPE.SPLIT:
      return 'Split';
    case BET_TYPE.HOT_3:
      return 'Hot3';
    case BET_TYPE.BUST_IT:
      return 'Bust It';
    case BET_TYPE.ANY_PAIR:
      return 'Any Pair';
    default:
      return 'Unknown Bet Type';
  }
};
