import { useMeasure, useWindowSize } from 'react-use';
import styles from './AspectRatioContainer.module.scss';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const AspectRatioContainer = ({ widthRatio = 16, heightRatio = 10, children }) => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [ref, { width: containerWidth, height: containerHeight }] = useMeasure();
  const dispatch = useDispatch();

  const calculateFontSize = useCallback(() => (containerWidth ? containerWidth * 0.015 : 16), [containerWidth]);

  const ratioX = windowWidth / widthRatio;
  const ratioY = windowHeight / heightRatio;
  const minRatio = Math.min(ratioX, ratioY);

  useEffect(() => {
    document.querySelector('html').style.fontSize = `${calculateFontSize()}px`;
  }, [calculateFontSize, containerWidth]);

  useEffect(() => {
    document.querySelector('html').style.fontSize = `${containerWidth * 0.015 || 16}px`;
  }, [containerHeight, containerWidth, dispatch]);

  if (windowWidth === 0 || windowHeight === 0) return;

  const width = minRatio * widthRatio;
  const height = minRatio * heightRatio;

  return (
    <div ref={ref} style={{ width, height }} className={styles.aspectRatioContainer}>
      {children}
    </div>
  );
};

export default AspectRatioContainer;
