import { BET_TYPE } from '@/enums/betType';
import { DECISION_TYPE, INSURANCE_DECISION_TYPE } from '@/enums/decisionType';
import { OUTCOME } from '@/enums/outcomeType';
import { ROUND_STATE } from '@/enums/roundState';
import { SOUND_TYPE } from '@/enums/sound';
import { getBetSumValue } from '@/lib/betService';
import socketApi from '@/lib/socketApi';
import soundService from '@/lib/soundService';
import { Logger } from '@vpmedia/simplify';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { addBet, leaveSeat, setLastDecision, setPayout, setResults, takeSeat, undoBet } from '../gameSlice';
import { selectBetsBySeatNumber, selectPlayerBets } from '../selectors/gameSelectors';
import { decreaseBalance, increaseBalance } from '../userSlice';

const logger = new Logger('gameThunks');

/**
 * TBD.
 * @param {object} payload - TBD.
 * @returns {object} TBD.
 */
export const setDecisionThunk = (payload) => async (dispatch, getState) => {
  /** @type {{ game: import('../gameSlice').GameState }} */
  const { game: gameState } = getState();
  const { gameId, roundId } = gameState;
  const { decision, seatNumber } = payload;

  try {
    const userMainBets = gameState.betHistory.filter(
      (bet) => bet.seatNumber === seatNumber && bet.betType === BET_TYPE.MAIN
    );

    const sumBetValue = _.sumBy(userMainBets, (betItem) => betItem.bet);

    let betType = null;

    if (decision === DECISION_TYPE.DOUBLE) {
      betType = BET_TYPE.DOUBLE;
    } else if (decision === DECISION_TYPE.SPLIT) {
      betType = BET_TYPE.SPLIT;
    }

    if (betType) {
      const bet = {
        seatNumber,
        betType,
        bet: sumBetValue,
      };
      dispatch(addBetThunk([bet]));
    }

    dispatch(setLastDecision(payload));
    await socketApi.sendDecision({ gameId, roundId, ...payload });
  } catch (error) {
    logger.error('setDecisionThunk', error);
  }
};

export const takeSeatThunk =
  ({ seatNumber }) =>
  async (dispatch, getState) => {
    /** @type {{ user: import('../userSlice').UserState, settings: import('../settingsSlice').SettingsState }} */
    const { user: userState, settings: settingsState } = getState();
    const { publicPlayerId } = userState;
    const { screenName } = settingsState;

    try {
      dispatch(takeSeat({ publicPlayerId, seatNumber, screenName }));
      await socketApi.takeSeat(seatNumber);
    } catch (error) {
      logger.error('takeSeatThunk', error);
    }
  };

export const leaveSeatThunk =
  ({ seatNumber }) =>
  async (dispatch, getState) => {
    /** @type {{ game: import('../gameSlice').GameState, user: import('../userSlice').UserState }} */
    const { game: gameState, user: userState } = getState();

    try {
      const currentSeat = gameState.seats[seatNumber];

      if (userState.publicPlayerId === currentSeat.publicPlayerId) {
        const currentBets = selectBetsBySeatNumber(seatNumber)(getState());
        const betSumValue = getBetSumValue(currentBets);
        if (betSumValue > 0) {
          dispatch(increaseBalance(betSumValue));
        }
      }
      dispatch(leaveSeat({ seatNumber }));
      await socketApi.leaveSeat(seatNumber);
    } catch (error) {
      logger.error('leaveSeatThunk', error);
    }
  };

export const addBetThunk = (bets) => async (dispatch, getState) => {
  /** @type {{ user: import('../userSlice').UserState }} */
  const { user: userState } = getState();
  const transactionId = uuidv4();

  const mappedBets = bets.map((bet) => ({
    publicBetId: uuidv4(),
    ...bet,
    transactionId,
    publicPlayerId: userState.publicPlayerId,
  }));

  const betSumValue = getBetSumValue(bets);
  try {
    if (mappedBets.length > 1) {
      soundService.playSound(SOUND_TYPE.BET_MULTIPLE);
    } else {
      soundService.playSound(SOUND_TYPE.BET_ONE_CHIP);
    }
    dispatch(decreaseBalance(betSumValue));
    dispatch(addBet(mappedBets));
    await socketApi.addBet(mappedBets);
  } catch (error) {
    dispatch(increaseBalance(betSumValue));
    dispatch(undoBet(transactionId));
    logger.error('addBetThunk', error);
  }
};

export const undoBetThunk = () => async (dispatch, getState) => {
  /** @type {{ game: import('../gameSlice').GameState }} */
  const { game: state } = getState();
  const userBets = selectPlayerBets(getState());
  if (userBets.length === 0) return;

  const lastBet = userBets.slice(-1)[0];
  const { transactionId } = lastBet;
  const currentBets = userBets.filter((bet) => bet.transactionId === transactionId);
  const betSumValue = getBetSumValue(currentBets);

  try {
    soundService.playSound(SOUND_TYPE.UNDO);
    dispatch(increaseBalance(betSumValue));
    dispatch(undoBet(transactionId));
    await socketApi.undoBet(transactionId);
  } catch (error) {
    dispatch(decreaseBalance(betSumValue));
    dispatch(addBet(currentBets));
    logger.error('undoBetThunk', error);
  }
};

export const doubleBetThunk = () => async (dispatch, getState) => {
  const userBets = selectPlayerBets(getState());
  const transactionId = uuidv4();
  const mappedBets = userBets.map((bet) => ({
    ...bet,
    publicBetId: uuidv4(),
    transactionId,
  }));

  const betSumValue = getBetSumValue(userBets);
  try {
    if (mappedBets.length > 1) {
      soundService.playSound(SOUND_TYPE.BET_MULTIPLE);
    } else {
      soundService.playSound(SOUND_TYPE.BET_ONE_CHIP);
    }
    dispatch(decreaseBalance(betSumValue));
    dispatch(addBet(mappedBets));

    await socketApi.addBet(mappedBets);
  } catch (error) {
    dispatch(increaseBalance(betSumValue));
    dispatch(undoBet(transactionId));
    logger.error('doubleBetThunk', error);
  }
};

export const setResultThunk = (results) => async (dispatch, getState) => {
  const { roundState } = getState().game;
  const { publicPlayerId } = getState().user;

  dispatch(setResults(results));
  logger.info('setResultThunk', results);

  if (roundState === ROUND_STATE.RESOLVE_PHASE) {
    const { results: allResults } = getState().game;
    const playerResults = allResults.filter((item) => item.publicPlayerId === publicPlayerId);
    const win = playerResults.reduce((acc, item) => {
      if (item.win > 0 || item.outcome === OUTCOME.PUSH) {
        acc += item.win + item.bet;
      }

      return acc;
    }, 0);

    dispatch(setPayout(win));
    dispatch(increaseBalance(win));
  }
};

export const addInsuranceThunk = (decision) => async (dispatch, getState) => {
  /** @type {{ game: import('../gameSlice').GameState, user: import('../userSlice').UserState }} */
  const { game: gameState, user: userState } = getState();
  const transactionId = uuidv4();
  let betSumValue;

  try {
    if (decision === INSURANCE_DECISION_TYPE.ACCEPT) {
      const playerBets = selectPlayerBets(getState());
      const mainBets = playerBets.filter((bet) => bet.betType === BET_TYPE.MAIN);

      const mappedBets = mainBets.map((betItem) => ({
        ...betItem,
        transactionId,
        publicBetId: uuidv4(),
        betType: BET_TYPE.INSURANCE,
        bet: betItem.bet / 2,
        publicPlayerId: userState.publicPlayerId,
      }));

      betSumValue = getBetSumValue(mappedBets);
      const soundType = mappedBets.length > 1 ? SOUND_TYPE.BET_MULTIPLE : SOUND_TYPE.BET_ONE_CHIP;
      soundService.playSound(soundType);

      dispatch(decreaseBalance(betSumValue));
      dispatch(addBet(mappedBets));
    }

    const payload = {
      gameId: gameState.gameId,
      roundId: gameState.roundId,
      transactionId,
      decision,
    };

    await socketApi.sendInsuranceDecision(payload);
  } catch (error) {
    if (decision === INSURANCE_DECISION_TYPE.ACCEPT) {
      dispatch(increaseBalance(betSumValue));
      dispatch(undoBet(transactionId));
    }

    logger.error('addInsuranceThunk', error);
  }
};
