import { SOCKET_ACTION } from '@/enums/socketEvent';
import { store } from '@/store';
import { setError } from '@/store/errorSlice';
import { Logger } from '@vpmedia/simplify';
import { isErrorSuppressed } from './errorService';

const getSocket = async () => {
  const lazySocketModule = await import('../socket');
  return lazySocketModule.socket;
};

const logger = new Logger('SocketAPI');

const emitWithAck = async (eventName, data) => {
  const socket = await getSocket();
  return new Promise((resolve, reject) => {
    socket.emit(eventName, data, (response) => {
      if (response.error) {
        logger.warn('emitWithAck', { eventName, data, response });
      } else {
        logger.info('emitWithAck', { eventName, data, response });
      }
      if (response.success) {
        resolve(response.data ?? null);
      } else {
        if (!isErrorSuppressed(response.error)) {
          store.dispatch(setError({ operation: eventName, errorCode: response.error }));
        }
        const error = new Error(response.error || JSON.stringify(response));
        reject(error);
      }
    });
  });
};

/**
 * Emits a socket event.
 * @param {string} eventName - Event name.
 * @param {object} data - Event payload.
 */
const emit = async (eventName, data) => {
  const socket = await getSocket();
  logger.info('emit', { eventName, data });
  socket.emit(eventName, data);
};

const socketApi = {
  takeSeat: async (seatNumber) => {
    return await emitWithAck(SOCKET_ACTION.ON_SEAT_RESERVED, { seatNumber });
  },
  leaveSeat: async (seatNumber) => {
    return await emitWithAck(SOCKET_ACTION.ON_SEAT_RELEASED, { seatNumber });
  },
  addBet: async (bets) => {
    return await emitWithAck(SOCKET_ACTION.ON_BET_ADDED, bets);
  },
  undoBet: async (transactionId) => {
    return await emitWithAck(SOCKET_ACTION.ON_BET_REMOVED, transactionId);
  },
  sendInsuranceDecision: async (payload) => {
    return await emitWithAck(SOCKET_ACTION.ON_INSURANCE_DECISION_ARRIVED, payload);
  },
  sendMessage: async (payload) => {
    await emit(SOCKET_ACTION.ON_CHAT_MESSAGE_SENT, payload);
  },
  sendDecision: async (payload) => {
    return await emitWithAck(SOCKET_ACTION.ON_DECISION_ARRIVED, payload);
  },
  getHistory: async () => {
    return await emitWithAck(SOCKET_ACTION.ON_GET_HISTORY, null);
  },
  getHistoryItem: async (roundId) => {
    return await emitWithAck(SOCKET_ACTION.ON_GET_HISTORY_ITEM, { roundId });
  },
  savePlayerSettings: async (settings) => {
    return await emitWithAck(SOCKET_ACTION.ON_PLAYER_SETTINGS_CHANGED, { settings });
  },
};

export default socketApi;
