export const SIDE_BETS = Object.freeze({
  PERFECT_PAIRS: 'perfectPairs',
  TWENTY_ONE_PLUS_THREE: 'twentyOnePlusThree',
});

export const PERFECT_PAIRS_TYPE = Object.freeze({
  PERFECT_PAIR: 'perfectPair',
  COLOURED_PAIR: 'colouredPair',
  MIXED_PAIR: 'mixedPair',
});

export const PERFECT_PAIR_PAYOUT = Object.freeze({
  [PERFECT_PAIRS_TYPE.PERFECT_PAIR]: 25,
  [PERFECT_PAIRS_TYPE.COLOURED_PAIR]: 12,
  [PERFECT_PAIRS_TYPE.MIXED_PAIR]: 6,
});

export const TWENTY_ONE_PLUS_THREE_TYPE = Object.freeze({
  STRAIGHT_FLUSH: 'straightFlush',
  THREE_OF_A_KIND: 'threeOfAKind',
  STRAIGHT: 'straight',
  FLUSH: 'flush',
});

export const TWENTY_ONE_PLUS_THREE_PAYOUT = Object.freeze({
  [TWENTY_ONE_PLUS_THREE_TYPE.STRAIGHT_FLUSH]: 30,
  [TWENTY_ONE_PLUS_THREE_TYPE.THREE_OF_A_KIND]: 20,
  [TWENTY_ONE_PLUS_THREE_TYPE.STRAIGHT]: 10,
  [TWENTY_ONE_PLUS_THREE_TYPE.FLUSH]: 5,
});
