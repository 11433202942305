import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import CountdownCircleTimer from '@/components/CountdownCircleTimer';
import { v4 as uuid } from 'uuid';
import { useCallback, useEffect, useRef } from 'react';
import styles from './Timer.module.scss';

const Timer = () => {
  const { duration, remainingTime } =
    useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.timer) || {};
  const timerId = useRef(uuid());

  const onTimerComplete = useCallback(() => {
    timerId.current = uuid();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        timerId.current = uuid();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <AnimatePresence>
      {remainingTime > 0 ? (
        <motion.div key={timerId.current} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <CountdownCircleTimer
            size="4rem"
            duration={duration}
            startAt={remainingTime}
            onTimerComplete={onTimerComplete}
          />
        </motion.div>
      ) : (
        <></>
      )}
    </AnimatePresence>
  );
};

export default Timer;
