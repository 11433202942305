import { useSelector } from 'react-redux';
import Seats from '../Seats';
import TableFigure from '../TableFigure';
import styles from './Table.module.scss';
import { GAME_TYPE } from '@/enums/gameType';
import InfiniteSeat from '../InfiniteBlackjack/InfiniteSeat';

const Table = ({ withFigure = false }) => {
  const gameType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.gameType);

  return (
    <div className={styles.table}>
      {withFigure && (
        <div className={styles.tableFigure}>
          <TableFigure />
        </div>
      )}
      {gameType === GAME_TYPE.LIVE_BLACKJACK ? (
        <div className={styles.seats}>
          <Seats />
        </div>
      ) : (
        <div className={styles.infiniteSeat}>
          <InfiniteSeat />
        </div>
      )}
    </div>
  );
};

export default Table;
