class InsufficientBalanceError extends Error {
  constructor(message) {
    super(message);
    this.name = 'InsufficientBalanceError';
  }
}

class BetLimitReachedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'BetLimitReachedError';
  }
}

export { InsufficientBalanceError, BetLimitReachedError };
