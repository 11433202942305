import { BET_TYPE } from '@/enums/betType';
import { INSURANCE_DECISION_TYPE } from '@/enums/decisionType';
import { ROUND_STATE } from '@/enums/roundState';
import { SOUND_TYPE } from '@/enums/sound';
import { getBetSumValue } from '@/lib/betService';
import { selectPlayerBets } from '@/store/selectors/gameSelectors';
import soundService from '@/lib/soundService';
import cl from 'clsx';
import { useCallback, useMemo } from 'react';
import { BsShieldFillCheck, BsShieldSlashFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import styles from './InsuranceDecisionPanel.module.scss';
import { Logger } from '@vpmedia/simplify';

const logger = new Logger('InsuranceDecisionPanel');

const InsuranceDecisionPanel = () => {
  const roundState = useSelector((select) => select.game.roundState);
  const playerBets = useSelector(selectPlayerBets);
  const playerBalance = useSelector(selectPlayerBets);
  const dispatch = useDispatch();

  const hasInsuranceBets = useMemo(() => {
    return playerBets.some((bet) => bet.betType === BET_TYPE.INSURANCE);
  }, [playerBets]);

  const hasEnoughBalance = useMemo(() => {
    const mainBets = playerBets.filter((bet) => bet.betType === BET_TYPE.MAIN);
    const sumMainBetValue = getBetSumValue(mainBets);
    return playerBalance >= sumMainBetValue;
  }, [playerBalance, playerBets]);

  const isVisible = useMemo(() => {
    if (roundState === ROUND_STATE.INSURANCE_PHASE && !hasInsuranceBets && hasEnoughBalance) {
      return true;
    }

    return false;
  }, [hasEnoughBalance, hasInsuranceBets, roundState]);

  const executeDecision = useCallback((decision) => {
    soundService.playSound(SOUND_TYPE.PRESS);
    logger.info('executeDecision', decision);
    //   dispatch()
  }, []);

  const buttons = useMemo(
    () => [
      {
        id: uuid(),
        action: () => executeDecision(INSURANCE_DECISION_TYPE.ACCEPT),
        icon: <BsShieldFillCheck size="1.5rem" />,
        text: 'Yes',
        dataCy: 'acceptButton',
        colorClass: 'green',
      },
      {
        id: uuid(),
        action: () => executeDecision(INSURANCE_DECISION_TYPE.DECLINE),
        icon: <BsShieldSlashFill size="1.5rem" />,
        text: 'No',
        dataCy: 'declineButton',
        colorClass: 'red',
      },
    ],
    [executeDecision]
  );

  if (!isVisible) return null;

  return (
    <div className={styles.container}>
      {buttons.map(({ id, action, icon, text, colorClass }) => (
        <button key={id} className={cl(styles.button, styles[colorClass])} onClick={action}>
          {icon}
          {text && <div className={styles.text}>{text}</div>}
        </button>
      ))}
    </div>
  );
};

export default InsuranceDecisionPanel;
