import { SOUND_TYPE } from '@/enums/sound';
import SelectCircle from '@/assets/svg/select-circle.svg?react';
import { setSelectedChip } from '@/store/gameSlice';
import soundService from '@/lib/soundService';
import cl from 'clsx';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chip from '../Chip/Chip';
import styles from './ChipSelector.module.scss';

const ChipSelector = () => {
  const chips = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.settings.chips);
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (chip) => {
      if (chip === selectedChip) return;
      soundService.playSound(SOUND_TYPE.CHIP_SELECTOR);
      dispatch(setSelectedChip(chip));
    },
    [dispatch, selectedChip]
  );

  return (
    <div className={styles.chipSelector}>
      {chips.map((chip) => (
        <div
          key={`chip-selector-item-${chip}`}
          className={cl(styles.chipContainer, selectedChip === chip && styles.selected)}
        >
          <div className={styles.hoverEffect}>
            <svg viewBox="0 0 64 64" height="3em" width="3em">
              <defs>
                <linearGradient id="strokeGradient" x2="0" y2="1">
                  <stop offset="0" stopColor="rgba(255, 255, 255, 0.2)"></stop>
                  <stop offset="1" stopColor="transparent"></stop>
                </linearGradient>
                <radialGradient id="fillGradient">
                  <stop offset="0" stopColor="transparent"></stop>
                  <stop offset="1" stopColor="rgba(255, 255, 255, 0.2)"></stop>
                </radialGradient>
              </defs>
              <circle
                cx="32"
                cy="32"
                r="31.5"
                fill="url(#fillGradient)"
                stroke="url(#strokeGradient)"
                strokeWidth="1"
              ></circle>
            </svg>
          </div>
          {chip === selectedChip && <SelectCircle className={styles.selectCircle} height="3em" width="3em" />}
          <Chip value={chip} onClick={() => handleClick(chip)} />
        </div>
      ))}
    </div>
  );
};

export default ChipSelector;
