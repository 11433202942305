import TwentyOnePlusThreeIcon from '@/assets/svg/21+3.svg?react';
import AnyPairIcon from '@/assets/svg/any_pair.svg?react';
import BustItIcon from '@/assets/svg/bust_it.svg?react';
import Hot3Icon from '@/assets/svg/hot3.svg?react';
import BetPlaceholderChip from '@/components/BetPlaceholderChip';
import Card from '@/components/Card';
import ChipStack from '@/components/ChipStack';
import HandStateSymbol from '@/components/HandStateSymbol';
import { BET_TYPE } from '@/enums/betType';
import { ROUND_STATE } from '@/enums/roundState';
import { SOUND_TYPE } from '@/enums/sound';
import useBet from '@/hooks/useBet';
import { isHandSplitted } from '@/lib/cardService';
import soundService from '@/lib/soundService';
import { selectHasBetAtSeat } from '@/store/selectors/gameSelectors';
import { Logger } from '@vpmedia/simplify';
import cl from 'clsx';
import { motion } from 'framer-motion';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import styles from './InfiniteSeat.module.scss';

const logger = new Logger('InfiniteSeat');

const cardAnimations = {
  init: ({ index, isDoubled }) => ({
    x: index === 2 && isDoubled ? `${-0.2 * index}rem` : `${index * 1.2}rem`,
    y: `calc(${index * -1.5}rem - 110%)`,
  }),
  animate: ({ index, isDoubled }) => ({
    x: index === 2 && isDoubled ? `${-0.1 * index}rem` : `${index * 1}rem`,
    y: `calc(${index * -1.5}rem - 100%)`,
  }),
};

const Hand = memo(({ hand, index, roundState, cardAnimations, isHandSplitted }) => (
  <div key={hand.handId} className={styles.hand} data-cy={`hand_0_${index}`}>
    <div className={styles.cardContainer}>
      {hand.cards.map(({ cardId, suit, rank }, cardIndex) => (
        <motion.div
          key={cardId}
          variants={cardAnimations}
          initial="init"
          animate="animate"
          custom={{ index: cardIndex, isDoubled: hand.isDoubled }}
          className={styles.card}
        >
          <Card suit={suit} rank={rank} />
        </motion.div>
      ))}
    </div>
    <div className={styles.handStateSymbol}>
      <HandStateSymbol
        key={`hand-state-symbol-${hand.handId}`}
        seatNumber={0}
        handNumber={index}
        cards={hand.cards}
        roundState={roundState}
        seatBelongsToPlayer={true}
        isHandSplitted={isHandSplitted}
      />
    </div>
  </div>
));

Hand.displayName = 'Hand';

const BetPlaceholder = memo(({ icon, betType, chipSize }) => {
  const bets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.betHistory);
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const results = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.results);
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const mainBetsExists = useSelector(selectHasBetAtSeat(0, BET_TYPE.MAIN));
  const { executeAddBetWithBalanceAndLimitCheck } = useBet();

  const executeAddBet = useCallback(() => {
    if (roundState !== ROUND_STATE.BETTING_PHASE || (betType !== BET_TYPE.MAIN && !mainBetsExists)) return;

    try {
      const bet = {
        seatNumber: 0,
        bet: selectedChip,
        betType,
      };

      executeAddBetWithBalanceAndLimitCheck(bet);
    } catch (error) {
      soundService.playSound(SOUND_TYPE.NOT_ALLOWED);
      logger.error('executeAddBet', error);
    }
  }, [roundState, betType, mainBetsExists, selectedChip, executeAddBetWithBalanceAndLimitCheck]);

  return (
    <div className={cl(styles.betPlaceholder, styles[betType])} onClick={executeAddBet}>
      <div className={cl(styles.gradientBorder, styles[betType])}></div>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.chipStack}>
        <ChipStack
          seatNumber={0}
          bets={bets}
          results={results}
          betTypes={[betType]}
          chipSize={chipSize}
          stackType="infinite"
        />
      </div>
    </div>
  );
});

BetPlaceholder.displayName = 'BetPlaceholder';

const betPlaceholdersData = [
  { id: uuid(), icon: <Hot3Icon />, type: BET_TYPE.HOT_3, chipSize: '3em' },
  { id: uuid(), icon: <TwentyOnePlusThreeIcon />, type: BET_TYPE.TWENTYONE_PLUS_THREE, chipSize: '3em' },
  { id: uuid(), icon: <BetPlaceholderChip />, type: BET_TYPE.MAIN, chipSize: '3.5em' },
  { id: uuid(), icon: <AnyPairIcon />, type: BET_TYPE.ANY_PAIR, chipSize: '3em' },
  { id: uuid(), icon: <BustItIcon />, type: BET_TYPE.BUST_IT, chipSize: '3em' },
];

const InfiniteSeat = () => {
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const seats = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.seats);
  const hands = seats?.[0]?.hands;

  return (
    <>
      <div className={styles.perspectiveContainer}>
        <div className={styles.handsContainer}>
          {hands && (
            <div className={cl(styles.hands, hands.length > 1 && styles.splitted)}>
              {hands.map((hand, index) => (
                <Hand
                  key={hand.handId}
                  hand={hand}
                  index={index}
                  roundState={roundState}
                  cardAnimations={cardAnimations}
                  isHandSplitted={isHandSplitted(hands)}
                />
              ))}
            </div>
          )}
        </div>
        <div className={cl(styles.seatContainer, roundState !== ROUND_STATE.BETTING_PHASE && styles.noMoreBets)}>
          <div className={styles.betsContainer}>
            {betPlaceholdersData.map(({ id, icon, type, chipSize }) => (
              <BetPlaceholder key={id} icon={icon} betType={type} chipSize={chipSize} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default InfiniteSeat;
