import { OUTCOME } from '@/enums/outcomeType';
import BustIcon from '@/assets/svg/bust_icon.svg?react';
import WinIcon from '@/assets/svg/win_icon.svg?react';
import LoseIcon from '@/assets/svg/x_icon.svg?react';
import BlackjackIcon from '@/assets/svg/bj_icon.svg?react';
import { TbArrowsSort } from 'react-icons/tb';
import styles from './OutcomeContent.module.scss';
import { ROUND_STATE } from '@/enums/roundState';

const OutcomeContent = ({ outcome, roundState }) => {
  let symbol;
  let text;

  switch (outcome) {
    case OUTCOME.BLACKJACK: {
      symbol = <BlackjackIcon height="0.85rem" fill="#e2ad27" data-cy="blackJackIcon" />;
      text = 'Blackjack';
      break;
    }
    case OUTCOME.BUST: {
      symbol = <BustIcon height="0.85rem" fill="white" data-cy="bustIcon" />;
      text = 'Bust';
      break;
    }
    case OUTCOME.WIN: {
      symbol = <WinIcon height="0.85rem" fill="#e2ad27" data-cy="winIcon" />;
      text = 'Win';
      break;
    }
    case OUTCOME.LOSE: {
      symbol = <LoseIcon height="0.85rem" fill="white" data-cy="loseIcon" />;
      text = 'Lose';
      break;
    }
    case OUTCOME.PUSH: {
      symbol = <TbArrowsSort size="0.85rem" color="#e2ad27" data-cy="pushIcon" />;
      text = 'Push';
      break;
    }
    default:
      symbol = null;
      text = null;
      break;
  }

  return (
    <div className={styles.outcomeContent} data-cy="outcomeContent">
      {symbol}
      {roundState === ROUND_STATE.RESOLVE_PHASE && <div className={styles.text}>{text}</div>}
    </div>
  );
};

export default OutcomeContent;
