import { BET_TYPE } from '@/enums/betType';
import { ROUND_STATE } from '@/enums/roundState';
import { SOUND_TYPE } from '@/enums/sound';
import soundService from '@/lib/soundService';
import { selectHasBetAtSeat, selectSeatBelongsToPlayer } from '@/store/selectors/gameSelectors';
import { takeSeatThunk } from '@/store/thunks/gameThunks';
import cl from 'clsx';
import { useCallback, useMemo } from 'react';
import { ImUser } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import BetPlaceholderChip from '../BetPlaceholderChip';
import styles from './BetPlaceholders.module.scss';

const MainBet = ({ cx, cy, userIconSize, chipIconSize, seatNumber, executeAddBet }) => {
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const seat = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.seats?.[seatNumber]);
  const seatBelongsToPlayer = useSelector(selectSeatBelongsToPlayer(seatNumber, publicPlayerId));
  const mainBetsExists = useSelector(selectHasBetAtSeat(seatNumber, BET_TYPE.MAIN));

  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const dispatch = useDispatch();

  const isActive = useMemo(() => {
    if (!seat || (seatBelongsToPlayer && roundState === ROUND_STATE.BETTING_PHASE)) return true;

    return false;
  }, [roundState, seat, seatBelongsToPlayer]);

  const isPulsing = useMemo(() => {
    if (!seat || (seat && seatBelongsToPlayer && !mainBetsExists)) return true;

    return false;
  }, [mainBetsExists, seat, seatBelongsToPlayer]);

  const handleTakeSeat = useCallback(() => {
    try {
      soundService.playSound(SOUND_TYPE.PRESS);
      dispatch(takeSeatThunk({ seatNumber }));
    } catch (error) {
      open;
    }
  }, [dispatch, seatNumber]);

  return (
    <>
      <circle
        cx={cx}
        cy={cy}
        r="22.5"
        data-placeholder="main"
        className={cl('fieldBackground', isActive && 'active', isPulsing && 'pulsing')}
        onClick={() => (seat ? executeAddBet(BET_TYPE.MAIN) : handleTakeSeat())}
      />
      {!seat && (
        <ImUser x={cx - userIconSize / 2} y={cy - userIconSize / 2} size={userIconSize} className={styles.userIcon} />
      )}
      {seat && <BetPlaceholderChip isPulsing={isPulsing} x={cx - chipIconSize / 2} width={chipIconSize} />}
    </>
  );
};

export default MainBet;
