import { CARD_COLOR, RANK_TYPE, SUIT_TYPE } from '@/enums/card';

export const createCardKey = ({ suit, value }) => `${suit}_${value}`;

export const getTwentyOnePlusThreeType = (cards, bankCards) => {
  // TODO: implement
  console.log('getTwentyOnePlusThreeType', cards, bankCards);
};

export const getPerfectPairType = (cards) => {
  // TODO: implement
  console.log('getPerfectPairType', cards);
};

export const parseCardKey = (key) =>
  key.split('_').map((item) => ({
    suit: item[0],
    rank: item[1],
  }));

export const getHandValues = (cards) => {
  let sum = 0;
  let aceCount = 0;

  cards.forEach(({ rank }) => {
    if (rank === 'A') {
      aceCount += 1;
    }
  });

  cards.forEach(({ rank }) => {
    if (rank === 'A') {
      sum += 1;
    } else if (['K', 'Q', 'J'].includes(rank)) {
      sum += 10;
    } else {
      sum += Number.parseInt(rank, 10);
    }
  });

  const possibleValues = [];
  possibleValues.push(sum);

  for (let i = 1; i <= aceCount; i++) {
    const value = sum + i * 10;
    possibleValues.push(value);
  }

  return possibleValues;
};

export const countHandValue = (cards) => {
  const handValues = getHandValues(cards);

  if (handValues.length > 1 && handValues[1] <= 21) {
    return handValues[1];
  }

  return handValues[0];
};

export const getHandValueString = (cards) => {
  const handValues = getHandValues(cards);
  const filteredHandValues = handValues.filter((value) => value <= 21);
  return filteredHandValues.includes(21) ? '21' : filteredHandValues.join('/');
};

export const isBlackjack = (cards) => {
  if (cards?.length < 2) return false;
  const cardsValue = countHandValue(cards.filter((_, index) => index <= 1));
  return cardsValue === 21;
};

export const isBust = (cards) => {
  if (cards?.length <= 2) return false;
  return countHandValue(cards) > 21;
};

export const getCardColor = (card) => {
  if (card.suit === SUIT_TYPE.HEARTS || card.suit === SUIT_TYPE.DIAMONDS) return CARD_COLOR.RED;

  return CARD_COLOR.BLACK;
};

export const containsAce = (cards) => cards.some((card) => card.rank === RANK_TYPE.ACE);

export const getCardsFaceValues = (cards) => {
  return cards.map((card) => {
    if (!Number.parseInt(card.rank)) {
      return card.rank === RANK_TYPE.ACE ? 1 : 10;
    }
    return card.rank;
  });
};

export const getCardValue = (rank) => {
  const cardValues = {
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    J: 10,
    Q: 10,
    K: 10,
    A: 1,
  };

  return cardValues[rank];
};

export const canSplit = (hand) => {
  if (hand.length !== 2) {
    return false;
  }

  const [card1, card2] = hand;
  return getCardValue(card1.rank) === getCardValue(card2.rank);
};

export const isHandSplitted = (hands) => hands?.length > 1;
