import { useRef, useEffect, useCallback, useMemo } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useModal } from '../../context/ModalContext';
import styles from './Modal.module.scss';
import Button from '../Button';
import { MODAL_TYPE } from '@/enums/modalType';
import RefundModalView from './Views/RefundModalView';
import BanModalView from './Views/BanModalView';
import TableClosedModalView from './Views/TableClosedModalView';
import ScreenNameModalView from './Views/ScreenNameModalView';
import CustomErrorModalView from './Views/CustomErrorModalView';

const Modal = () => {
  const {
    isOpen,
    modalTitle,
    modalType,
    modalProps,
    closeModal,
    displayCloseButton,
    displayConfirmButton,
    confirmButtonText,
    onCloseCallback,
    onConfirmCallback,
  } = useModal();
  const ref = useRef();

  const renderedModalView = useMemo(() => {
    switch (modalType) {
      case MODAL_TYPE.REFUND:
        return <RefundModalView {...modalProps} />;
      case MODAL_TYPE.BAN:
        return <BanModalView {...modalProps} />;
      case MODAL_TYPE.TABLE_CLOSED:
        return <TableClosedModalView {...modalProps} />;
      case MODAL_TYPE.SCREEN_NAME:
        return <ScreenNameModalView {...modalProps} />;
      case MODAL_TYPE.CUSTOM_ERROR:
        return <CustomErrorModalView {...modalProps} />;
      default:
        return null;
    }
  }, [modalProps, modalType]);

  const backdropAnimations = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };

  const modalAnimations = {
    visible: {
      y: 0,
      opacity: 1,
    },
    hidden: {
      y: '-2rem',
      opacity: 0,
    },
  };

  const handleCloseModal = useCallback(() => {
    if (onCloseCallback) {
      onCloseCallback();
    }
    closeModal();
  }, [closeModal, onCloseCallback]);

  const handleKey = useCallback(
    (e) => {
      if (displayCloseButton && e.key === 'Escape') {
        handleCloseModal();
      }
    },
    [displayCloseButton, handleCloseModal]
  );

  const handleConfirm = useCallback(() => {
    if (onConfirmCallback) {
      onConfirmCallback();
    }
    closeModal();
  }, [closeModal, onConfirmCallback]);

  useEffect(() => {
    const modal = ref.current;

    if (modal) {
      disableBodyScroll(modal, { reserveScrollBarGap: true });
      window.addEventListener('keydown', handleKey);
    }
    return () => {
      clearAllBodyScrollLocks();
      window.removeEventListener('keydown', handleKey);
    };
  }, [handleKey]);

  if (!isOpen) {
    return null;
  }

  return (
    <motion.div
      animate="visible"
      initial="hidden"
      exit="hidden"
      variants={backdropAnimations}
      transition={{ duration: 0.4 }}
      className={styles.root}
    >
      <motion.div
        animate="visible"
        initial="hidden"
        exit="hidden"
        variants={modalAnimations}
        transition={{ duration: 0.4 }}
        className={styles.modal}
        role="dialog"
        ref={ref}
      >
        {modalTitle && <div className={styles.title}>{modalTitle}</div>}
        {displayCloseButton && (
          <div onClick={handleCloseModal} aria-label="Close panel" className={styles.close}>
            <FaTimes size="1.25rem" />
          </div>
        )}
        <div className={styles.content}>{renderedModalView}</div>
        <div className={styles.actions}>
          {displayConfirmButton && <Button onClick={handleConfirm}>{confirmButtonText}</Button>}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Modal;
