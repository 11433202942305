import { GAME_TYPE } from '@/enums/gameType';
import { ROUND_STATE } from '@/enums/roundState';
import { selectPlayerHasSeat } from '@/store/selectors/gameSelectors';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './GameMessage.module.scss';

const GameMessage = () => {
  const { t } = useTranslation('common');
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const userHasSeat = useSelector(selectPlayerHasSeat(publicPlayerId));
  const gameType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.gameType);

  const message = useMemo(() => {
    if (!userHasSeat && gameType === GAME_TYPE.LIVE_BLACKJACK) return 'takeASeat';

    if (roundState === ROUND_STATE.BETTING_PHASE) {
      return 'placeYourBet';
    }
  }, [gameType, roundState, userHasSeat]);

  return <div className={styles.gameMessage}>{message && t(`status.${message}`)}</div>;
};

export default GameMessage;
