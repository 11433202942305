import { ROUND_STATE } from '@/enums/roundState';
import { selectPlayerHasSeat } from '@/store/selectors/gameSelectors';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './StatusBar.module.scss';

const StatusBar = () => {
  const { t } = useTranslation('common');
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const bets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.bets);
  const timer = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.timer);
  const userHasSeat = useSelector(selectPlayerHasSeat(publicPlayerId));

  // const hasNoBet = useCallback(() => {
  //   return Object.keys(bets).length === 0
  // }, [bets])

  const getRoundStateBackground = useCallback(() => {
    if (!userHasSeat) return 'green';

    if (roundState === ROUND_STATE.BETTING_PHASE) {
      const { remainingTime } = timer || {};
      return remainingTime > 5 ? `green` : 'yellow';
    }

    // if (roundState === GAME_STATE.NO_MORE_BETS) {
    //   return 'red'
    // }

    if (roundState === ROUND_STATE.WAITING_FOR_PLAYERS) {
      return 'grey';
    }

    // if (roundState === GAME_STATE.WINNING_NUMBER) {
    //   return 'grey'
    // }
  }, [roundState, timer, userHasSeat]);

  const getRoundStateLabel = useCallback(() => {
    if (!userHasSeat) return t('status.takeASeat');

    if (roundState === ROUND_STATE.BETTING_PHASE) {
      const { remainingTime } = timer || {};
      return remainingTime > 5
        ? `${t('status.placeYourBet')} ${remainingTime}`
        : `${t('status.finalBets')} ${remainingTime}`;
    }

    // if (roundState === GAME_STATE.NO_MORE_BETS) {
    //   return t('status.noMoreBets')
    // }

    if (roundState === ROUND_STATE.WAITING_FOR_PLAYERS) {
      return t('status.waitFor');
    }

    // if (roundState === GAME_STATE.SPINNING && !hasNoBet()) {
    //   return t('status.betAccepted')
    // }

    // if (roundState === GAME_STATE.WINNING_NUMBER) {
    //   const { color } = numbers[winningNumber]
    //   return `${winningNumber} ${t(color)}`
    // }
  }, [roundState, t, timer, userHasSeat]);

  return (
    <div className={clsx(styles.container, styles[getRoundStateBackground()])}>
      {roundState && <div className={styles.label}>{getRoundStateLabel()}</div>}
    </div>
  );
};

export default StatusBar;
