import { isHandSplitted } from '@/lib/cardService';
import { selectSeatBelongsToPlayer } from '@/store/selectors/gameSelectors';
import { useSelector } from 'react-redux';
import HandStateSymbol from '../HandStateSymbol';
import styles from './HandInfo.module.scss';

const HandInfo = ({ seatNumber }) => {
  const hands = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.seats[seatNumber].hands
  );
  const playingHand = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.playingHand);
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const seatBelongsToPlayer = useSelector(selectSeatBelongsToPlayer(seatNumber, publicPlayerId));

  if (hands?.length === 0) return null;

  return (
    <div className={styles.handInfo} data-cy="handInfo">
      {hands?.map(({ handId, cards, outcome, lastDecision }, handNumber) => (
        <HandStateSymbol
          key={handId}
          seatNumber={seatNumber}
          handNumber={handNumber}
          cards={cards}
          outcome={outcome}
          lastDecision={lastDecision}
          playingHand={playingHand}
          roundState={roundState}
          seatBelongsToPlayer={seatBelongsToPlayer}
          isHandSplitted={isHandSplitted(hands)}
        />
      ))}
    </div>
  );
};

export default HandInfo;
