import { z } from 'zod';

const envSchema = z.object({
  PROD: z.coerce.boolean(),
  MODE: z.coerce.string(),
  VITE_APP_DEFAULT_GAME_ID: z.coerce.string(),
  VITE_BACKEND_BASE_URI: z.coerce.string().default(''),
  VITE_APP_ENVIRONMENT: z.coerce.string().default('local'),
  VITE_APP_ID: z.coerce.string().default('hpg_live_game_blackjack_client'),
  VITE_APP_REVISION: z.coerce.string().default('dev'),
  VITE_APP_VENDOR: z.coerce.string().default('HPG'),
  VITE_APP_VERSION: z.coerce.string().default('1.0.0'),
  VITE_NODE_ENV: z.coerce.string().default(import.meta.env.MODE),
  VITE_OTEL_COLLECTOR_URI: z.coerce.string(),
  VITE_OTEL_DEBUG: z
    .string()
    .transform((s) => s === 'true' || s === '1')
    .default('false'),
  VITE_OTEL_ENABLED: z
    .string()
    .transform((s) => s === 'true' || s === '1')
    .default('false'),
  VITE_OTEL_SENTRY_ENABLED: z
    .string()
    .transform((s) => s === 'true' || s === '1')
    .default('false'),
  VITE_OTEL_TRACES_SAMPLER_ARG: z.coerce.number(),
  VITE_OTEL_METRIC_EXPORT_INTERVAL: z.coerce.number(),
  VITE_SENTRY_DSN: z.coerce.string(),
});

export const config = envSchema.parse(import.meta.env);
