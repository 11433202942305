import UndoButton from '@/components/UndoButton';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ChipSelector from '../ChipSelector';
import DoubleButton from '../DoubleButton';
import RepeatButton from '../RepeatButton';
import styles from './BetActionPanel.module.scss';

const BetActionPanel = () => {
  const betHistory = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.betHistory);
  const previousBets = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.previousBets);
  const seats = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.seats);
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );

  const filteredPreviousBets = useMemo(() => {
    const userSeats = seats
      .filter(Boolean)
      .filter((seat) => seat.publicPlayerId === publicPlayerId)
      .map((seat) => seat.seatNumber);

    const filteredPreviousBets = previousBets?.filter((bet) => userSeats.includes(bet.seatNumber));

    return filteredPreviousBets;
  }, [publicPlayerId, previousBets, seats]);

  const userBetHistory = useMemo(() => {
    return betHistory.filter((historyItem) => historyItem.publicPlayerId === publicPlayerId);
  }, [betHistory, publicPlayerId]);

  const isRepeatButtonVisible = useMemo(() => {
    if (filteredPreviousBets?.length > 0 && userBetHistory?.length === 0) {
      return true;
    }

    return false;
  }, [filteredPreviousBets?.length, userBetHistory?.length]);

  return (
    <div className={styles.betActionPanel}>
      <UndoButton />
      <ChipSelector />
      {isRepeatButtonVisible && <RepeatButton bets={filteredPreviousBets} />}
      {!isRepeatButtonVisible && <DoubleButton />}
    </div>
  );
};

export default BetActionPanel;
