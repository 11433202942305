import useDegree from '@/hooks/useDegree';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import HalfCircleItem from './HalfCircleItem';
import { useDispatch } from 'react-redux';
import { setBetPlaceholderPositions } from '@/store/uiSlice';

const HalfCircleLayout = ({ radius, baseDegree = 0, distance = 18, withRotation = true, children }) => {
  const [positions, setPositions] = useState();
  const transformedPositions = useRef([]);
  const itemCount = React.Children.count(children);
  const degrees = useDegree(itemCount, baseDegree, distance);
  const dispatch = useDispatch();

  const getCoordinatesOnRadius = useCallback(
    (cx, cy, degree) => {
      const radians = (degree * Math.PI) / 180;

      const x = cx + radius * Math.cos(radians);
      const y = cy + radius * Math.sin(radians);

      return { x: x, y: y };
    },
    [radius]
  );

  const onItemPositionChanged = useCallback(
    (position) => {
      const { index, x, y, degree } = position;
      transformedPositions.current[index] = { x, y, degree };

      if (transformedPositions.current.length === itemCount) {
        dispatch(setBetPlaceholderPositions(transformedPositions.current));
      }
    },
    [dispatch, itemCount]
  );

  useEffect(() => {
    const calculatePositions = () => {
      const newPositions = React.Children.map(children, (_, index) => {
        const degree = degrees[index];
        const center = { cx: 50, cy: 50 };

        const { x, y } = getCoordinatesOnRadius(center.cx, center.cy, degree);

        return { x, y, degree: withRotation ? baseDegree - degree : 0 };
      }).reverse();

      setPositions(newPositions);
    };

    calculatePositions();
  }, [baseDegree, children, degrees, getCoordinatesOnRadius, radius, withRotation]);

  return (
    <>
      {positions &&
        React.Children.map(children, (child, index) => {
          const { x, y, degree } = positions[index] || {};
          return (
            <HalfCircleItem
              key={`half-circle-item-${index}`}
              index={index}
              x={x}
              y={y}
              degree={withRotation ? degree : baseDegree}
              onPositionChanged={onItemPositionChanged}
            >
              {child}
            </HalfCircleItem>
          );
        })}
    </>
  );
};

export default HalfCircleLayout;
