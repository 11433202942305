import Button from '@/components/Button/';
import Card from '@/components/Card';
import { getBetTypeName } from '@/enums/betType';
import { countHandValue } from '@/lib/cardService';
import { formatMoney } from '@/lib/moneyService';
import cl from 'clsx';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './HistoryDetails.module.scss';

const renderResultRows = (results) => {
  return results.map(({ seatNumber, cards, bet, betType, win }, index) => (
    <tr key={`history-details-result-row-${index}`}>
      <td>Place {seatNumber + 1}</td>
      <td>{getBetTypeName(betType)}</td>
      <td>
        <div className={styles.cards} style={{ gridTemplateColumns: `repeat(${cards.length}, 1rem)` }}>
          {cards.map(({ cardId, suit, rank }) => (
            <div key={cardId} className={styles.card}>
              <Card suit={suit} rank={rank} />
            </div>
          ))}
        </div>
      </td>
      <td>{formatMoney(bet)}</td>
      <td>{formatMoney(win)}</td>
    </tr>
  ));
};

const HistoryDetails = ({ itemsCount, selectedHistoryItem, backToGameHistory, onItemSelected }) => {
  const { seatsCount } = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.settings);
  const { t } = useTranslation('common');
  const { index, reservedSeats, startedAt, closedAt, dealerCards, results } = selectedHistoryItem;

  const sumOfBets = useMemo(() => {
    return results.reduce((total, item) => total + item.bet, 0);
  }, [results]);

  const sumOfPayouts = useMemo(() => {
    return results.reduce((total, item) => total + item.win, 0);
  }, [results]);

  return (
    <div className={styles.container}>
      <div className={styles.roundInfo}>
        <div className={styles.roundInfoSection}>
          <div className={styles.label}>{t('history.gameNumber')}:</div>
          <div className={styles.value}>{format(new Date(startedAt), '#HH:mm:ss')}</div>
        </div>
        <div className={styles.roundInfoSection}>
          <div className={styles.label}>{t('history.gameClosed')}:</div>
          <div className={styles.value}>{new Date(closedAt).toLocaleString('en-US', { hour12: true })}</div>
        </div>
      </div>
      <div className={styles.results}>
        <div className={styles.dealerCardsAndPlaces}>
          <div>Dealer</div>
          <div>Your places</div>
          <div className={styles.cardList}>
            <div className={styles.cards} style={{ gridTemplateColumns: `repeat(${dealerCards?.length}, 1rem)` }}>
              {dealerCards?.map(({ id, suit, rank }) => (
                <div key={id} className={styles.card}>
                  <Card suit={suit} rank={rank} />
                </div>
              ))}
            </div>
            <div className={styles.handValue}>{countHandValue(dealerCards)}</div>
          </div>
          <div className={styles.seats}>
            {Array.from({ length: seatsCount }, (_, index) => (
              <div
                key={`history-details-seat-${index}`}
                className={cl(styles.seat, reservedSeats.includes(index) && styles.active)}
              >
                <div className={styles.seatNumber}>{index + 1}</div>
              </div>
            ))}
          </div>
        </div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Place</th>
              <th>Bet type</th>
              <th>Cards & score</th>
              <th>Bet</th>
              <th>Wins</th>
            </tr>
          </thead>
          <tbody>{renderResultRows(results)}</tbody>
          <tfoot>
            <tr>
              <td colSpan={3}>{t('history.total')}</td>
              <td>{formatMoney(sumOfBets)}</td>
              <td>{formatMoney(sumOfPayouts)}</td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className={styles.footerSection}>
        <Button disabled={index === 0} text={t('history.previousGame')} onClick={() => onItemSelected(index - 1)} />
        <Button text={t('history.backToGameHistory')} onClick={backToGameHistory} />
        <Button
          disabled={index === itemsCount - 1}
          text={t('history.nextGame')}
          onClick={() => onItemSelected(index + 1)}
        />
      </div>
    </div>
  );
};

export default HistoryDetails;
