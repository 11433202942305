import { DECISION_TYPE } from '@/enums/decisionType';
import { useEffect, useCallback, useState } from 'react';
import DoubleIcon from '@/assets/svg/double.svg?react';
import { TbArrowsMoveHorizontal, TbMinus, TbPlus } from 'react-icons/tb';
import { getHandValueString } from '@/lib/cardService';

const LastDecisionContent = ({ lastDecision, seatBelongsToPlayer, cards }) => {
  const [content, setContent] = useState();
  const displayDuration = 3000;

  const getContent = useCallback(() => {
    switch (lastDecision) {
      case DECISION_TYPE.HIT:
        return <TbPlus size="1rem" />;
      case DECISION_TYPE.STAND:
      case DECISION_TYPE.TIMEOUT_STAND:
        return <TbMinus size="1rem" />;

      case DECISION_TYPE.DOUBLE:
        return <DoubleIcon width="1rem" />;

      case DECISION_TYPE.SPLIT:
        return <TbArrowsMoveHorizontal size="1rem" />;
      default:
        return null;
    }
  }, [lastDecision]);

  const clearContent = useCallback(() => {
    const score = cards.length > 0 ? getHandValueString(cards) : '-';
    setContent(score);
  }, [cards]);

  useEffect(() => {
    let timeoutId;
    if (lastDecision) {
      const newContent = getContent();
      setContent(newContent);
      timeoutId = setTimeout(clearContent, displayDuration);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [clearContent, getContent, lastDecision]);

  return (
    <div
      style={{
        display: 'flex',
        color: seatBelongsToPlayer ? 'white' : 'inherit',
      }}
      data-cy="lastDecisionContent"
    >
      {content}
    </div>
  );
};

export default LastDecisionContent;
