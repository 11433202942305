import { DEVICE_TYPE } from '@/enums/deviceType';
import { setTags } from '@sentry/react';
import { getURLParam, Logger } from '@vpmedia/simplify';
import { config } from './configService';

export const IS_PRODUCTION = config.VITE_APP_ENVIRONMENT === 'production';
export const GAME_ID = getURLParam('game_id', config.VITE_APP_DEFAULT_GAME_ID);
export const TOKEN = getURLParam('token', null);
export const LANGUAGE =
  getURLParam('lang', getURLParam('language', navigator.language.split('-')[0])) === 'nl' ? 'nl' : 'en';
export const LOCALE_TYPE = LANGUAGE === 'nl' ? 'nl-NL' : 'en-US';
export const BASE_URL = IS_PRODUCTION ? 'https://www.goldruncasino.nl' : 'https://www.grstageserver.com';
export const IS_DESKTOP = getURLParam('desktop') === '1';
export const IS_SIMULATION = getURLParam('simulation', false) === 'true';

/**
 * Logs the initialization of the game, including various environment and configuration variables.
 */
export const initLog = () => {
  const logger = new Logger('App');
  logger.info('initLog', {
    config,
    IS_PRODUCTION,
    IS_DESKTOP,
    GAME_ID,
    TOKEN,
    LANGUAGE,
    LOCALE_TYPE,
    BASE_URL,
    IS_SIMULATION,
  });
  const dsn = config.VITE_SENTRY_DSN;
  if (dsn && dsn !== '') {
    setTags({
      'app.gameId': GAME_ID,
      'app.device': IS_DESKTOP ? DEVICE_TYPE.DESKTOP : DEVICE_TYPE.MOBILE,
      'app.language': LANGUAGE,
    });
  }
};
