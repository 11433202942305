import IconButton from '../IconButton/IconButton';
import DoubleIcon from '@/assets/svg/double.svg?react';
import styles from './DoubleButton.module.scss';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlayerTotalBet } from '@/store/selectors/gameSelectors';
import { doubleBetThunk } from '@/store/thunks/gameThunks';

const DoubleButton = () => {
  const balance = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.user.balance);
  const totalBet = useSelector(selectPlayerTotalBet);
  const dispatch = useDispatch();

  const canDoubleBet = useMemo(() => {
    return totalBet > 0 && balance >= totalBet * 2;
  }, [balance, totalBet]);

  const executeDoubleBet = useCallback(() => {
    dispatch(doubleBetThunk());
  }, [dispatch]);

  return (
    <div>
      <IconButton icon={<DoubleIcon width="1.5em" />} onClick={executeDoubleBet} disabled={!canDoubleBet} />
    </div>
  );
};

export default DoubleButton;
