import { useCallback, useEffect, useRef } from 'react';

const HalfCircleItem = ({ index, x, y, degree, children, onPositionChanged }) => {
  const itemRef = useRef();

  const itemStyle = {
    position: 'absolute',
    top: `${x}%`,
    left: `${y}%`,
    transform: `translate(-50%, -50%) rotate(${degree}deg)`,
  };

  const calculateTransformedPosition = useCallback(() => {
    const containerElement = document.querySelector('#seats');
    const containerRect = containerElement.getBoundingClientRect();
    const childRect = itemRef.current.getBoundingClientRect();
    const xOffset = childRect.left - containerRect.left;
    const yOffset = childRect.top - containerRect.top;

    const xRelativeToContainer = xOffset + childRect.width / 2;
    const yRelativeToContainer = yOffset + childRect.height / 2;

    const percentageX = (xRelativeToContainer / containerRect.width) * 100;
    const percentageY = (yRelativeToContainer / containerRect.height) * 100;

    onPositionChanged({ index, x: percentageX, y: percentageY, degree });
  }, [degree, index, onPositionChanged]);

  useEffect(() => {
    calculateTransformedPosition();
  }, [calculateTransformedPosition]);

  return (
    <div ref={itemRef} style={itemStyle}>
      {children}
    </div>
  );
};

export default HalfCircleItem;
