import React, { useMemo } from 'react';
import DecisionPanel from '../DecisionPanel';
import { DECISION_PANEL_TYPE } from '@/enums/ui';
import { useSelector } from 'react-redux';
import { selectPlayingHand, selectSeats } from '@/store/selectors/gameSelectors';
import { motion, AnimatePresence } from 'framer-motion';
import { ROUND_STATE } from '@/enums/roundState';

const LargeDecisionPanel = () => {
  const playingHand = useSelector(selectPlayingHand);
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const seats = useSelector(selectSeats);

  const isDecisionPanelVisible = useMemo(() => {
    const currentHand = seats[playingHand?.seatNumber]?.hands[playingHand.handNumber];

    return (
      roundState === ROUND_STATE.DECISION_PHASE &&
      playingHand &&
      currentHand &&
      currentHand.seatNumber === playingHand.seatNumber &&
      !currentHand.lastDecision
    );
  }, [playingHand, roundState, seats]);

  return (
    <>
      <AnimatePresence>
        {isDecisionPanelVisible && (
          <motion.div init="false" exit={{ opacity: 0 }}>
            <DecisionPanel seatNumber={playingHand.seatNumber} type={DECISION_PANEL_TYPE.LARGE} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default LargeDecisionPanel;
