import { FaUser } from 'react-icons/fa';
import styles from './PlayersDecisionStatus.module.scss';
import DotLoader from '@/components/DotLoader/DotLoader';
import { useCountUp } from 'react-countup';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ROUND_STATE } from '@/enums/roundState';

const playerCountAnimations = {
  initial: {
    opacity: 0,
    x: '1.5rem',
  },
  slideIn: {
    opacity: 1,
    x: 0,
  },
};

const dotAnimations = {
  initial: {
    opacity: 0,
  },
  slideIn: {
    opacity: 1,
  },
};

const PlayersDecisionStatus = () => {
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const playersWithoutDecision = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.playersWithoutDecision
  );
  // const [tmpStart, setTmpStart] = useState(null)
  // const prevPlayerCount = useRef(null)
  // const countUpRef = useRef(null)
  // const { update: updateCountDown, reset: resetCountDown } = useCountUp({
  //   ref: countUpRef,
  //   start: tmpStart,
  //   end: 0,
  //   duration: 5,
  //   onReset: () => console.log('Resetted!'),
  //   onUpdate: () => console.log('Updated!'),
  //   startOnMount: false,
  // })

  // useEffect(() => {
  //   if(tmpStart !== prevPlayerCount.current) {

  //   }

  // }, [tmpStart])

  // useEffect(() => {
  //   if (tmpStart && tmpStart !== prevPlayerCount.current) {
  //     prevPlayerCount.current = tmpStart
  //   }
  //   updateCountDown(playersWithoutDecision)
  // }, [playersWithoutDecision, tmpStart, updateCountDown])

  // useEffect(() => {
  //   const count = 20
  //   const intervalId = setInterval(() => {
  //     setTmpStart((prev) => (prev && prev - 5 >= 0 ? prev - 1 : count))
  //   }, 1000)

  //   return () => {
  //     clearInterval(intervalId)
  //   }
  // }, [])

  if (roundState !== ROUND_STATE.DECISION_PHASE || playersWithoutDecision === null) return null;

  return (
    <div className={styles.container}>
      <motion.div
        variants={playerCountAnimations}
        initial="initial"
        animate="slideIn"
        transition={{ duration: 0.6 }}
        className={styles.playerCount}
      >
        <div>{playersWithoutDecision}</div>
        {/* <div ref={countUpRef} /> */}
      </motion.div>
      <div className={styles.playerIcon}>
        <FaUser size="1rem" />
      </div>
      <motion.div
        variants={dotAnimations}
        initial="initial"
        animate="slideIn"
        transition={{ duration: 0.6 }}
        className={styles.loader}
      >
        <DotLoader />
      </motion.div>
    </div>
  );
};

export default PlayersDecisionStatus;
