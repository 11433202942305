import IconButton from '../IconButton/IconButton';
import { AiOutlineUndo } from 'react-icons/ai';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styles from './RepeatButton.module.scss';
import { addBetThunk } from '@/store/thunks/gameThunks';

const RepeatButton = ({ bets }) => {
  const dispatch = useDispatch();

  const executeRepeatBet = useCallback(() => {
    dispatch(addBetThunk(bets));
  }, [bets, dispatch]);

  return (
    <div className={styles.icon}>
      <IconButton icon={<AiOutlineUndo size="1.5em" />} onClick={executeRepeatBet} />
    </div>
  );
};

export default RepeatButton;
