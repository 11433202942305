import Header from '@/components/Header';
import Footer from '@/components/Footer';
import styles from './DesktopLayout.module.scss';
import { useSelector } from 'react-redux';
import DealerHand from '../DealerHand';
import CompactView from '../CompactView';
import StreamView from '../StreamView';
import MainView from '../MainView';
import PlayersDecisionStatus from '../InfiniteBlackjack/PlayersDecisionStatus';
import { GAME_TYPE } from '@/enums/gameType';
import { Logger } from '@vpmedia/simplify';

const logger = new Logger('DesktopLayout');

const DesktopLayout = () => {
  const isInitialized = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.isInitialized
  );
  const compactViewOpened = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.compactViewOpened
  );
  const gameType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.gameType);

  logger.info('render', { isInitialized, gameType });

  return (
    isInitialized && (
      <div className={styles.container}>
        {!compactViewOpened && (
          <div className={styles.streamContainer}>
            <StreamView />
          </div>
        )}
        <div className={styles.dealerHand}>
          <DealerHand />
        </div>
        {gameType === GAME_TYPE.INFINITE_BLACKJACK && (
          <div className={styles.playersDecisionStatus}>
            <PlayersDecisionStatus />
          </div>
        )}
        <Header />
        {compactViewOpened ? <CompactView /> : <MainView />}
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    )
  );
};

export default DesktopLayout;
