import { BET_TYPE } from '@/enums/betType';
import { ROUND_STATE } from '@/enums/roundState';
import { selectHasBetAtSeat, selectSeatBelongsToPlayer } from '@/store/selectors/gameSelectors';
import cl from 'clsx';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import styles from './BetPlaceholders.module.scss';

const BetBehind = ({ seatNumber, executeAddBet, getAreaDescription }) => {
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const mainBetsExists = useSelector(selectHasBetAtSeat(seatNumber, BET_TYPE.MAIN));
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const seatBelongsToPlayer = useSelector(selectSeatBelongsToPlayer(seatNumber, publicPlayerId));
  const betBehindBetsExists = useSelector(selectHasBetAtSeat(seatNumber, BET_TYPE.BET_BEHIND));
  const { current: betBehindId } = useRef(uuid());

  const isActive = useMemo(() => {
    if (mainBetsExists && !seatBelongsToPlayer && roundState === ROUND_STATE.BETTING_PHASE) return true;

    return false;
  }, [mainBetsExists, roundState, seatBelongsToPlayer]);

  const isPulsing = useMemo(() => {
    if (isActive && !betBehindBetsExists) return true;

    return false;
  }, [isActive, betBehindBetsExists]);

  return (
    <>
      <path
        id={betBehindId}
        data-placeholder="betBehind"
        className={cl('fieldBackground', isActive && 'active', isPulsing && 'pulsing')}
        d={getAreaDescription(BET_TYPE.BET_BEHIND)}
        onClick={() => executeAddBet(BET_TYPE.BET_BEHIND)}
      />
      <text className={cl(styles.betText)} fontSize={6} dy="-7">
        <textPath xlinkHref={`#${betBehindId}`} startOffset="5px" textLength="65">
          Bet behind
        </textPath>
      </text>
    </>
  );
};

export default BetBehind;
