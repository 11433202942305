import { PiSpeakerSimpleLowFill, PiSpeakerSimpleHighFill, PiSpeakerSimpleXFill } from 'react-icons/pi';

const SoundIndicator = ({ level, isMuted }) => {
  if (level > 0 && !isMuted) {
    if (level >= 50) return <PiSpeakerSimpleHighFill size="1.25em" />;

    return <PiSpeakerSimpleLowFill size="1.25em" />;
  }

  return <PiSpeakerSimpleXFill size="1.25em" />;
};
export default SoundIndicator;
