import { useSelector } from 'react-redux';
import styles from './InfoPanel.module.scss';
import { format } from 'date-fns';

const InfoPanel = () => {
  const streamQuality = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.settings.stream.quality
  );
  const time = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.time);
  const roundStartedAt = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.roundStartedAt
  );

  return (
    <div className={styles.container}>
      {time && <div className="time">{format(new Date(time), 'HH:mm')}</div>}
      <div className={styles.quality}>{streamQuality}</div>
      <div className="round">{roundStartedAt ? format(new Date(roundStartedAt), '#HH:mm:ss') : '#--:--:--'}</div>
    </div>
  );
};

export default InfoPanel;
