import { selectBetLimits, selectPlayerBets, selectPlayerTotalBet } from '@/store/selectors/gameSelectors';
import { selectPlayerBalance } from '@/store/selectors/userSelectors';
import { addBetThunk } from '@/store/thunks/gameThunks';
import { BetLimitReachedError, InsufficientBalanceError } from '@/utils/CustomErrors';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useBet = () => {
  const playerBalance = useSelector(selectPlayerBalance);
  const totalBet = useSelector(selectPlayerTotalBet);
  const betLimits = useSelector(selectBetLimits);
  const playerBets = useSelector(selectPlayerBets);
  const dispatch = useDispatch();

  const executeAddBetWithBalanceAndLimitCheck = useCallback(
    (betItem) => {
      const currentBetLimit = betLimits[betItem.betType];
      const { max: maxBetLimit } = currentBetLimit;

      const allBetsByCurrentBetType = playerBets.filter(
        (bet) => bet.seatNumber === betItem.seatNumber && bet.betType === betItem.betType
      );
      const sumBet = allBetsByCurrentBetType.reduce((total, betItem) => total + betItem.bet, 0);

      if (betItem.bet + sumBet > maxBetLimit) {
        const remainingBetValue = maxBetLimit - sumBet;
        if (remainingBetValue === 0) {
          throw new BetLimitReachedError('Max. bet limit reached');
        }

        betItem.bet = remainingBetValue;
      }

      if (betItem.bet + totalBet <= playerBalance) {
        dispatch(addBetThunk([betItem]));
      } else {
        throw new InsufficientBalanceError('Insufficient balance');
      }
    },
    [betLimits, playerBets, totalBet, playerBalance, dispatch]
  );

  return { executeAddBetWithBalanceAndLimitCheck };
};

export default useBet;
