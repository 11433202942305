export const DECISION_TYPE = Object.freeze({
  HIT: 'hit',
  STAND: 'stand',
  DOUBLE: 'double',
  SPLIT: 'split',
  TIMEOUT_STAND: 'timeoutStand',
});

export const INSURANCE_DECISION_TYPE = Object.freeze({
  ACCEPT: 'accept',
  DECLINE: 'decline',
});
