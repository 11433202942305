import clsx from 'clsx';
import { useCallback, useRef } from 'react';
import styles from './Chip.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { getInnerColor, getOuterColor } from '@/lib/chipService';

const Chip = ({
  value,
  size = '3em',
  onClick,
  isWinning = false,
  isDisabled = false,
  isPreview = false,
  className,
  style,
}) => {
  const borderCircleId = useRef(uuidv4());
  const borderClipPathId = useRef(uuidv4());
  const crosshairCircleId = useRef(uuidv4());
  const crosshairClipPathId = useRef(uuidv4());

  const getTextSize = useCallback((value) => {
    if (value % 1 === 0) {
      if (value >= 1 && value < 100) return 300;
      if (value >= 100 && value < 1000) return 280;
    } else {
      if (value === 0.5) return 280;
      if (value >= 1 && value < 10) return 250;
      if (value >= 10 && value < 100) return 200;
      return 180;
    }

    return 250;
  }, []);

  const renderChipValue = useCallback(
    (value) => (value % 1 === 0 ? value : Number.parseFloat(value).toFixed(2).replace('.', ',')),
    []
  );

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 1000 1000"
      className={clsx(styles.container, isDisabled && styles.disabled, isPreview && styles.previewChip, className)}
      onClick={() => onClick(value)}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <style>{`.st3{fill:#fff}.st5{clip-path:url(#${crosshairClipPathId.current})}`}</style>
      <g>
        {isWinning && (
          <svg preserveAspectRatio="xMinYMin meet" style={{ overflow: 'visible' }}>
            <defs>
              <filter id="sofGlow" width="300%" height="300%" x="-100%" y="-100%">
                <feGaussianBlur in="thicken" stdDeviation={40} result="blurred" />
              </filter>
            </defs>
            <circle cx="500" cy="500" r="500" fill="rgb(255, 235, 59)" filter="url(#sofGlow)" />
          </svg>
        )}

        <circle className="st2" cx={500} cy={500} r={463} fill={getOuterColor(value)} />
        <path
          className="st3"
          d="M500 973C239.2 973 27 760.8 27 500S239.2 27 500 27s473 212.2 473 473-212.2 473-473 473zm0-926.1C250.2 46.9 46.9 250.2 46.9 500S250.1 953.1 500 953.1 953.1 749.8 953.1 500 749.8 46.9 500 46.9z"
        />
        <defs>
          <circle id={`${borderCircleId.current}`} cx={500} cy={500} r={460.8} />
        </defs>
        <clipPath id={`${borderClipPathId.current}`}>
          <use
            xlinkHref={`#${borderCircleId.current}`}
            style={{
              overflow: 'visible',
            }}
          />
        </clipPath>
        <g
          style={{
            clipPath: `url(#${borderClipPathId.current})`,
          }}
        >
          <path className="st3" d="m497.1 503.2-266-449.5L66.5 207.6zM497.6 502.9l115-509.5-225.3-1z" />
          <path
            className="st3"
            d="M496.3 500.4 937 220.2 778 60.6zM496.3 500.4 1007 609.8l-1.5-225.3zM496.3 500.4l282.1 439.5 158.9-159.7zM496.3 500.4l-109.5 510.7 225.3-1.4zM496.3 500.4 57 783l159.9 158.7zM496.3 500.4-12.5 382.2l-2.4 225.2z"
          />
        </g>
        <defs>
          <circle id={crosshairCircleId.current} cx={499.2} cy={502.8} r={460.8} />
        </defs>
        <clipPath id={crosshairClipPathId.current}>
          <use
            xlinkHref={`#${crosshairCircleId.current}`}
            style={{
              overflow: 'visible',
            }}
          />
        </clipPath>
        <g className="st5">
          <path d="M479.3-40.3h39.9v1086.2h-39.9z" fill={getOuterColor(value)} />
        </g>
        <g className="st5">
          <path transform="rotate(90 499.228 502.79)" fill={getOuterColor(value)} d="M479.3-40.3h39.9v1086.2h-39.9z" />
        </g>
        <circle cx={499.2} cy={502.8} r={365.3} fill={getOuterColor(value)} />
        <circle cx={499.2} cy={502.8} r={328.7} fill={getInnerColor(value)} />
        <path
          className="st3"
          d="M499.2 841.5c-186.8 0-338.7-151.9-338.7-338.7 0-186.8 151.9-338.7 338.7-338.7S837.9 316 837.9 502.8c0 186.7-151.9 338.7-338.7 338.7zm0-657.5c-175.8 0-318.8 143-318.8 318.8s143 318.8 318.8 318.8S818 678.6 818 502.8 675 184 499.2 184z"
        />
        <text
          className={styles.value}
          fontSize={getTextSize(value)}
          x="50%"
          y="50%"
          dominantBaseline="central"
          textAnchor="middle"
        >
          {renderChipValue(value)}
        </text>
      </g>
    </svg>
  );
};

export default Chip;
