import clsx from 'clsx';
import { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addNewMessageThunk, setInputMessage } from '@/store/chatSlice';
import { motion, AnimatePresence } from 'framer-motion';
import { IoIosSend } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import styles from './ChatView.module.scss';
import { USER_TYPE } from '@/enums/userType';

const ChatView = ({ className }) => {
  const { t } = useTranslation('common');
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const [isMounted, setIsMounted] = useState(false);
  const messageContainer = useRef();
  const inputMessage = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.chat.inputMessage);
  const messages = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.chat.messages);
  const dispatch = useDispatch();

  const messageAnimations = {
    init: (isMine) => ({
      x: isMine ? -20 : 20,
      opacity: 0,
    }),
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  const scrollToBottom = () => {
    if (!messageContainer.current) return;
    const { current: element } = messageContainer;
    element.scrollTop = element.scrollHeight - element.clientHeight;
  };

  const sendMessage = useCallback(
    (event) => {
      event.preventDefault();
      if (!inputMessage || inputMessage.trim() === '') return;
      dispatch(addNewMessageThunk({ message: inputMessage }));
      dispatch(setInputMessage(''));
    },
    [dispatch, inputMessage]
  );

  const iconAnimations = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };

  const iconVisible = useCallback(() => inputMessage.trim() !== '', [inputMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div className={clsx(styles.container, className)}>
      <div className={clsx(styles.dealerContainer, 'dealerContainer')}>
        <div className={styles.label}>Dealer:</div>
        <div className={styles.name}>Eveline</div>
      </div>
      <div ref={messageContainer} className={clsx(styles.messages, 'messages')}>
        {messages.map(({ id, userId, screenName, message, userType }) => (
          <motion.div
            animate="visible"
            initial={isMounted ? 'init' : 'visible'}
            variants={messageAnimations}
            custom={publicPlayerId === userId}
            key={`message-${id}`}
            className={clsx(
              styles.message,
              publicPlayerId === userId && styles.isMine,
              userType === USER_TYPE.DEALER && styles.dealer
            )}
          >
            {publicPlayerId !== userId && <span className={styles.userName}>{screenName}:</span>}
            {message}
          </motion.div>
        ))}
      </div>

      <form className={clsx(styles.inputForm, 'inputForm')} onSubmit={sendMessage}>
        <div className={styles.inputContainer}>
          <input
            type="text"
            className={styles.input}
            placeholder={t('chat.placeholder')}
            value={inputMessage}
            onChange={(e) => dispatch(setInputMessage(e.target.value))}
          />
          <AnimatePresence>
            {iconVisible() && (
              <motion.div animate="visible" initial="hidden" exit="hidden" variants={iconAnimations}>
                <IoIosSend onClick={sendMessage} className={styles.icon} size="1.5rem" />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </form>
    </div>
  );
};

export default ChatView;
