import { createContext, useCallback, useState } from 'react';

export const TooltipContext = createContext();

const defaultTooltipOptions = {
  delay: 300,
  transitionDelay: 0.3,
  offset: [0, 8],
  autoHide: false,
  showDuration: 3000,
};

export const TooltipContextProvider = (props) => {
  const [referenceElement, setReferenceElement] = useState();
  const [tooltipText, setTooltipText] = useState();
  const [tooltipOptions, setTooltipOptions] = useState(defaultTooltipOptions);

  const showTooltip = useCallback((element, text, options) => {
    setReferenceElement(element);
    setTooltipText(text);
    setTooltipOptions({ ...defaultTooltipOptions, ...options });
  }, []);

  const hideTooltip = useCallback(() => {
    setReferenceElement(null);
    setTooltipText(null);
  }, []);

  return (
    <TooltipContext.Provider
      value={{
        referenceElement,
        tooltipText,
        showTooltip,
        hideTooltip,
        tooltipOptions,
      }}
    >
      {props.children}
    </TooltipContext.Provider>
  );
};
