import { SOUND_TYPE } from '@/enums/sound';
import { closeSlidePopup } from '@/store/uiSlice';
import soundService from '@/lib/soundService';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlidePopupView from './SlidePopupView';

const SlidePopupUI = () => {
  const slidePopupOpened = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupOpened
  );
  const slidePopupView = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupView
  );
  const slidePopupOptions = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupOptions
  );
  const dispatch = useDispatch();

  const handleCloseSlidePopup = useCallback(() => {
    soundService.playSound(SOUND_TYPE.PRESS);
    dispatch(closeSlidePopup());
  }, [dispatch]);

  return (
    <>
      {slidePopupOpened && (
        <SlidePopupView
          slidePopupView={slidePopupView}
          slidePopupOptions={slidePopupOptions}
          closeMenu={handleCloseSlidePopup}
        />
      )}
    </>
  );
};

export default SlidePopupUI;
