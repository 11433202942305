import { createSelector } from '@reduxjs/toolkit';

/**
 * TBD.
 * @param {import('../index').RootState} state - TBD.
 * @returns {import('../userSlice').UserState} TBD.
 */
const selectPlayer = (state) => state.user;

export const selectPlayerBalance = createSelector([selectPlayer], (user) => user.balance);

export const selectCurrentPlayerId = createSelector([selectPlayer], (user) => user.publicPlayerId);
