import { BET_TYPE } from '@/enums/betType';
import { DECISION_TYPE } from '@/enums/decisionType';
import { SOUND_TYPE } from '@/enums/sound';
import { DECISION_PANEL_TYPE } from '@/enums/ui';
import { canSplit, getHandValues } from '@/lib/cardService';
import DoubleIcon from '@/assets/svg/double.svg?react';
import { selectBetsValueBySeatNumberAndBetType, selectIsHandSplitted } from '@/store/selectors/gameSelectors';
import { setDecisionThunk } from '@/store/thunks/gameThunks';
import soundService from '@/lib/soundService';
import cl from 'clsx';
import { useCallback, useMemo } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { TbArrowsMoveHorizontal } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Tooltip from '../Tooltip';
import styles from './DecisionPanel.module.scss';
import DecisionPanelButton from './DecisionPanelButton';

const DecisionPanel = ({ seatNumber, type = DECISION_PANEL_TYPE.LARGE }) => {
  const balance = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.user.balance);
  const mainBets = useSelector(selectBetsValueBySeatNumberAndBetType(seatNumber, BET_TYPE.MAIN));
  const playingHand = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.playingHand);
  const isHandSplitted = useSelector(selectIsHandSplitted(seatNumber));
  const handNumber = useMemo(() => {
    if (playingHand && isHandSplitted) {
      return playingHand.handNumber;
    }

    return 0;
  }, [isHandSplitted, playingHand]);
  const currentHand = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.seats[seatNumber].hands[handNumber]
  );
  const cards = currentHand?.cards;
  const dispatch = useDispatch();

  const executeDecision = useCallback(
    (decision) => {
      soundService.playSound(SOUND_TYPE.PRESS);
      dispatch(setDecisionThunk({ seatNumber, handNumber, decision }));
    },
    [dispatch, handNumber, seatNumber]
  );

  const canStep = useCallback(() => {
    return true;
    // return !roundClosed && countHandValue(cards) < 21
  }, []);

  const canDoubleDown = useCallback(() => {
    const handValues = getHandValues(cards);
    return (
      !isHandSplitted &&
      cards?.length === 2 &&
      handValues.some((value) => value >= 9 && value <= 11) &&
      balance > 0 &&
      balance - mainBets >= 0
    );
  }, [balance, cards, isHandSplitted, mainBets]);

  const checkSplit = useCallback(() => {
    if (cards?.length !== 2 || isHandSplitted) return false;
    const [firstCard, secondCard] = cards;
    return canSplit([firstCard, secondCard]);
  }, [cards, isHandSplitted]);

  const buttons = useMemo(
    () => [
      {
        id: uuid(),
        action: DECISION_TYPE.DOUBLE,
        canExecute: canDoubleDown,
        icon: type === DECISION_PANEL_TYPE.SMALL ? <DoubleIcon size="0.5rem" /> : null,
        text: 'Double',
        dataCy: 'doubleButton',
        colorClass: 'orange',
      },
      {
        id: uuid(),
        action: DECISION_TYPE.HIT,
        canExecute: canStep,
        icon: <FaPlus size={type === DECISION_PANEL_TYPE.LARGE ? '1.5rem' : '0.5rem'} />,
        text: 'Hit',
        colorClass: 'green',
      },
      {
        id: uuid(),
        action: DECISION_TYPE.STAND,
        canExecute: canStep,
        icon: <FaMinus size={type === DECISION_PANEL_TYPE.LARGE ? '1.5rem' : '0.5rem'} />,
        text: 'Stand',
        colorClass: 'red',
      },
      {
        id: uuid(),
        action: DECISION_TYPE.SPLIT,
        canExecute: checkSplit,
        icon: type === DECISION_PANEL_TYPE.SMALL ? <TbArrowsMoveHorizontal size="1rem" /> : null,
        text: 'Split',
        dataCy: 'splitButton',
        colorClass: 'blue',
      },
    ],
    [canDoubleDown, canStep, checkSplit, type]
  );

  return (
    <div className={cl(styles.actionPanel, type === DECISION_PANEL_TYPE.SMALL && styles.small)} data-cy="decisionPanel">
      {buttons.map((button) => (
        <Tooltip key={button.id} content={button.text} distance={6}>
          <DecisionPanelButton
            action={button.action}
            lastDecision={currentHand?.lastDecision}
            onClick={() => executeDecision(button.action)}
            disabled={!button.canExecute()}
            icon={button.icon}
            text={type === DECISION_PANEL_TYPE.LARGE ? button.text : null}
            dataCy={button.dataCy}
            colorClass={button.colorClass}
            size={type === DECISION_PANEL_TYPE.SMALL ? 'small' : 'large'}
          />
        </Tooltip>
      ))}
    </div>
  );
};

export default DecisionPanel;
