import cl from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BET_TYPE } from '@/enums/betType';
import { ROUND_STATE } from '@/enums/roundState';
import { DECISION_PANEL_TYPE } from '@/enums/ui';
import { isPlayed } from '@/lib/handService';
import { selectHasBetAtSeat, selectSeatBelongsToPlayer } from '@/store/selectors/gameSelectors';
import { fadeInOutAnimation } from '@/utils/animations';
import DecisionPanel from '../DecisionPanel';
import HandInfo from '../HandInfo';
import LeaveSeatButton from '../LeaveSeatButton';
import WinCounter from '../WinCounter';
import styles from './SeatInfo.module.scss';

const AnimatedWrapper = ({ visible, children }) => (
  <AnimatePresence>
    {visible && (
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={fadeInOutAnimation}
        className={styles.animatedWrapper}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

const SeatInfo = ({ seatNumber }) => {
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const { roundState, seats } = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game);
  const currentSeat = seats[seatNumber];

  const { hands, consecutiveWins, screenName } = currentSeat || {};

  const seatBelongsToPlayer = useSelector(selectSeatBelongsToPlayer(seatNumber, publicPlayerId));
  const hasBetAtSeat = useSelector(selectHasBetAtSeat(seatNumber, BET_TYPE.MAIN));

  const leaveSeatButtonVisible = useMemo(() => {
    return seatBelongsToPlayer && (roundState === ROUND_STATE.BETTING_PHASE || !hasBetAtSeat);
  }, [roundState, hasBetAtSeat, seatBelongsToPlayer]);

  const decisionPanelVisible = useMemo(() => {
    if (roundState !== ROUND_STATE.DECISION_PHASE || !seatBelongsToPlayer) return false;
    const validHand = hands?.find((hand) => hand.cards.length > 1 && hand.lastDecision === null);
    return validHand && !isPlayed(validHand);
  }, [roundState, hands, seatBelongsToPlayer]);

  return (
    <>
      {currentSeat && (
        <div className={styles.seatInfo}>
          <div className={styles.top}>
            <AnimatedWrapper visible={leaveSeatButtonVisible}>
              <LeaveSeatButton seatNumber={seatNumber} />
            </AnimatedWrapper>
            {!leaveSeatButtonVisible && <HandInfo seatNumber={seatNumber} />}
          </div>
          <div className={styles.bottom}>
            <AnimatedWrapper visible={decisionPanelVisible}>
              <DecisionPanel seatNumber={seatNumber} type={DECISION_PANEL_TYPE.SMALL} />
            </AnimatedWrapper>
            {!decisionPanelVisible && roundState !== ROUND_STATE.RESOLVE_PHASE && (
              <AnimatedWrapper visible={!decisionPanelVisible}>
                <div className={cl(styles.userNameContainer, seatBelongsToPlayer && styles.isOwnSeat)}>
                  {consecutiveWins > 0 && (
                    <div className={styles.winCounter} data-cy="winCounter">
                      <WinCounter count={consecutiveWins} />
                    </div>
                  )}
                  <div className={styles.userName} data-cy="screenName">
                    {screenName}
                  </div>
                </div>
              </AnimatedWrapper>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SeatInfo;
