import { SOUND_TYPE } from '@/enums/sound';
import { formatMoney } from '@/lib/moneyService';
import soundService from '@/lib/soundService';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import styles from './HistoryViewTableRow.module.scss';

const HistoryViewTableRow = ({ index, date, bet, profit, onItemSelected }) => {
  const [isOver, setIsOver] = useState(false);

  const handleOnClick = useCallback(
    (index) => {
      soundService.playSound(SOUND_TYPE.PRESS);
      onItemSelected(index);
    },
    [onItemSelected]
  );

  return (
    <>
      <div
        className={clsx(styles.column, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={() => handleOnClick(index)}
      >
        {date.toLocaleTimeString('en-US', { hour12: true })}
      </div>
      <div
        className={clsx(styles.column, styles.right, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={() => handleOnClick(index)}
      >
        {formatMoney(bet)}
      </div>
      <div
        className={clsx(styles.column, styles.right, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={() => handleOnClick(index)}
      >
        {formatMoney(profit)}
      </div>
    </>
  );
};

export default HistoryViewTableRow;
