import { AnimatePresence, motion } from 'framer-motion';
import AutoHideChat from '../AutoHideChat';
import GameMessage from '../GameMessage';
import Prize from '../Prize';
import Table from '../Table';
import Timer from '../Timer';
import BetActionPanel from '../BetActionPanel';
import styles from './MainView.module.scss';
import { useSelector } from 'react-redux';
import { ROUND_STATE } from '@/enums/roundState';
import LargeDecisionPanel from '../LargeDecisionPanel';
import InsuranceDecisionPanel from '../InsuranceDecisionPanel';

const MainView = () => {
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);

  return (
    <div className={styles.mainContent}>
      <div className={styles.playingArea}>
        <div className={styles.tableContainer}>
          <Table />
        </div>
      </div>
      <div className={styles.autoHideChat}>
        <AutoHideChat />
      </div>
      <div className={styles.timer}>
        <Timer />
      </div>

      <div className={styles.gameMessage}>
        <GameMessage />
      </div>

      <div className={styles.prize}>
        <Prize />
      </div>

      <div className={styles.betActionPanel}>
        <AnimatePresence>
          {roundState === ROUND_STATE.BETTING_PHASE && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <BetActionPanel />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className={styles.decisionPanel}>
        <LargeDecisionPanel />
      </div>
      <div className={styles.insurancePanel}>
        <InsuranceDecisionPanel />
      </div>
    </div>
  );
};

export default MainView;
