import { BET_TYPE } from '@/enums/betType';
import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} UIState - TBD.
 * @property {boolean} limitsOpen - TBD.
 * @property {boolean} limitsPinned - TBD.
 * @property {boolean} slidePopupOpened - TBD.
 * @property {string} slidePopupView - TBD.
 * @property {boolean} slidePopupOptions - TBD.
 * @property {object} flyingBets - TBD.
 * @property {object[]} betPlaceholderPositions - TBD.
 * @property {boolean} compactViewOpened - TBD.
 * @property {boolean} chatViewOpened - TBD.
 * @property {boolean} soundEnabled - TBD.
 * @property {boolean} showLowBalancePopup - TBD.
 */

/** @type {UIState} */
const initialState = {
  limitsOpen: false,
  limitsPinned: false,
  slidePopupOpened: false,
  slidePopupView: null,
  slidePopupOptions: null,
  flyingBets: {},
  betPlaceholderPositions: [],
  compactViewOpened: false,
  chatViewOpened: false,
  soundEnabled: false,
  showLowBalancePopup: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {UIState} state - TBD.
     */
    openLimits: (state) => {
      state.limitsOpen = true;
    },
    /**
     * TBD.
     * @param {UIState} state - TBD.
     */
    closeLimits: (state) => {
      state.limitsOpen = false;
    },
    /**
     * TBD.
     * @param {UIState} state - TBD.
     */
    pinLimits: (state) => {
      state.limitsPinned = true;
    },
    /**
     * TBD.
     * @param {UIState} state - TBD.
     */
    unpinLimits: (state) => {
      state.limitsPinned = false;
    },
    /**
     * TBD.
     * @param {UIState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<object>} action - TBD.
     */
    setSlidePopupView: (state, action) => {
      const { view, options } = action.payload;
      state.slidePopupView = view;
      state.slidePopupOptions = options;
    },
    /**
     * TBD.
     * @param {UIState} state - TBD.
     */
    openSlidePopup: (state) => {
      state.slidePopupOpened = true;
    },
    /**
     * TBD.
     * @param {UIState} state - TBD.
     */
    closeSlidePopup: (state) => {
      state.slidePopupOpened = false;
    },
    /**
     * TBD.
     * @param {UIState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<object>} action - TBD.
     */
    setBetPlaceholderPositions: (state, action) => {
      state.betPlaceholderPositions = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - TBD.
     */
    setCompactViewOpened: (state, action) => {
      state.compactViewOpened = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - TBD.
     */
    setChatViewOpened: (state, action) => {
      state.chatViewOpened = action.payload;
    },
    /**
     * TBD.
     * @param {UIState} state - TBD.
     */
    toggleSound: (state) => {
      state.soundEnabled = !state.soundEnabled;
    },
    /**
     * TBD.
     * @param {UIState} state - TBD.
     */
    openLowBalancePopup: (state) => {
      state.showLowBalancePopup = true;
    },
    /**
     * TBD.
     * @param {UIState} state - TBD.
     */
    closeLowBalancePopup: (state) => {
      state.showLowBalancePopup = false;
    },
  },
});

export const {
  openLimits,
  closeLimits,
  pinLimits,
  unpinLimits,
  setSlidePopupView,
  openSlidePopup,
  closeSlidePopup,
  setBetPlaceholderPositions,
  setCompactViewOpened,
  setChatViewOpened,
  toggleSound,
  openLowBalancePopup,
  closeLowBalancePopup,
} = uiSlice.actions;

export default uiSlice.reducer;
