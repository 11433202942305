import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './RTCPlayer.module.scss';
import { Logger } from '@vpmedia/simplify';

const logger = new Logger('RTCPlayer');

const RTCPlayer = ({ domain, streamId, isCovered = false }) => {
  const soundEnabled = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.ui.soundEnabled);
  const videoPlayerRef = useRef();
  const webRTCAdaptor = useRef(null);
  const [showLoader, setShowLoader] = useState(true);

  const startPlayingStream = useCallback(() => {
    setShowLoader(false);
    videoPlayerRef.current.play();
  }, []);

  useEffect(() => {
    if (!webRTCAdaptor.current) {
      let websocketURL = `wss://${domain}/WebRTCAppEE/websocket`;

      let mediaConstraints = {
        video: false,
        audio: false,
      };

      let pc_config = {
        iceServers: [
          {
            urls: 'stun:stun1.l.google.com:19302',
          },
        ],
      };

      let sdpConstraints = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
      };

      webRTCAdaptor.current = new WebRTCAdaptor({
        websocket_url: websocketURL,
        mediaConstraints: mediaConstraints,
        peerconnection_config: pc_config,
        sdp_constraints: sdpConstraints,
        remoteVideoId: 'player',
        isPlayMode: true,
        callback: (info, description) => {
          if (info !== 'pong') {
            logger.info('info', info);
          }
          if (info === 'initialized') {
            webRTCAdaptor.current.getStreamInfo(streamId);
          } else if (info === 'streamInformation') {
            webRTCAdaptor.current.play(streamId);
          } else if (info === 'play_started') {
            startPlayingStream();
          } else if (info === 'resolutionChangeInfo') {
            logger.info('Resolution is changed', description['streamHeight']);
          } else if (info === 'ice_connection_state_changed') {
            logger.debug('ice connection state changed', description.state);
            if (description.state === 'connected' || description.state === 'completed') {
              //it means the ice connection has been established
              logger.info('ice_conn_completed');
            }
          }
        },
        callbackError: function (error, message) {
          if (
            error === 'no_stream_exist' ||
            error === 'WebSocketNotConnected' ||
            error === 'not_initialized_yet' ||
            error === 'data_store_not_available'
          ) {
            logger.info('no_stream_exist or not_initialized_yet');
          }
        },
      });
    }
    logger.info('webRTCAdaptor.current', webRTCAdaptor.current);
  }, [domain, startPlayingStream, streamId]);

  return (
    <div className={styles.container}>
      <video
        ref={videoPlayerRef}
        width="100%"
        height={isCovered ? '100%' : 'auto'}
        id="player"
        className={clsx(styles.video, isCovered && styles.covered)}
        playsInline
        muted={!soundEnabled}
        autoPlay={false}
      ></video>
      {/* {showLoader && (
        <div className={styles.loader}>
          Stream will start playing automatically when it is live
        </div>
      )} */}
    </div>
  );
};

export default RTCPlayer;
