import { countHandValue } from '@/lib/cardService';
import { useSelector } from 'react-redux';
import Card from '../Card';
import { motion } from 'framer-motion';
import styles from './DealerHand.module.scss';

const DealerHand = () => {
  const cards = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.dealerHand);

  if (cards?.length === 0) return null;

  const cardAnimations = {
    init: () => ({
      x: 0,
    }),
    animate: (index) => ({
      x: `-${index * 1}rem`,
    }),
  };

  return (
    <>
      {cards && (
        <div className={styles.dealerHand}>
          <div className={styles.cards}>
            {cards.map(({ cardId, suit, rank }, index) => (
              <motion.div
                key={cardId}
                variants={cardAnimations}
                initial="init"
                animate="animate"
                custom={cards.length - 1 - index}
                className={styles.card}
                style={{ zIndex: index }}
              >
                <Card suit={suit} rank={rank} />
                {index === 0 && <div className={styles.handValue}>{countHandValue(cards)}</div>}
              </motion.div>
            ))}
          </div>
          <div className={styles.label}>Hand of the croupier</div>
        </div>
      )}
    </>
  );
};

export default DealerHand;
