import ChatView from '../ChatView';
import StatusBar from '../StatusBar';
import Table from '../Table';
import styles from './CompactView.module.scss';

const CompactView = () => {
  return (
    <div className={styles.compactView}>
      <div className={styles.topSection}>
        <div className={styles.topRow}>
          <div className={styles.chatContainer}>
            <ChatView className={styles.chat} />
          </div>
          <div className={styles.stream}>
            <img
              className={styles.streamPic}
              src="/images//images/fake-stream.png"
              sizes="100%"
              alt="Fake stream image"
            />
          </div>
          <div className={styles.placeholder}></div>
        </div>
        <StatusBar />
      </div>

      <div className={styles.bottomSection}>
        <div className={styles.playingArea}>
          <div className={styles.tableContainer}>
            <Table withFigure="true" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompactView;
