import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} ChatMessage
 * @property {string} chatId - UUIDv4 identifier.
 * @property {string} message - Message text.
 * @property {string} screenName - Screen name.
 * @property {string} userId - Like 'GR_1'.
 * @property {string} userType - Enum player, dealer, or pitBoss.
 * @property {boolean} [isHidden] - Do not broadcast message to other players.
 */

/**
 * @typedef {object} MessageWallState - TBD.
 * @property {{prize: number, name: string}[]} winners - TBD.
 * @property {ChatMessage} lastChatMessage - TBD.
 */

/** @type {MessageWallState} */
const initialState = {
  winners: null,
  lastChatMessage: null,
};

export const messageWallSlice = createSlice({
  name: 'messageWall',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {MessageWallState} state - TBD.
     */
    clearWinners: (state) => {
      state.winners = null;
    },
    /**
     * TBD.
     * @param {MessageWallState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<object[]>} action - TBD.
     */
    showWinners: (state, action) => {
      const winners = action.payload;

      if (winners) {
        const mappedWinners = Object.keys(winners).map((key) => ({
          name: winners[key].screenName,
          prize: winners[key].win,
        }));
        state.winners = mappedWinners;
      }
    },
    setLastChatMessage: (state, action) => {
      state.lastChatMessage = action.payload;
    },
  },
});

export const { clearWinners, showWinners, setLastChatMessage } = messageWallSlice.actions;

export default messageWallSlice.reducer;
