import { createSelector } from '@reduxjs/toolkit';

/**
 * TBD.
 * @param {import('../index').RootState} state - TBD.
 * @returns {import('../gameSlice').GameState} TBD.
 */
const selectGame = (state) => state.game;

/**
 * TBD.
 * @param {import('../index').RootState} state - TBD.
 * @returns {import('../userSlice').UserState} TBD.
 */
const selectUser = (state) => state.user;

export const selectBets = createSelector([selectGame], (game) => game.betHistory);
export const selectPublicPlayerId = createSelector([selectUser], (user) => user.publicPlayerId);
export const selectResults = createSelector([selectGame], (game) => game.results);
export const selectSeats = createSelector([selectGame], (game) => game.seats);
export const selectPlayingHand = createSelector([selectGame], (game) => game.playingHand);
export const selectBetLimits = createSelector([selectGame], (game) => game.settings.betLimits);

export const selectPlayerBets = createSelector([selectBets, selectPublicPlayerId], (betHistory, publicPlayerId) => {
  return betHistory.filter((item) => item.publicPlayerId === publicPlayerId);
});

export const selectPlayerHasSeat = (publicPlayerId) =>
  createSelector(
    (state) => state.game.seats,
    (seats) => {
      const reservedSeats = Object.values(seats).filter(Boolean);
      return reservedSeats.some((seat) => seat.publicPlayerId === publicPlayerId);
    }
  );

export const selectBetsBySeatNumber = (seatNumber) =>
  createSelector(selectBets, (betHistory) => betHistory.filter((item) => item.seatNumber === seatNumber));

export const selectBetsValueBySeatNumberAndBetType = (seatNumber, betType) =>
  createSelector(selectBets, (betHistory) => {
    const bets = betHistory.filter((item) => item.seatNumber === seatNumber && item.betType === betType);
    const totalBet = bets.reduce((acc, betItem) => acc + betItem.bet, 0);

    return totalBet;
  });

export const selectSeatBelongsToPlayer = (seatNumber, publicPlayerId) =>
  createSelector(
    (state) => state.game.seats[seatNumber]?.publicPlayerId,
    (seatPlayerId) => seatPlayerId === publicPlayerId
  );

export const selectHasBetAtSeat = (seatNumber, betType) =>
  createSelector(
    (state) => state.game.betHistory,
    (betHistory) => {
      const bets = betHistory.filter((bet) => bet.seatNumber === seatNumber && bet.betType === betType);

      return bets.length > 0;
    }
  );

export const selectPlayerTotalBet = createSelector(selectPlayerBets, (betHistory) => {
  const totalBet = betHistory.reduce((acc, betItem) => acc + betItem.bet, 0);
  return totalBet;
});

export const selectIsHandSplitted = (seatNumber) =>
  createSelector(selectSeats, (seats) => {
    const currentSeat = seats[seatNumber];

    return currentSeat?.hands?.length === 2;
  });
