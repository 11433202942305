import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cl from 'clsx';
import { BET_TYPE } from '@/enums/betType';
import styles from './ChipsContainer.module.scss';
import ChipStack from '../ChipStack/ChipStack';
import { selectBets, selectResults } from '@/store/selectors/gameSelectors';

const ChipsContainer = () => {
  const seatsCount = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.settings.seatsCount
  );
  const positions = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.betPlaceholderPositions
  );
  const bets = useSelector((/** @type {import('@/store/index').RootState} */ state) => selectBets(state));
  const results = useSelector((/** @type {import('@/store/index').RootState} */ state) => selectResults(state));

  const calculateRotatedTranslation = useCallback((initialTranslateX, initialTranslateY, angle) => {
    const angleInRadians = (angle * Math.PI) / 180;
    const newTranslateX = initialTranslateX * Math.cos(angleInRadians) - initialTranslateY * Math.sin(angleInRadians);
    const newTranslateY = initialTranslateX * Math.sin(angleInRadians) + initialTranslateY * Math.cos(angleInRadians);

    return { translateX: newTranslateX, translateY: newTranslateY };
  }, []);

  const betBehindPositions = useMemo(() => {
    const initialTranslateX = 0;
    const initialTranslateY = 110;
    const betsPositions = [];

    for (let i = 0; i < seatsCount; i++) {
      const { degree } = positions[i] || {};
      const angleFineTune = degree > 0 ? 15 : -15;
      const angle = degree + (degree === 0 ? 0 : angleFineTune);
      const result = calculateRotatedTranslation(initialTranslateX, initialTranslateY, angle);
      betsPositions.push(result);
    }

    return betsPositions;
  }, [calculateRotatedTranslation, positions, seatsCount]);

  const twentyOnePlusThreePositions = useMemo(() => {
    const initialTranslateX = 120;
    const initialTranslateY = 0;
    const betsPositions = [];

    for (let i = 0; i < seatsCount; i++) {
      const { degree } = positions[i] || {};
      const angle = degree;
      const xFineTune = degree === 0 ? 0 : -10;
      const result = calculateRotatedTranslation(initialTranslateX + xFineTune, initialTranslateY, angle);
      betsPositions.push(result);
    }

    return betsPositions;
  }, [calculateRotatedTranslation, positions, seatsCount]);

  const perfectPairsPositions = useMemo(() => {
    const initialTranslateX = -120;
    const initialTranslateY = 0;
    const betsPositions = [];

    for (let i = 0; i < seatsCount; i++) {
      const { degree } = positions[i] || {};
      const angle = degree;
      const xFineTune = degree === 0 ? 0 : 10;
      const result = calculateRotatedTranslation(initialTranslateX + xFineTune, initialTranslateY, angle);
      betsPositions.push(result);
    }

    return betsPositions;
  }, [calculateRotatedTranslation, positions, seatsCount]);

  // const insurancePositions = useMemo(() => {
  //   const initialTranslateX = 110
  //   const initialTranslateY = -120
  //   const betsPositions = []

  //   for (let i = 0; i < seatsCount; i++) {
  //     const { degree } = positions[i] || {}
  //     const angleFineTune = degree > 0 ? -10 : -10
  //     const angle = degree + (degree === 0 ? 0 : angleFineTune)
  //     const result = calculateRotatedTranslation(
  //       initialTranslateX,
  //       initialTranslateY,
  //       angle
  //     )
  //     betsPositions.push(result)
  //   }

  //   return betsPositions
  // }, [calculateRotatedTranslation, positions, seatsCount])

  return (
    <>
      {positions &&
        Array.from({ length: seatsCount }).map((_, index) => {
          const { x, y } = positions[index] || {};
          const chipsStyle = {
            position: 'absolute',
            top: `${y}%`,
            left: `${x}%`,
            transform: `translate(-50%, -50%)`,
          };

          return (
            <div key={`chips-${index}`} style={chipsStyle}>
              {/* <div
                style={{
                  transform: `translate(-50%, -50%) translateX(${insurancePositions[index].translateX}%) translateY(${insurancePositions[index].translateY}%)`,
                }}
                className={cl(styles.chipContainer)}
              >
                <ChipStack
                  seatNumber={index}
                  bets={bets}
                  results={results}
                  betTypes={[BET_TYPE.INSURANCE]}
                  chipSize="1.25em"
                />
              </div> */}

              <div
                style={{
                  transform: `translate(-50%, -50%) translateX(${betBehindPositions[index].translateX}%) translateY(${betBehindPositions[index].translateY}%)`,
                }}
                className={cl(styles.chipContainer)}
              >
                <ChipStack
                  seatNumber={index}
                  bets={bets}
                  results={results}
                  betTypes={[BET_TYPE.BET_BEHIND]}
                  chipSize="1.8em"
                />
              </div>

              <div
                style={{
                  transform: `translate(-50%, -50%) translateX(${perfectPairsPositions[index].translateX}%) translateY(${perfectPairsPositions[index].translateY}%)`,
                }}
                className={cl(styles.chipContainer)}
              >
                <ChipStack
                  seatNumber={index}
                  bets={bets}
                  results={results}
                  betTypes={[BET_TYPE.PERFECT_PAIRS]}
                  chipSize="1.8em"
                />
              </div>

              <div
                style={{
                  transform: `translate(-50%, -50%) translateX(${twentyOnePlusThreePositions[index].translateX}%) translateY(${twentyOnePlusThreePositions[index].translateY}%)`,
                }}
                className={cl(styles.chipContainer)}
              >
                <ChipStack
                  seatNumber={index}
                  bets={bets}
                  results={results}
                  betTypes={[BET_TYPE.TWENTYONE_PLUS_THREE]}
                  chipSize="1.8em"
                />
              </div>

              <div className={cl(styles.chipContainer, styles.mainBetChipContainer)}>
                <ChipStack
                  seatNumber={index}
                  bets={bets}
                  results={results}
                  betTypes={[BET_TYPE.MAIN, BET_TYPE.DOUBLE, BET_TYPE.SPLIT]}
                  chipSize="2.5em"
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

export default ChipsContainer;
