import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} ViewportState - TBD.
 * @property {number} width - TBD.
 * @property {number} height - TBD.
 */

/** @type {ViewportState} */
const initialState = {
  width: null,
  height: null,
};

const viewportSlice = createSlice({
  name: 'viewport',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {ViewportState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<{width: number, height: number}>} action - TBD.
     */
    updateViewport(state, action) {
      const { width, height } = action.payload;
      state.width = width;
      state.height = height;
    },
  },
});

export const initializeViewportState = () => (dispatch) => {
  dispatch(
    viewportSlice.actions.updateViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  );
};

export const { updateViewport } = viewportSlice.actions;
export default viewportSlice.reducer;
