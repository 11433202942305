import { useModal } from '@/context/ModalContext';
import { MODAL_TYPE } from '@/enums/modalType';
import { setError } from '@/store/errorSlice';
import { setInitialized } from '@/store/gameSlice';
import { Logger } from '@vpmedia/simplify';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const logger = new Logger('StateObserver');

const StateObserver = () => {
  const { openModal } = useModal();
  const screenName = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.settings.screenName);
  const isSocketConnected = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.isSocketConnected
  );
  const isInitialized = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.isInitialized
  );
  const errorData = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.error.errorData);
  const dispatch = useDispatch();

  const closeCustomErrorDialog = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  useEffect(() => {
    if (isInitialized && !isSocketConnected) {
      dispatch(setInitialized(false));
    }
  }, [dispatch, isInitialized, isSocketConnected]);

  useEffect(() => {
    if (isInitialized && !screenName) {
      openModal('Screen name', MODAL_TYPE.SCREEN_NAME, null, false);
    }
  }, [isInitialized, openModal, screenName]);

  useEffect(() => {
    if (errorData) {
      openModal(
        'Error',
        MODAL_TYPE.CUSTOM_ERROR,
        { errorMessage: errorData.errorCode },
        true,
        true,
        closeCustomErrorDialog,
        closeCustomErrorDialog
      );
    }
  }, [closeCustomErrorDialog, errorData, openModal]);

  return null;
};

export default StateObserver;
