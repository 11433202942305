import { IS_SIMULATION } from '@/lib/paramService';
import { selectSeatBelongsToPlayer } from '@/store/selectors/gameSelectors';
import { fadeInOutAnimation } from '@/utils/animations';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import BetPlaceholders from '../BetPlaceholders';
import Hands from '../Hands';
import PlayingHandIndicator from '../PlayingHandIndicator';
import styles from './Seat.module.scss';

const Seat = ({ seatNumber }) => {
  const seat = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.seats[seatNumber]);
  const playingHand = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.playingHand);
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const seatBelongsToPlayer = useSelector(selectSeatBelongsToPlayer(seatNumber, publicPlayerId));

  const getPlayingIndicatorColor = useCallback(() => {
    if (seatBelongsToPlayer) return '#faaf3b';
    return '#ffffff';
  }, [seatBelongsToPlayer]);

  return (
    <div className={styles.seat}>
      <div className={styles.hands}>{seat?.hands && <Hands hands={seat.hands} isSimulation={IS_SIMULATION} />}</div>
      <div className={styles.betArea}>
        <div className={styles.betPlaceholders}>
          <BetPlaceholders seatNumber={seatNumber} />
          <AnimatePresence>
            {playingHand && playingHand.seatNumber === seatNumber && (
              <motion.div
                variants={fadeInOutAnimation}
                initial="hidden"
                animate="visible"
                exit="exit"
                className={styles.playingHandIndicator}
              >
                <PlayingHandIndicator color={getPlayingIndicatorColor()} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Seat;
