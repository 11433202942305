import React, { useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { addNewMessageThunk, setInputMessage } from '@/store/chatSlice';
import { IoIosSend } from 'react-icons/io';
import styles from './MessageInput.module.scss';
import { useTranslation } from 'react-i18next';

const NewMessage = () => {
  const { t } = useTranslation('common');
  const inputMessage = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.chat.inputMessage);
  const dispatch = useDispatch();

  const sendMessage = useCallback(
    (event) => {
      event.preventDefault();
      if (!inputMessage || inputMessage.trim() === '') return;
      dispatch(addNewMessageThunk({ message: inputMessage }));
      dispatch(setInputMessage(''));
    },
    [dispatch, inputMessage]
  );

  const iconAnimations = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };

  const iconVisible = useCallback(() => inputMessage.trim() !== '', [inputMessage]);

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={sendMessage}>
        <input
          className={styles.input}
          value={inputMessage}
          placeholder={t('chat.placeholder')}
          onChange={(e) => {
            dispatch(setInputMessage(e.currentTarget.value));
          }}
        />
        <AnimatePresence>
          {iconVisible() && (
            <motion.div animate="visible" initial="hidden" exit="hidden" variants={iconAnimations}>
              <IoIosSend onClick={sendMessage} className={styles.icon} size="1.25rem" />
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    </div>
  );
};

export default NewMessage;
