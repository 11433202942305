import { formatMoney } from '@/lib/moneyService';
import styles from './Limits.module.scss';
import { useSelector } from 'react-redux';
import { GAME_TYPE } from '@/enums/gameType';

const Limits = () => {
  const gameType = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.gameType);
  const betLimits = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.settings.betLimits
  );

  const isRegularBlackjack = gameType === GAME_TYPE.LIVE_BLACKJACK;

  return (
    <div className={styles.limits}>
      <div className={styles.header}>
        <div>Bet</div>
        <div>Payout</div>
      </div>
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.name}>Blackjack</div>
          <div className={styles.limit}>
            {formatMoney(betLimits.main.min)}-{formatMoney(betLimits.main.max, false)}
          </div>
        </div>
        <div className={styles.label}>Blackjack</div>
        <div className={styles.payout}>3:2</div>
        <div className={styles.label}>Winning hands</div>
        <div className={styles.payout}>1:1</div>
        <div className={styles.label}>Insurance</div>
        <div className={styles.payout}>2:1</div>
        {isRegularBlackjack ? (
          <>
            <div className={styles.section}>
              <div className={styles.name}>Bet behind</div>
              <div className={styles.limit}>
                {formatMoney(betLimits.betBehind.min)}-{formatMoney(betLimits.betBehind.max, false)}
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.name}>Perfect pairs</div>
              <div className={styles.limit}>
                {formatMoney(betLimits.perfectPairs.min)}-{formatMoney(betLimits.perfectPairs.max, false)}
              </div>
            </div>
            <div className={styles.label}>Perfect pair</div>
            <div className={styles.payout}>25:1</div>
            <div className={styles.label}>Colored pair</div>
            <div className={styles.payout}>12:1</div>
            <div className={styles.label}>Mixed pair</div>
            <div className={styles.payout}>6:1</div>
          </>
        ) : (
          <>
            <div className={styles.section}>
              <div className={styles.name}>Hot 3</div>
              <div className={styles.limit}>
                {formatMoney(betLimits.hot3.min)}-{formatMoney(betLimits.hot3.max, false)}
              </div>
            </div>
            <div className={styles.label}>7-7-7</div>
            <div className={styles.payout}>100:1</div>
            <div className={styles.label}>Total 21 suited</div>
            <div className={styles.payout}>20:1</div>
            <div className={styles.label}>Total 21 unsuited</div>
            <div className={styles.payout}>4:1</div>
            <div className={styles.label}>Total 20</div>
            <div className={styles.payout}>2:1</div>
            <div className={styles.label}>Total 19</div>
            <div className={styles.payout}>1:1</div>

            <div className={styles.section}>
              <div className={styles.name}>Any pair</div>
              <div className={styles.limit}>
                {formatMoney(betLimits.anyPair.min)}-{formatMoney(betLimits.anyPair.max, false)}
              </div>
            </div>
            <div className={styles.label}>Suited pair</div>
            <div className={styles.payout}>25:1</div>
            <div className={styles.label}>Any pair</div>
            <div className={styles.payout}>8:1</div>

            <div className={styles.section}>
              <div className={styles.name}>Bust it</div>
              <div className={styles.limit}>
                {formatMoney(betLimits.bustIt.min)}-{formatMoney(betLimits.bustIt.max, false)}
              </div>
            </div>
            <div className={styles.label}>Bust with 8+ cards</div>
            <div className={styles.payout}>250:1</div>
            <div className={styles.label}>Bust with 7 cards</div>
            <div className={styles.payout}>100:1</div>
            <div className={styles.label}>Bust with 6 cards</div>
            <div className={styles.payout}>50:1</div>
            <div className={styles.label}>Bust with 5 cards</div>
            <div className={styles.payout}>9:1</div>
            <div className={styles.label}>Bust with 4 cards</div>
            <div className={styles.payout}>2:1</div>
            <div className={styles.label}>Bust with 3 cards</div>
            <div className={styles.payout}>1:1</div>
          </>
        )}
        <div className={styles.section}>
          <div className={styles.name}>21 + 3</div>
          <div className={styles.limit}>
            {formatMoney(betLimits.twentyOnePlusThree.min)}-{formatMoney(betLimits.twentyOnePlusThree.max, false)}
          </div>
        </div>
        <div className={styles.label}>Suited Trips</div>
        <div className={styles.payout}>100:1</div>
        <div className={styles.label}>Straight Flush</div>
        <div className={styles.payout}>40:1</div>
        <div className={styles.label}>Three of a Kind</div>
        <div className={styles.payout}>30:1</div>
        <div className={styles.label}>Straight</div>
        <div className={styles.payout}>10:1</div>
        <div className={styles.label}>Flush</div>
        <div className={styles.payout}>5:1</div>
      </div>
    </div>
  );
};

export default Limits;
