import { LuUndo2 } from 'react-icons/lu';
import styles from './UndoButton.module.scss';
import IconButton from '../IconButton/IconButton';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlayerBets } from '@/store/selectors/gameSelectors';
import { undoBetThunk } from '@/store/thunks/gameThunks';

const UndoButton = () => {
  const { t } = useTranslation('common');
  const undoIntervalRef = useRef(null);
  const userBets = useSelector(selectPlayerBets);
  const dispatch = useDispatch();

  const startUndo = () => {
    if (undoIntervalRef.current) return;
    executeUndoBet();
    undoIntervalRef.current = setInterval(() => {
      executeUndoBet();
    }, 200);
  };

  const stopUndo = () => {
    if (undoIntervalRef.current) {
      clearInterval(undoIntervalRef.current);
      undoIntervalRef.current = null;
    }
  };

  useEffect(() => {
    return () => stopUndo();
  }, []);

  const canUndoBet = useCallback(() => {
    return userBets.length > 0;
  }, [userBets.length]);

  const executeUndoBet = useCallback(() => {
    if (!canUndoBet()) return;
    dispatch(undoBetThunk());
  }, [canUndoBet, dispatch]);

  return (
    <div className={styles.undoButton} onMouseDown={startUndo} onMouseUp={stopUndo} onMouseLeave={stopUndo}>
      {/* <div className={styles.text}>{t('undo')}</div> */}
      <div className={styles.icon}>
        <IconButton icon={<LuUndo2 size="1.5em" />} disabled={!canUndoBet()} />
      </div>
    </div>
  );
};

export default UndoButton;
