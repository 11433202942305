import { useModal } from '@/context/ModalContext';
import { MODAL_TYPE } from '@/enums/modalType';
import { SOUND_TYPE } from '@/enums/sound';
import { QUALITY } from '@/enums/stream';
import { SCREEN_ORIENTATION } from '@/enums/ui';
import socketApi from '@/lib/socketApi';
import soundService from '@/lib/soundService';
import {
  muteSound,
  setAutoZoom,
  setHideChatFromOtherPlayers,
  setLeaveWinningBets,
  setSoundVolume,
  setStreamAutomaticAdjustment,
  setStreamQuality,
  unmuteSound,
} from '@/store/settingsSlice';
import { Logger } from '@vpmedia/simplify';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOrientation } from 'react-use';
import Button from '../Button';
import Checkbox from '../Checkbox';
import styles from './SettingsView.module.scss';
import SoundSlider from './SoundSlider';

const logger = new Logger('SettingsView');

const tabs = [
  { label: 'settings.general' },
  { label: 'settings.video' },
  { label: 'settings.sound' },
  { label: 'Bet behind' },
];

const SettingsView = ({ tabIndex = 0 }) => {
  const { t } = useTranslation('common');
  const { type: screenOrientation } = useOrientation();
  const settings = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.settings);
  const { general, soundVolume, stream } = settings;
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(tabIndex);
  const isInitialized = useRef(false);
  const { openModal } = useModal();

  const panelVariants = {
    visible: () => ({
      opacity: 1,
    }),
    hidden: () => ({ opacity: 0 }),
  };

  const handleSoundChanged = useCallback(
    (type, level) => {
      dispatch(setSoundVolume({ type, level }));
    },
    [dispatch]
  );

  const streamQualityIndex = useMemo(() => {
    let index = 0;
    for (const key in QUALITY) {
      if (QUALITY[key] === settings.stream.quality) {
        break;
      }
      index++;
    }

    return index;
  }, [settings.stream.quality]);

  const handleOnMute = useCallback(
    (type) => {
      soundService.playSound(SOUND_TYPE.PRESS);
      soundVolume[type].isMuted ? dispatch(unmuteSound({ type })) : dispatch(muteSound({ type }));
    },
    [dispatch, soundVolume]
  );

  const handleStreamQualityChanged = useCallback(
    (value) => {
      soundService.playSound(SOUND_TYPE.PRESS);
      dispatch(setStreamQuality(value));
    },
    [dispatch]
  );

  const changeScreenName = useCallback(() => {
    openModal('Screen name', MODAL_TYPE.SCREEN_NAME, null, false);
  }, [openModal]);

  const handleTabSelected = useCallback((index) => {
    soundService.playSound(SOUND_TYPE.PRESS);
    setSelectedTab(index);
  }, []);

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;
      return;
    }

    logger.info('savePlayerSettings', { settings });
    socketApi.savePlayerSettings(settings);
  }, [settings]);

  return (
    <div
      className={clsx(styles.container, isMobile && screenOrientation === SCREEN_ORIENTATION.PORTRAIT && styles.mobile)}
    >
      <div className={styles.tabs}>
        {tabs.map(({ label }, index) => (
          <div
            key={`tab-item-${index}`}
            onClick={() => handleTabSelected(index)}
            className={clsx(styles.tab, selectedTab === index && styles.active)}
          >
            {t(label)}
          </div>
        ))}
      </div>
      {selectedTab === 0 && (
        <motion.div
          className={styles.tabPanel}
          initial="hidden"
          exit="hidden"
          animate="visible"
          variants={panelVariants}
          transition={{ duration: 0.5 }}
        >
          <Checkbox
            label={t('settings.hideChat')}
            value={general?.hideChatFromOtherPlayers}
            onChange={(value) => dispatch(setHideChatFromOtherPlayers(value))}
          />
          <Checkbox
            label={t('settings.autoZoom')}
            value={general?.autoZoom}
            onChange={(value) => dispatch(setAutoZoom(value))}
          />
          <Checkbox
            label={t('settings.winningBets')}
            value={general?.leaveWinningBets}
            onChange={(value) => dispatch(setLeaveWinningBets(value))}
          />
          <Button onClick={changeScreenName}>{t('screenName.change')}</Button>
        </motion.div>
      )}
      {selectedTab === 1 && (
        <motion.div
          className={styles.tabPanel}
          initial="hidden"
          exit="hidden"
          animate="visible"
          variants={panelVariants}
          transition={{ duration: 0.5 }}
        >
          <Checkbox
            label={t('settings.autoAdjust')}
            value={stream.automaticAdjustment}
            onChange={(value) => dispatch(setStreamAutomaticAdjustment(value))}
          />
          <div className={styles.videoQualityContainer}>
            {Object.keys(QUALITY).map((key, index) => (
              <div key={key} className={styles.item}>
                {index === streamQualityIndex ? (
                  <motion.div layoutId="anchorContainer" className={styles.anchorContainer}>
                    <motion.div
                      layout
                      className={clsx(
                        styles.anchor,
                        isMobile && screenOrientation === SCREEN_ORIENTATION.PORTRAIT ? styles.right : styles.down
                      )}
                    ></motion.div>
                  </motion.div>
                ) : null}
                <div
                  onClick={() => handleStreamQualityChanged(QUALITY[key])}
                  className={clsx(styles.label, QUALITY[key] === stream.quality && styles.selected)}
                >
                  {t(`settings.${QUALITY[key]}`)}
                </div>
              </div>
            ))}
          </div>
        </motion.div>
      )}
      {selectedTab === 2 && (
        <motion.div
          className={styles.tabPanel}
          initial="hidden"
          exit="hidden"
          animate="visible"
          variants={panelVariants}
          transition={{ duration: 0.5 }}
        >
          <div className={styles.volumeContainer}>
            <div className={styles.volumeItem}>
              <div className={styles.label}>{t('settings.mainVolume')}</div>
              <div className={styles.sliderContainer}>
                <SoundSlider
                  level={soundVolume?.main?.level}
                  isMuted={soundVolume?.main?.isMuted}
                  onChanged={(level) => handleSoundChanged('main', level)}
                  onMute={() => handleOnMute('main')}
                />
              </div>
            </div>
            <div className={styles.separator}></div>
            <div className={styles.volumeItem}>
              <div className={styles.label}>{t('settings.studioSounds')}</div>
              <div className={styles.sliderContainer}>
                <SoundSlider
                  level={soundVolume?.studio?.level}
                  isMuted={soundVolume?.studio?.isMuted}
                  onChanged={(level) => handleSoundChanged('studio', level)}
                  onMute={() => handleOnMute('studio')}
                />
              </div>
            </div>
            <div className={styles.volumeItem}>
              <div className={styles.label}>{t('settings.soundEffects')}</div>
              <div className={styles.sliderContainer}>
                <SoundSlider
                  level={soundVolume?.effects?.level}
                  isMuted={soundVolume?.effects?.isMuted}
                  onChanged={(level) => handleSoundChanged('effects', level)}
                  onMute={() => handleOnMute('effects')}
                />
              </div>
            </div>
          </div>
        </motion.div>
      )}
      {selectedTab === 3 && (
        <motion.div
          className={styles.tabPanel}
          initial="hidden"
          exit="hidden"
          animate="visible"
          variants={panelVariants}
          transition={{ duration: 0.5 }}
        >
          <div>Under construction</div>
        </motion.div>
      )}
    </div>
  );
};

export default SettingsView;
