import { useMemo, useEffect, useState } from 'react';

const useDegree = (itemCount, startDegree, distance) => {
  const degreesDefault = useMemo(() => {
    const middleIndex = Math.floor(itemCount / 2);
    const degrees = [];

    if (itemCount % 2 === 1) {
      for (let i = 0; i < itemCount; i++) {
        const position = i - middleIndex;
        let degree;

        if (i < middleIndex) {
          degree = startDegree + distance * Math.abs(position);
        } else if (i === middleIndex) {
          degree = startDegree;
        } else {
          degree = startDegree - distance * Math.abs(position);
        }

        degrees.push(degree);
      }
    } else {
      const halfLength = Math.floor(itemCount / 2);
      const centerDegree = Math.floor(distance / 2);
      degrees.push(startDegree + centerDegree, startDegree - centerDegree);

      for (let i = 1; i < halfLength; i++) {
        degrees.push(degrees[0] + distance * i, degrees[1] - distance * i);
      }

      degrees.sort((a, b) => a - b);
    }

    return degrees.reverse();
  }, [distance, itemCount, startDegree]);

  const [degrees, setDegrees] = useState(degreesDefault);

  useEffect(() => {
    setDegrees(degreesDefault);
  }, [degreesDefault]);

  return degrees;
};

export default useDegree;
