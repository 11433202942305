import { USER_TYPE } from '@/enums/userType';
import clsx from 'clsx';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './MessageList.module.scss';
import { Logger } from '@vpmedia/simplify';

const logger = new Logger('MessageList');

const MessageListItem = ({ id, userType, senderUserId, userName, message, onDelete, currentUserId }) => {
  const chatAnimations = {
    visible: {
      x: 0,
      opacity: 1,
    },
    hidden: {
      x: 20,
      opacity: 0,
    },
    exit: {
      opacity: 0,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      onDelete(id);
    }, 5000);
  }, [id, onDelete]);

  return (
    <motion.div
      layout
      className={clsx(styles.message, userType === USER_TYPE.DEALER && styles.dealer)}
      animate="visible"
      initial="hidden"
      exit="exit"
      variants={chatAnimations}
    >
      {senderUserId !== currentUserId && <span className={styles.userName}>{userName}:</span>}
      {message}
    </motion.div>
  );
};

const MessageList = () => {
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const messages = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.chat.messages);
  const displayedMessages = useRef(new Set());
  const [visibleMessages, setVisibleMessages] = useState([]);

  const handleDeleteItem = useCallback((id) => {
    logger.info(id);
    setVisibleMessages((prev) => prev.filter((item) => item.id !== id));
  }, []);

  useEffect(() => {
    if (displayedMessages.current) {
      messages.forEach(({ id }) => {
        displayedMessages.current.add(id);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    messages.forEach((message) => {
      const { id: messageId } = message;
      if (!displayedMessages.current.has(messageId)) {
        displayedMessages.current.add(messageId);
        setVisibleMessages((prev) => [...prev, message]);
      }
    });
  }, [messages]);

  return (
    <div className={styles.container}>
      <LayoutGroup>
        <AnimatePresence initial={false}>
          {visibleMessages.map(({ id, userId, screenName, message, userType }) => (
            <MessageListItem
              key={id}
              id={id}
              senderUserId={userId}
              userName={screenName}
              message={message}
              onDelete={handleDeleteItem}
              currentUserId={publicPlayerId}
              userType={userType}
            />
          ))}
        </AnimatePresence>
      </LayoutGroup>
    </div>
  );
};

export default MessageList;
