import socketApi from '@/lib/socketApi';
import { Logger } from '@vpmedia/simplify';
import clsx from 'clsx';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import HistoryDetails from './HistoryDetails';
import styles from './HistoryView.module.scss';
import HistoryViewTableGroup from './HistoryViewTableGroup';

const logger = new Logger('HistoryView');

const HistoryView = () => {
  const { t } = useTranslation('common');
  const [history, setHistory] = useState([]);
  const [selectedHistoryItem, setSelectedHistoryItem] = useState(null);
  const [isBusy, setIsBusy] = useState(true);
  const dispatch = useDispatch();

  const mappedHistory = useMemo(() => {
    const remappedHistoryItems = history.map(({ roundId, totalBet, totalWin, closedAt }, index) => ({
      index,
      date: new Date(closedAt),
      bet: totalBet,
      profit: totalWin,
      roundId: roundId,
    }));

    const grouppedItems = _.groupBy(remappedHistoryItems, (item) => item.date.toLocaleDateString('en-US'));

    return Object.keys(grouppedItems).map((key) => ({
      date: new Date(key),
      bet: _.sumBy(grouppedItems[key], (item) => item.bet),
      profit: _.sumBy(grouppedItems[key], (item) => item.profit),
      items: grouppedItems[key],
    }));
  }, [history]);

  const getDetailsItem = useCallback((index) => ({ ...history[index], index }), [history]);

  const onHistoryItemSelected = useCallback(
    async (index) => {
      const item = getDetailsItem(index);
      const historyItem = await socketApi.getHistoryItem(item.roundId);
      setSelectedHistoryItem({ ...historyItem, index });
    },
    [getDetailsItem]
  );

  const backToGameHistory = useCallback(() => setSelectedHistoryItem(null), []);

  const loadHistoryItems = useCallback(async () => {
    setIsBusy(true);
    try {
      const historyItems = await socketApi.getHistory();
      setHistory(historyItems);
    } finally {
      setIsBusy(false);
    }
  }, []);

  useEffect(() => {
    loadHistoryItems();
  }, [loadHistoryItems]);

  if (isBusy)
    return (
      <div className={styles.container}>
        <div>Loading...</div>
      </div>
    );

  if (history.length === 0)
    return (
      <div className={styles.container}>
        <div>No history items found.</div>
      </div>
    );

  return (
    <div className={styles.container}>
      {selectedHistoryItem ? (
        <HistoryDetails
          itemsCount={history.length}
          onItemSelected={onHistoryItemSelected}
          selectedHistoryItem={selectedHistoryItem}
          backToGameHistory={backToGameHistory}
        />
      ) : (
        <div className={styles.gridContainer}>
          <div className={styles.header}>{t('date')}</div>
          <div className={clsx(styles.header, styles.right)}>{t('bet')}</div>
          <div className={clsx(styles.header, styles.right)}>{t('profitLoss')}</div>
          {mappedHistory.map((group, index) => (
            <HistoryViewTableGroup
              key={`history-table-group-${index}`}
              group={group}
              onItemSelected={onHistoryItemSelected}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default HistoryView;
