import Button from '../Button';
import Wallet from '../Wallet';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <>
      <div className={styles.wallet}>
        <Wallet />
      </div>
      <div className={styles.lobby}>
        <Button text="Lobby" className={styles.lobbyButton} />
      </div>
    </>
  );
};

export default Footer;
