import errorReducer from '@/store/errorSlice';
import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './chatSlice';
import gameReducer from './gameSlice';
import messageWallReducer from './messageWallSlice';
import errorMiddleware from './middlewares/errorMiddleware';
import settingsReducer from './settingsSlice';
import uiReducer from './uiSlice';
import userReducer from './userSlice';
import viewportReducer from './viewportSlice';

/**
 * @typedef {object} RootState - TBD.
 * @property {import('./gameSlice').GameState} game - TBD.
 * @property {import('./userSlice').UserState} user - TBD.
 * @property {import('./uiSlice').UIState} ui - TBD.
 * @property {import('./settingsSlice').SettingsState} settings - TBD.
 * @property {import('./messageWallSlice').MessageWallState} messageWall - TBD.
 * @property {import('./chatSlice').ChatState} chat - TBD.
 * @property {import('./viewportSlice').ViewportState} table - TBD.
 * @property {import('./errorSlice').ErrorState} error - TBD.
 */

export const getStore = () => {
  return configureStore({
    reducer: {
      game: gameReducer,
      user: userReducer,
      ui: uiReducer,
      settings: settingsReducer,
      chat: chatReducer,
      messageWall: messageWallReducer,
      viewport: viewportReducer,
      error: errorReducer,
    },
    // eslint-disable-next-line unicorn/prefer-spread
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(errorMiddleware),
  });
};

export const store = getStore();
