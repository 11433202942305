import AspectRatioContainer from '@/components/AspectRatioContainer';
import DesktopLayout from '@/components/DesktopLayout/';
import Modal from '@/components/Modal';
import SocketHandler from '@/components/SocketHandler/';
import SyncAnimations from '@/components/SyncAnimations';
import { initializeViewportState } from '@/store/viewportSlice';
import soundService from '@/lib/soundService';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from '@/styles/App.module.scss';
import { Logger } from '@vpmedia/simplify';

const logger = new Logger('index');

const App = () => {
  const [clientLoaded, setClientLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeViewportState());
    soundService.loadSounds();
    setClientLoaded(true);
  }, [dispatch]);

  logger.info('render', { clientLoaded });

  return (
    <div className={styles.container}>
      {clientLoaded && (
        <>
          <SocketHandler />
          <SyncAnimations />
        </>
      )}
      {clientLoaded && (
        <AspectRatioContainer>
          <DesktopLayout />
          <Modal />
        </AspectRatioContainer>
      )}
    </div>
  );
};

export default App;
