import { useCountdown } from 'react-countdown-circle-timer';
import { motion } from 'framer-motion';
import styles from './CountdownCircleTimer.module.scss';

const CountdownCircleTimer = ({ size: timerSize, duration, startAt, onTimerComplete }) => {
  const { path, pathLength, stroke, strokeDashoffset, remainingTime, strokeWidth } = useCountdown({
    isPlaying: true,
    colors: ['#61d273', '#e3d43b', '#e3d43b'],
    colorsTime: [duration, 3, 0],
    rotation: 'counterclockwise',
    initialRemainingTime: startAt,
    size: 100,
    onComplete: onTimerComplete,
    duration,
  });

  const numberVarians = {
    default: {
      scale: 1,
      transition: null,
    },
    pulse: (number) => ({
      scale: number > 5 ? 1 : [null, 0.1, 1.2, 1.2, 1],
      transition: {
        ease: 'linear',
        duration: 1,
        repeat: number > 5 ? 1 : Infinity,
      },
    }),
  };

  return (
    <div className={styles.container} style={{ width: timerSize, height: timerSize }}>
      <svg viewBox={`0 0 100 100`} className={styles.timer}>
        <circle className={styles.background} cx={50} cy={50} r={50} fill="rgba(0,0,0,.4)" />
        <path d={path} fill="none" stroke={'rgba(0,0,0,.3)'} strokeWidth={strokeWidth} />
        <path
          d={path}
          fill="none"
          stroke={stroke}
          strokeLinecap={'round'}
          strokeWidth={strokeWidth}
          strokeDasharray={pathLength}
          strokeDashoffset={strokeDashoffset}
        />

        {remainingTime >= 1 && (
          <motion.g custom={remainingTime} initial="default" animate="pulse" variants={numberVarians}>
            <text className={styles.text} dominantBaseline="central" textAnchor="middle" fontSize={45} x="50%" y="50%">
              {remainingTime}
            </text>
          </motion.g>
        )}
      </svg>
    </div>
  );
};

export default CountdownCircleTimer;
