import { useCallback, useEffect } from 'react';

const SyncAnimations = () => {
  const handleAnimationStart = useCallback((event) => {
    const animationName = event.animationName;
    const targetElement = event.target;

    const animations = document.getAnimations();

    const currentAnimation = animations.find((animation) => {
      return animation.animationName === animationName && animation.effect && animation.effect.target === targetElement;
    });

    if (currentAnimation) {
      const pulsingAnimations = animations.filter((animation) => {
        return animation.animationName === 'pulsing';
      });

      if (pulsingAnimations.length > 0) {
        const oldestPulsingAnimation = pulsingAnimations.reduce((oldest, current) => {
          return current.startTime < oldest.startTime ? current : oldest;
        });

        if (oldestPulsingAnimation && currentAnimation.startTime !== oldestPulsingAnimation.startTime) {
          currentAnimation.startTime = oldestPulsingAnimation.startTime;
        }
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener('animationstart', handleAnimationStart);

    return () => {
      document.removeEventListener('animationstart', handleAnimationStart);
    };
  }, [handleAnimationStart]);

  return null;
};

export default SyncAnimations;
