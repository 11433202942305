import { useModal } from '@/context/ModalContext';
import { CLIENT_ERROR_TYPE } from '@/enums/errorType';
import { MODAL_TYPE } from '@/enums/modalType';
import { SOCKET_CLIENT_EVENT, SOCKET_EVENT } from '@/enums/socketEvent';
import { addNewMessage } from '@/store/chatSlice';
import { setError } from '@/store/errorSlice';
import {
  addBet,
  addCardToDealer,
  addCardToPlayer,
  createNewRound,
  initGame,
  leaveSeat,
  setConsecutiveWins,
  setInitialized,
  setLastDecision,
  setPlayersWithoutDecision,
  setPlayingHand,
  setRoundState,
  setTime,
  setTimerData,
  takeSeat,
  undoBet,
} from '@/store/gameSlice';
import { setPlayerSettings } from '@/store/settingsSlice';
import { setResultThunk } from '@/store/thunks/gameThunks';
import { initUser, setIsSocketConnected, setIsUserAuthenticated, setIsUserConnected } from '@/store/userSlice';
import { Logger } from '@vpmedia/simplify';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from './../../socket';

const logger = new Logger('SocketHandler');

const SocketHandler = () => {
  logger.debug('created');
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const isUserAuthenticated = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.isUserAuthenticated
  );

  const onConnect = useCallback(async () => {
    logger.info(SOCKET_CLIENT_EVENT.ON_CONNECT);
    dispatch(setIsSocketConnected(true));
  }, [dispatch]);

  const onDisconnect = useCallback(
    (reason, description) => {
      logger.info(SOCKET_CLIENT_EVENT.ON_DISCONNECT, { reason, description, isUserAuthenticated });
      if (isUserAuthenticated) {
        dispatch(setIsSocketConnected(false));
        dispatch(setIsUserAuthenticated(false));
        dispatch(setIsUserConnected(false));
      } else {
        dispatch(
          setError({ operation: SOCKET_CLIENT_EVENT.ON_DISCONNECT, errorCode: CLIENT_ERROR_TYPE.CONNECTION_ERROR })
        );
      }
    },
    [dispatch, isUserAuthenticated]
  );

  const onAuthenticated = useCallback(
    (/** @type {{publicPlayerId: string, balance: number}} */ payload) => {
      logger.info(SOCKET_EVENT.ON_AUTHENTICATED, payload);
      dispatch(initUser(payload));
    },
    [dispatch]
  );

  const onAuthenticationError = useCallback(
    (/** @type {string} */ errorCode) => {
      logger.warn(SOCKET_EVENT.ON_AUTHENTICATION_ERROR, { errorCode });
      dispatch(setError({ operation: SOCKET_CLIENT_EVENT.ON_CONNECT, errorCode }));
    },
    [dispatch]
  );

  const onPlayerConnected = useCallback(
    (payload) => {
      logger.info(SOCKET_EVENT.ON_PLAYER_CONNECTED, payload);
      const { gameState, playerSettings } = payload;
      dispatch(initGame(gameState));
      dispatch(setPlayerSettings(playerSettings));
      dispatch(setInitialized(true));
      dispatch(setIsUserConnected(true));
    },
    [dispatch]
  );

  const onRoundCreated = useCallback(
    (payload) => {
      logger.info(SOCKET_EVENT.ON_ROUND_CREATED, payload);
      dispatch(createNewRound(payload));
    },
    [dispatch]
  );

  const onRoundChanged = useCallback(
    (payload) => {
      logger.info(SOCKET_EVENT.ON_ROUND_CHANGED, payload);
      dispatch(setRoundState(payload));
    },
    [dispatch]
  );

  const onSeatReserved = useCallback(
    (payload) => {
      logger.info(SOCKET_EVENT.ON_SEAT_RESERVED, payload);
      dispatch(takeSeat(payload));
    },
    [dispatch]
  );

  const onSeatReleased = useCallback(
    (payload) => {
      logger.info(SOCKET_EVENT.ON_SEAT_RELEASED, payload);
      dispatch(leaveSeat(payload));
    },
    [dispatch]
  );

  const onTimeChanged = useCallback(
    (payload) => {
      dispatch(setTime(payload));
    },
    [dispatch]
  );

  const onTimerChanged = useCallback(
    (payload) => {
      dispatch(setTimerData(payload));
    },
    [dispatch]
  );

  const onTimerStopped = useCallback(
    (payload) => {
      // logger.info(SOCKET_EVENT.ON_TIMER_STOPPED, payload);
      dispatch(setTimerData(null));
    },
    [dispatch]
  );

  const onCardDealtToPlayer = useCallback(
    (payload) => {
      logger.info(SOCKET_EVENT.ON_CARD_DEALT_TO_PLAYER, payload);
      dispatch(addCardToPlayer(payload));
    },
    [dispatch]
  );

  const onCardDealtToDealer = useCallback(
    (payload) => {
      logger.info(SOCKET_EVENT.ON_CARD_DEALT_TO_DEALER, payload);
      dispatch(addCardToDealer(payload));
    },
    [dispatch]
  );

  const onBetAdded = useCallback(
    (bets) => {
      logger.info(SOCKET_EVENT.ON_BET_ADDED, { bets });
      dispatch(addBet(bets));
    },
    [dispatch]
  );

  const onBetRemoved = useCallback(
    (transactionId) => {
      logger.info(SOCKET_EVENT.ON_BET_REMOVED, { transactionId });
      dispatch(undoBet(transactionId));
    },
    [dispatch]
  );

  const onPlayingHandChanged = useCallback(
    (payload) => {
      logger.info(SOCKET_EVENT.ON_PLAYING_HAND_CHANGED, payload);
      dispatch(setPlayingHand(payload));
    },
    [dispatch]
  );

  const onDecisionArrived = useCallback(
    (payload) => {
      logger.info(SOCKET_EVENT.ON_DECISION_ARRIVED, payload);
      dispatch(setLastDecision(payload));
    },
    [dispatch]
  );

  const onResultArrived = useCallback(
    (payload) => {
      logger.info(SOCKET_EVENT.ON_RESULT_ARRIVED, payload);
      dispatch(setResultThunk(payload));
    },
    [dispatch]
  );

  const onWinnersArrived = useCallback((payload) => {
    logger.info(SOCKET_EVENT.ON_WINNERS_ARRIVED, payload);
  }, []);

  const onChatMessageSent = useCallback(
    (payload) => {
      logger.info(SOCKET_EVENT.ON_CHAT_MESSAGE_SENT, payload);
      const { userId } = payload;
      // if (userId !== currentPlayerId) {
      dispatch(addNewMessage(payload));
      // }
    },
    [dispatch]
  );

  const onSettingsChanged = useCallback((/** @type {object} */ payload) => {
    logger.info(SOCKET_EVENT.ON_SETTINGS_CHANGED, payload);
  }, []);

  const onConsecutiveWinnersCalculated = useCallback(
    (payload) => {
      dispatch(setConsecutiveWins(payload));
    },
    [dispatch]
  );

  const onUserBanned = useCallback(
    (payload) => {
      logger.info('onUserBanned', payload);
      openModal('You have been banned', MODAL_TYPE.BAN, null, true, true);
    },
    [openModal]
  );

  const onRefundIssued = useCallback(
    (payload) => {
      logger.info('onRefundIssued', payload);
      const { credit } = payload;
      openModal('Refund issued', MODAL_TYPE.REFUND, { credit }, true, true);
    },
    [openModal]
  );

  // TODO: refact/rethink this infinite bj card dealing handler
  // const onCardDealtToPlayers = useCallback(
  //   (payload) => {
  //     const { playerIds } = payload
  //     if (playerIds.includes(currentPlayerId)) {
  //       dispatch(addCardToPlayer(payload))
  //     }
  //   },
  //   [currentPlayerId, dispatch]
  // )

  const onSyncPlayersWithoutDecision = useCallback(
    (payload) => {
      dispatch(setPlayersWithoutDecision(payload));
    },
    [dispatch]
  );

  useEffect(() => {
    socket.on(SOCKET_CLIENT_EVENT.ON_CONNECT, onConnect);
    socket.on(SOCKET_CLIENT_EVENT.ON_DISCONNECT, onDisconnect);
    socket.on(SOCKET_EVENT.ON_AUTHENTICATED, onAuthenticated);
    socket.on(SOCKET_EVENT.ON_AUTHENTICATION_ERROR, onAuthenticationError);
    socket.on(SOCKET_EVENT.ON_BET_ADDED, onBetAdded);
    socket.on(SOCKET_EVENT.ON_BET_REMOVED, onBetRemoved);
    socket.on(SOCKET_EVENT.ON_CARD_DEALT_TO_DEALER, onCardDealtToDealer);
    socket.on(SOCKET_EVENT.ON_CARD_DEALT_TO_PLAYER, onCardDealtToPlayer);
    socket.on(SOCKET_EVENT.ON_CHAT_MESSAGE_SENT, onChatMessageSent);
    socket.on(SOCKET_EVENT.ON_DECISION_ARRIVED, onDecisionArrived);
    socket.on(SOCKET_EVENT.ON_PLAYER_CONNECTED, onPlayerConnected);
    socket.on(SOCKET_EVENT.ON_PLAYING_HAND_CHANGED, onPlayingHandChanged);
    socket.on(SOCKET_EVENT.ON_RESULT_ARRIVED, onResultArrived);
    socket.on(SOCKET_EVENT.ON_ROUND_CHANGED, onRoundChanged);
    socket.on(SOCKET_EVENT.ON_ROUND_CREATED, onRoundCreated);
    socket.on(SOCKET_EVENT.ON_SEAT_RELEASED, onSeatReleased);
    socket.on(SOCKET_EVENT.ON_SEAT_RESERVED, onSeatReserved);
    socket.on(SOCKET_EVENT.ON_SETTINGS_CHANGED, onSettingsChanged);
    socket.on(SOCKET_EVENT.ON_TIME_CHANGED, onTimeChanged);
    socket.on(SOCKET_EVENT.ON_TIMER_CHANGED, onTimerChanged);
    socket.on(SOCKET_EVENT.ON_TIMER_STOPPED, onTimerStopped);
    socket.on(SOCKET_EVENT.ON_WINNERS_ARRIVED, onWinnersArrived);

    if (socket.auth.token) {
      if (socket.connected) {
        logger.info('Socket already connected', { connectionId: socket.id });
      } else {
        logger.info('Socket connecting');
        socket.connect();
      }
    } else {
      dispatch(setError({ operation: SOCKET_CLIENT_EVENT.ON_CONNECT, errorCode: CLIENT_ERROR_TYPE.MISSING_TOKEN }));
    }
    return () => {
      socket.off(SOCKET_CLIENT_EVENT.ON_CONNECT, onConnect);
      socket.off(SOCKET_CLIENT_EVENT.ON_DISCONNECT, onDisconnect);
      socket.off(SOCKET_EVENT.ON_AUTHENTICATED, onAuthenticated);
      socket.off(SOCKET_EVENT.ON_AUTHENTICATION_ERROR, onAuthenticationError);
      socket.off(SOCKET_EVENT.ON_BET_ADDED, onBetAdded);
      socket.off(SOCKET_EVENT.ON_BET_REMOVED, onBetRemoved);
      socket.off(SOCKET_EVENT.ON_CARD_DEALT_TO_DEALER, onCardDealtToDealer);
      socket.off(SOCKET_EVENT.ON_CARD_DEALT_TO_PLAYER, onCardDealtToPlayer);
      socket.off(SOCKET_EVENT.ON_CHAT_MESSAGE_SENT, onChatMessageSent);
      socket.off(SOCKET_EVENT.ON_DECISION_ARRIVED, onDecisionArrived);
      socket.off(SOCKET_EVENT.ON_ROUND_CREATED, onRoundCreated);
      socket.off(SOCKET_EVENT.ON_PLAYER_CONNECTED, onPlayerConnected);
      socket.off(SOCKET_EVENT.ON_PLAYING_HAND_CHANGED, onPlayingHandChanged);
      socket.off(SOCKET_EVENT.ON_RESULT_ARRIVED, onResultArrived);
      socket.off(SOCKET_EVENT.ON_ROUND_CHANGED, onRoundChanged);
      socket.off(SOCKET_EVENT.ON_SEAT_RELEASED, onSeatReleased);
      socket.off(SOCKET_EVENT.ON_SEAT_RESERVED, onSeatReserved);
      socket.off(SOCKET_EVENT.ON_SETTINGS_CHANGED, onSettingsChanged);
      socket.off(SOCKET_EVENT.ON_TIME_CHANGED, onTimeChanged);
      socket.off(SOCKET_EVENT.ON_TIMER_CHANGED, onTimerChanged);
      socket.off(SOCKET_EVENT.ON_TIMER_STOPPED, onTimerStopped);
      socket.off(SOCKET_EVENT.ON_WINNERS_ARRIVED, onWinnersArrived);
      // socket.off('consecutiveWinnersCalculated', onConsecutiveWinnersCalculated)
      // socket.off('userBanned', onUserBanned)
      // socket.off('refundIssued', onRefundIssued)
      // socket.off('cardDealtToPlayers', onCardDealtToPlayers)
      // socket.on('syncPlayersWithoutDecision', onSyncPlayersWithoutDecision)
    };
  }, [
    dispatch,
    onAuthenticated,
    onAuthenticationError,
    onBetAdded,
    onBetRemoved,
    onChatMessageSent,
    onCardDealtToDealer,
    onCardDealtToPlayer,
    onConnect,
    onDecisionArrived,
    onDisconnect,
    onRoundCreated,
    onPlayerConnected,
    onPlayingHandChanged,
    onResultArrived,
    onRoundChanged,
    onSeatReleased,
    onSeatReserved,
    onSettingsChanged,
    onTimeChanged,
    onTimerChanged,
    onTimerStopped,
    onWinnersArrived,
  ]);

  return null;
};

export default SocketHandler;
