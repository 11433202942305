import { formatMoney } from '@/lib/moneyService';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './Prize.module.scss';

const Prize = () => {
  const { t } = useTranslation('common');
  const prize = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.prize);

  const prizeAnimations = {
    hidden: { opacity: 0, y: '1rem' },
    visible: { opacity: 1, y: '0rem' },
    exit: {
      opacity: 0,
      y: '1rem',
      transition: {
        ease: 'easeOut',
      },
    },
  };

  if (!prize || prize === 0) return null;

  return (
    <motion.div className={styles.container} animate="visible" initial="hidden" exit="exit" variants={prizeAnimations}>
      <div className={styles.prizeContainer}>
        <div className={styles.prizeLabel}>{t('prize.youWin')}</div>
        <div className={styles.prizeValue}>{formatMoney(prize)}</div>
      </div>
    </motion.div>
  );
};

export default Prize;
