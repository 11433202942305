import { RESULT } from '@/enums/result';
import { PERFECT_PAIR_PAYOUT, TWENTY_ONE_PLUS_THREE_PAYOUT } from '@/enums/sideBets';
import { countHandValue, getPerfectPairType, getTwentyOnePlusThreeType, isBlackjack, isBust } from './cardService';

export const calculateHandResultAndPayout = (playerCards, bankCards, bet) => {
  if (bet === 0)
    return {
      result: RESULT.NO_BETS,
      prize: 0,
    };

  const playerPoints = countHandValue(playerCards);
  const bankPoints = countHandValue(bankCards);

  if (isBust(playerCards)) return { result: RESULT.BUST, prize: 0 };

  if (isBust(bankCards) || playerPoints > bankPoints || (isBlackjack(playerCards) && !isBlackjack(bankCards)))
    return {
      result: RESULT.WIN,
      prize: bet + (isBlackjack(playerCards) ? 1.5 * bet : bet),
    };

  if (playerPoints < bankPoints || (isBlackjack(bankCards) && !isBlackjack(playerCards)))
    return { result: RESULT.LOSE, prize: 0 };

  if (playerPoints === bankPoints) return { result: RESULT.PUSH, prize: 0 };
};

export const calculatePerfectPairResultAndPayout = (playerCards, bet) => {
  if (bet === 0)
    return {
      result: RESULT.NO_BETS,
      prize: 0,
    };

  const type = getPerfectPairType(playerCards);

  return {
    result: type ? RESULT.WIN : RESULT.LOSE,
    prize: type ? bet + bet * PERFECT_PAIR_PAYOUT[type] : 0,
  };
};

export const calculateTwentyOnePlusThreeResultAndPayout = (playerCards, bankCards, bet) => {
  if (bet === 0)
    return {
      result: RESULT.NO_BETS,
      prize: 0,
    };

  const type = getTwentyOnePlusThreeType(playerCards, bankCards[0]);

  return {
    result: type ? RESULT.WIN : RESULT.LOSE,
    prize: type ? bet + bet * TWENTY_ONE_PLUS_THREE_PAYOUT[type] : 0,
  };
};
