export const SOCKET_IO_EVENT = Object.freeze({
  ON_ERROR: 'error', // Fired upon a connection error.
  ON_RECONNECT: 'reconnect', // Fired upon a successful reconnection.
  ON_RECONNECT_ATTEMPT: 'reconnect_attempt', // Fired upon an attempt to reconnect.
  ON_RECONNECT_ERROR: 'reconnect_error', // Fired upon a reconnection attempt error.
  ON_RECONNECT_FAILED: 'reconnect_failed', // Fired when couldn't reconnect within reconnectionAttempts.
});

export const SOCKET_CLIENT_EVENT = Object.freeze({
  ON_CONNECT: 'connect', // This event is fired by the Socket instance upon connection and reconnection.
  ON_CONNECT_ERROR: 'connect_error', // This event is fired upon connection failure.
  ON_DISCONNECT: 'disconnect', // This event is fired upon disconnection.
});

export const SOCKET_ACTION = Object.freeze({
  ON_CHAT_MESSAGE_SENT: 'onChatMessageSent',
  ON_BET_ADDED: 'onBetAdded',
  ON_BET_REMOVED: 'onBetRemoved',
  ON_DECISION_ARRIVED: 'onDecisionArrived',
  ON_GET_HISTORY_ITEM: 'onGetHistoryItem',
  ON_GET_HISTORY: 'onGetHistory',
  ON_INSURANCE_DECISION_ARRIVED: 'onInsuranceDecisionArrived',
  ON_PLAYER_SETTINGS_CHANGED: 'onPlayerSettingsChanged',
  ON_SEAT_RELEASED: 'onSeatReleased',
  ON_SEAT_RESERVED: 'onSeatReserved',
});

export const SOCKET_EVENT = Object.freeze({
  ON_AUTHENTICATED: 'onAuthenticated',
  ON_AUTHENTICATION_ERROR: 'onAuthenticationError', // errorCode
  ON_BET_ADDED: 'onBetAdded',
  ON_BET_REMOVED: 'onBetRemoved',
  ON_CARD_DEALT_TO_DEALER: 'onCardDealtToDealer',
  ON_CARD_DEALT_TO_PLAYER: 'onCardDealtToPlayer',
  ON_CHAT_MESSAGE_SENT: 'onChatMessageSent',
  ON_DECISION_ARRIVED: 'onDecisionArrived',
  ON_PLAYER_CONNECTED: 'onPlayerConnected',
  ON_PLAYING_HAND_CHANGED: 'onPlayingHandChanged',
  ON_RESULT_ARRIVED: 'onResultArrived',
  ON_ROUND_CHANGED: 'onRoundChanged',
  ON_ROUND_CREATED: 'onRoundCreated',
  ON_SEAT_RELEASED: 'onSeatReleased',
  ON_SEAT_RESERVED: 'onSeatReserved',
  ON_SETTINGS_CHANGED: 'onSettingsChanged', // GameSettings
  ON_TIME_CHANGED: 'onTimeChanged',
  ON_TIMER_CHANGED: 'onTimerChanged',
  ON_TIMER_STOPPED: 'onTimerStopped',
  ON_WINNERS_ARRIVED: 'onWinnersArrived',
});
