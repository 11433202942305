import ChatView from '@/components/ChatView';
import HistoryView from '@/components/HistoryView';
import SettingsView from '@/components/SettingsView';
import SlidePopup from '@/components/SlidePopup';
import { MENU_VIEW } from '@/enums/ui';
import { useTranslation } from 'react-i18next';
import { GoHistory } from 'react-icons/go';
import { IoMdChatboxes, IoMdSettings } from 'react-icons/io';
import { MdOutlineQuestionMark } from 'react-icons/md';
import styles from './SlidePopupView.module.scss';

const SlidePopupView = ({ slidePopupView, slidePopupOptions, closeSlidePopup }) => {
  const { t } = useTranslation('common');
  if (!slidePopupView) return;
  let title;
  let icon;

  switch (slidePopupView) {
    case MENU_VIEW.CHAT:
      title = t('chat');
      icon = <IoMdChatboxes size="1.25em" />;
      break;
    case MENU_VIEW.SETTINGS:
      title = t('settings');
      icon = <IoMdSettings size="1.25em" />;
      break;
    case MENU_VIEW.HISTORY:
      title = t('history');
      icon = <GoHistory size="1.25em" />;
      break;
    case MENU_VIEW.HELP:
      title = t('help');
      icon = <MdOutlineQuestionMark size="1.25em" />;
      break;

    default:
      throw new Error(`Unsupported slide popup view: ${slidePopupView}`);
  }

  return (
    <SlidePopup onClose={closeSlidePopup} className={styles.slidePopup} title={title} icon={icon}>
      <div className={styles.viewWrapper}>
        {slidePopupView === MENU_VIEW.CHAT && <ChatView {...slidePopupOptions} />}
        {slidePopupView === MENU_VIEW.SETTINGS && <SettingsView {...slidePopupOptions} />}
        {slidePopupView === MENU_VIEW.HISTORY && <HistoryView {...slidePopupOptions} />}
        {/* {slidePopupView === MENU_VIEW.HELP && <HelpView {...slidePopupOptions} />}  */}
      </div>
    </SlidePopup>
  );
};

export default SlidePopupView;
