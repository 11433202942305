import { useState } from 'react';
import clsx from 'clsx';
import HistoryViewTableRow from '../HistoryViewTableRow';
import styles from './HistoryViewTableGroup.module.scss';
import { formatMoney } from '@/lib/moneyService';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

const HistoryViewTableGroup = ({ group, onItemSelected }) => {
  const [isOver, setIsOver] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const { date, bet, profit, items } = group;

  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  return (
    <>
      <div
        className={clsx(styles.column, styles.first, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={toggleIsOpen}
      >
        {isOpen ? <AiOutlineMinus /> : <AiOutlinePlus />}
        {date.toLocaleDateString('en-US')}
      </div>
      <div
        className={clsx(styles.column, styles.right, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={toggleIsOpen}
      >
        {formatMoney(bet)}
      </div>
      <div
        className={clsx(styles.column, styles.right, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        onClick={toggleIsOpen}
      >
        {formatMoney(profit)}
      </div>
      {isOpen &&
        items.map(({ index, roundId, date, bet, profit }) => (
          <HistoryViewTableRow
            key={roundId}
            index={index}
            date={date}
            bet={bet}
            profit={profit}
            onItemSelected={onItemSelected}
          />
        ))}
    </>
  );
};

export default HistoryViewTableGroup;
