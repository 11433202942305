export const MENU_VIEW = Object.freeze({
  CHAT: 'chat',
  SETTINGS: 'settings',
  HISTORY: 'history',
  HELP: 'help',
});

export const MODAL_VIEW = Object.freeze({
  LIMIT_REACHED: 'limitReached',
  SCREEN_NAME: 'screenName',
  ERROR: 'error',
});

export const SIDENAV_VIEW = Object.freeze({
  MENU: 'menu',
  CHAT: 'chat',
  HELP: 'help',
  SETTINGS: 'settings',
  LIMITS_AND_PAYOUTS: 'limitsAndPayouts',
  HISTORY: 'history',
});

export const ORIENTATION = Object.freeze({
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
});

export const SCREEN_ORIENTATION = Object.freeze({
  LANDSCAPE: 'landscape-primary',
  PORTRAIT: 'portrait-primary',
});

export const DECISION_PANEL_TYPE = Object.freeze({
  LARGE: 'large',
  SMALL: 'small',
});
