const PlayingHandIndicator = ({ color = '#ffffff' }) => {
  return (
    <svg viewBox="0 0 100 100" style={{ overflow: 'visible' }}>
      <defs>
        <linearGradient id="a" x1={0} x2={1} y1={0} y2={0} gradientUnits="objectBoundingBox">
          <stop offset="0%" stopColor={color} stopOpacity={0.01} />
          <stop offset="25%" stopColor={color} />
          <stop offset="75%" stopColor={color} />
          <stop offset="100%" stopColor={color} stopOpacity={0.01} />
        </linearGradient>
        <filter id="b" width="160%" height="160%" x="-30%" y="-30%">
          <feGaussianBlur result="glow" stdDeviation={1} />
          <feMerge>
            <feMergeNode in="glow" />
            <feMergeNode in="glow" />
            <feMergeNode in="glow" />
          </feMerge>
        </filter>
      </defs>
      <path fill="none" stroke="url(#a)" d="M0 50a50 50 0 0 0 100 0" filter="url(#b)" />
      <path fill="none" stroke="url(#a)" strokeWidth={2} d="M0 50a50 50 0 0 0 100 0" filter="url(#b)" />
    </svg>
  );
};

export default PlayingHandIndicator;
