import clsx from 'clsx';
import MessageInput from './MessageInput';
import MessageList from './MessageList';
import styles from './AutoHideChat.module.scss';
import { MENU_VIEW } from '@/enums/ui';
import { useSelector } from 'react-redux';

const AutoHideChat = ({ className }) => {
  const slidePopupOpened = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupOpened
  );
  const slidePopupView = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.slidePopupView
  );

  if (slidePopupOpened && slidePopupView === MENU_VIEW.CHAT) return null;

  return (
    <div className={clsx(styles.container, className)}>
      <MessageInput />
      <MessageList />
    </div>
  );
};

export default AutoHideChat;
