import clsx from 'clsx';
import SoundIndicator from '@/components/SoundIndicator';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import styles from './SoundSlider.module.scss';
import { useDebounce } from 'use-debounce';
import { useState, useEffect } from 'react';

const SoundSlider = ({ level: defaultLevel, isMuted, onChanged, onMute, withIcon = true, className }) => {
  const [level, setLevel] = useState(isMuted ? 0 : defaultLevel);
  const [debouncedLevel] = useDebounce(level, 500);

  useEffect(() => {
    if (isMuted) {
      setLevel(0);
    } else {
      setLevel(defaultLevel);
    }
  }, [defaultLevel, isMuted]);

  useEffect(() => {
    onChanged(debouncedLevel);
  }, [debouncedLevel, onChanged]);

  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');

  return (
    <div className={clsx(styles.container, className)}>
      {withIcon && (
        <SoundIndicator className={styles.indicator} height="1rem" level={level} isMuted={isMuted} onClick={onMute} />
      )}
      <Slider
        styles={{
          rail: { height: '0.2rem' },
          track: { backgroundColor: primaryColor, height: '0.2rem' },
          handle: {
            borderColor: primaryColor,
            backgroundColor: primaryColor,
            opacity: 1,
            boxShadow: 'none',
            height: '0.85rem',
            width: '0.85rem',
            marginTop: '-0.3rem',
          },
        }}
        min={0}
        max={100}
        value={level}
        onChange={(value) => setLevel(value)}
      />
    </div>
  );
};

export default SoundSlider;
