import { formatMoney } from '@/lib/moneyService';

const RefundModalView = ({ credit }) => {
  return (
    <div>{`We would like to inform you that we have issued a ${formatMoney(
      credit
    )} refund, which has been credited to your balance.`}</div>
  );
};

export default RefundModalView;
