import { BET_TYPE } from '@/enums/betType';
import { DECISION_TYPE } from '@/enums/decisionType';
import { ROUND_STATE } from '@/enums/roundState';
import { calculateHandResultAndPayout } from '@/lib/resultService';
import { createSlice } from '@reduxjs/toolkit';
import { Logger } from '@vpmedia/simplify';
import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {object} Seat
 * @property {string} publicPlayerId - TBD.
 * @property {string} screenName - TBD.
 * @property {object[]} hands - TBD.
 * @property {number} consecutiveWins - TBD.
 */

/**
 * @typedef {object} Winner
 * @property {string} publicPlayerId - TBD.
 * @property {string} screenName - TBD.
 * @property {number} win - TBD.
 */

/**
 * @typedef {object} Bet
 * @property {string} publicPlayerId - TBD.
 * @property {number} bet - TBD.
 * @property {string} betType - TBD.
 * @property {string} publicBetId - TBD.
 * @property {string} transactionId - TBD.
 * @property {number} seatNumber - TBD.
 */

/**
 * @typedef {object} GameState - TBD.
 * @property {boolean} isInitialized - TBD.
 * @property {string} gameId - TBD.
 * @property {string} gameType - TBD.
 * @property {string} gameName - TBD.
 * @property {string} roundId - TBD.
 * @property {string} dealer - TBD.
 * @property {string} time - TBD.
 * @property {number} selectedChip - TBD.
 * @property {Seat[]} seats - TBD.
 * @property {object} dealerHand - TBD.
 * @property {object} playingHand - TBD.
 * @property {string} roundState - TBD.
 * @property {object} timer - TBD.
 * @property {Bet[]} betHistory - TBD.
 * @property {object} settings - TBD.
 * @property {string} roundStartedAt - TBD.
 * @property {object[]} results - TBD.
 * @property {number} prize - TBD.
 * @property {Winner[]} winners - TBD.
 * @property {Bet[]} previousBets - TBD.
 * @property {object[]} playersWithoutDecision - TBD.
 * @property {object} bank - TBD.
 */

const logger = new Logger('gameSlice');

/** @type {GameState} */
const initialState = {
  isInitialized: false,
  bank: null,
  gameId: null,
  gameType: null,
  gameName: null,
  roundId: null,
  dealer: null,
  time: null,
  selectedChip: 1,
  seats: {
    ...new Array(7).fill(null),
  },
  dealerHand: null,
  playingHand: null,
  roundState: null,
  timer: null,
  betHistory: [],
  settings: {
    seatsCount: 7,
    chips: [1, 2, 5, 25, 100, 500],
    betLimits: null,
  },
  roundStartedAt: null,
  results: [],
  prize: null,
  winners: null,
  previousBets: null,
  playersWithoutDecision: null,
};

const createEmptyHand = () => ({
  handId: uuidv4(),
  cards: [],
  lastDecision: null,
});

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    /**
     * TBD.
     * @param {GameState} state - TBD.
     * @param {import('@reduxjs/toolkit').PayloadAction<boolean>} action - TBD.
     */
    setInitialized: (state, action) => {
      logger.info('setInitialized', { isInitialized: action.payload });
      state.isInitialized = action.payload;
    },
    initGame: (state, action) => {
      logger.info('initGame', action.payload);
      const {
        gameType,
        time,
        gameId,
        gameName,
        roundId,
        roundState,
        settings,
        betHistory,
        reservedSeats,
        playersHands,
        dealerHand,
        roundStartedAt,
        playingHand,
        results,
      } = action.payload;

      state.time = time;
      state.gameId = gameId;
      state.gameType = gameType;
      state.gameName = gameName;
      state.roundId = roundId;
      state.roundStartedAt = roundStartedAt;
      state.roundState = roundState;
      state.settings = settings;
      state.playingHand = playingHand;
      state.results = results || [];

      const seats = Array.from({ length: settings.seatsCount }, () => null);

      const mappedPlayersHands = playersHands.reduce((remappedHands, hand) => {
        const { handId, handNumber, seatNumber, lastDecision, cards, outcome } = hand;

        remappedHands[seatNumber] = remappedHands[seatNumber] || [];
        remappedHands[seatNumber][handNumber] = remappedHands[seatNumber][handNumber] || {};

        remappedHands[seatNumber][handNumber] = {
          handId,
          cards,
          lastDecision,
          outcome,
        };

        return remappedHands;
      }, {});

      for (const seat of reservedSeats) {
        const { seatNumber } = seat;
        if (seatNumber >= 0 && seatNumber < settings.seatsCount) {
          seats[seatNumber] = {
            ...seat,
            hands: mappedPlayersHands[seatNumber] || [],
          };
        }
      }

      state.seats = seats;
      state.dealerHand = dealerHand.map((card) => ({ cardId: uuidv4(), ...card }));
      state.betHistory = betHistory;
      state.selectedChip = settings.chips[0];
    },
    closeRound: (state, action) => {
      const { seatNumber, handNumber } = action.payload;
      state.seats[seatNumber].hands[handNumber].roundClosed = true;
    },
    calculateResult: (state) => {
      const { cards: bankCards } = state.bank;
      Object.values(state.seats)
        .filter(Boolean)
        .forEach((seat) => {
          seat.hands.forEach((hand) => {
            if (hand.bet > 0) {
              const { result, prize } = calculateHandResultAndPayout(hand.cards, bankCards, hand.bet);
              hand.result = result;
              hand.prize = prize;
            }
          });
        });
    },
    takeSeat: (state, action) => {
      logger.debug('takeSeat', action.payload);
      const { publicPlayerId, seatNumber, screenName } = action.payload;
      state.seats[seatNumber] = {
        id: uuidv4(),
        publicPlayerId,
        screenName,
        seatNumber,
        hands: [],
      };
    },
    leaveSeat: (state, action) => {
      logger.debug('leaveSeat', action.payload);
      const { seatNumber } = action.payload;
      if (!state.seats[seatNumber]) return;
      state.seats[seatNumber] = null;
      state.betHistory = state.betHistory.filter((bet) => bet.seatNumber !== seatNumber);
    },
    setSelectedChip: (state, action) => {
      logger.debug('setSelectedChip', { payload: action.payload });
      state.selectedChip = action.payload;
    },
    setPlayingHand: (state, action) => {
      logger.debug('setPlayingHand', action.payload);
      state.playingHand = action.payload;
    },
    addBet: (state, action) => {
      const bets = action.payload;
      logger.debug('addBet', { bets });
      for (const bet of bets) {
        state.betHistory = state.betHistory.filter((betItem) => betItem.transactionId !== bet.transactionId);
      }
      state.betHistory.push(...bets);
    },
    undoBet: (state, action) => {
      logger.debug('undoBet', action.payload);
      const transactionId = action.payload;
      state.betHistory = state.betHistory.filter((betItem) => betItem.transactionId !== transactionId);
    },
    addCardToPlayer: (state, action) => {
      const { seatNumber, handNumber, card } = action.payload;
      let currentHand = state.seats[seatNumber].hands[handNumber];
      if (!currentHand) {
        currentHand = createEmptyHand();
        state.seats[seatNumber].hands[handNumber] = currentHand;
      }

      if (
        state.roundState === ROUND_STATE.DECISION_PHASE &&
        currentHand.lastDecision &&
        [DECISION_TYPE.HIT, DECISION_TYPE.SPLIT].includes(currentHand.lastDecision)
      ) {
        currentHand.lastDecision = null;
      }

      state.seats[seatNumber].hands[handNumber].cards.push(card);
    },
    addCardToDealer: (state, action) => {
      const { card } = action.payload;
      state.dealerHand.push(card);
    },
    setRoundState: (state, action) => {
      const { roundId, roundState, roundStartedAt } = action.payload;
      state.roundId = roundId;
      state.roundState = roundState;
      state.roundStartedAt = roundStartedAt;

      if (roundState === ROUND_STATE.DEALER_TURN) {
        state.playingHand = null;
      }
    },
    createNewRound: (state, action) => {
      const { roundId, roundState, roundStartedAt } = action.payload;

      if (state.betHistory?.length > 0) {
        state.previousBets = state.betHistory.filter((item) =>
          [BET_TYPE.MAIN, BET_TYPE.PERFECT_PAIRS, BET_TYPE.TWENTYONE_PLUS_THREE].includes(item.betType)
        );
      }
      state.results = [];
      state.betHistory = [];
      state.dealerHand = [];
      Object.entries(state.seats).forEach(([_, entries]) => {
        if (entries) {
          entries.hands = [];
        }
      });
      state.roundId = roundId;
      state.roundStartedAt = roundStartedAt;
      state.roundState = roundState;
      state.prize = null;
    },
    setTimerData: (state, action) => {
      state.timer = action.payload;
    },
    setLastDecision: (state, action) => {
      const { seatNumber, handNumber, decision } = action.payload;

      if (decision === DECISION_TYPE.SPLIT) {
        const firstHand = state.seats[seatNumber].hands[0];
        const [firstCard, secondCard] = firstHand.cards;
        firstHand.cards = [secondCard];
        const secondHand = createEmptyHand();
        secondHand.cards.push(firstCard);
        state.seats[seatNumber].hands.push(secondHand);
      }

      state.seats[seatNumber].hands[handNumber].lastDecision = decision;
    },
    setResults: (state, action) => {
      logger.debug('setResults', { payload: action.payload });
      const results = action.payload;
      state.results = [...state.results, ...results];
    },
    setPayout: (state, action) => {
      logger.debug('setPayout', { payload: action.payload });
      state.prize = action.payload;
    },
    resetGame: (state) => {
      logger.debug('resetGame');
      state.bank.cards = [];
      Object.keys(state.seats).forEach((key) => {
        const currentSeat = state.seats[key];
        if (currentSeat) {
          currentSeat.hands = [];
        }
      });
    },
    setTime: (state, action) => {
      state.time = action.payload;
    },
    setConsecutiveWins: (state, action) => {
      logger.info('setConsecutiveWins', action.payload);
      const { winners } = action.payload;

      const winnerSet = new Set();

      winners.forEach((winner) => {
        winnerSet.add(`${winner.publicPlayerId}-${winner.seatNumber}`);
      });

      Object.values(state.seats)
        .filter(Boolean)
        .forEach(({ seatNumber, publicPlayerId, consecutiveWins }) => {
          const key = `${publicPlayerId}-${seatNumber}`;
          state.seats[seatNumber].consecutiveWins = winnerSet.has(key) ? (consecutiveWins || 0) + 1 : 0;
        });
    },
    setPlayersWithoutDecision: (state, action) => {
      const { playersWithoutDecision } = action.payload;
      state.playersWithoutDecision = playersWithoutDecision;
    },
  },
});

export const {
  setInitialized,
  initGame,
  setSelectedChip,
  takeSeat,
  leaveSeat,
  closeRound,
  calculateResult,
  setLastDecision,
  setResults,
  setPayout,
  addBet,
  undoBet,
  addCardToPlayer,
  addCardToDealer,
  createNewRound,
  setRoundState,
  setTimerData,
  resetGame,
  addBetBehind,
  setTime,
  setPlayingHand,
  setConsecutiveWins,
  setPlayersWithoutDecision,
} = gameSlice.actions;

export default gameSlice.reducer;
