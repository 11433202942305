import styles from './LowBalancePopup.module.scss';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { usePopper } from 'react-popper';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeLowBalancePopup } from '@/store/uiSlice';
import Button from '@/components/Button';

const LowBalancePopup = ({ referenceElement }) => {
  const { t } = useTranslation(['common']);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const showLowBalancePopup = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.ui.showLowBalancePopup
  );
  const dispatch = useDispatch();

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'top',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
    ],
  });

  const popupAnimations = {
    visible: { opacity: 1, transition: 0.3 },
    hidden: { opacity: 0 },
  };

  const close = useCallback(() => {
    dispatch(closeLowBalancePopup());
  }, [dispatch]);

  if (!showLowBalancePopup) return null;

  return (
    <motion.div
      ref={setPopperElement}
      variants={popupAnimations}
      initial="hidden"
      animate="visible"
      exit="hidden"
      className={styles.container}
      style={popperStyles.popper}
      {...attributes.popper}
    >
      <div className={styles.content}>
        <h4 className={styles.title}>{t('lowBalance')}</h4>
        <p className={styles.descr}>{t('lowBalancePopup.descr')}</p>
        <Button onClick={close}>{t('close').toUpperCase()}</Button>
      </div>

      <div ref={setArrowElement} className={styles.arrow} style={popperStyles.arrow} />
    </motion.div>
  );
};

export default LowBalancePopup;
