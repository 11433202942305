import cl from 'clsx';
import styles from './GameLabel.module.scss';
import { useSelector } from 'react-redux';
import { formatMoney } from '@/lib/moneyService';

const GameLabel = ({ className }) => {
  const gameName = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.gameName);
  const mainBetLimits = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.game.settings.betLimits.main
  );

  return (
    <div className={cl(styles.container, className)}>
      <div className={styles.name}>{gameName}</div>
      <div className={styles.range}>{`${formatMoney(mainBetLimits.min)} - ${formatMoney(
        mainBetLimits.max,
        false
      )}`}</div>
    </div>
  );
};

export default GameLabel;
