import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PerspectiveChip from '../PerspectiveChip';
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import styles from './ChipStack.module.scss';
import { BET_TYPE } from '@/enums/betType';
import { OUTCOME } from '@/enums/outcomeType';
import Chip from '../Chip/Chip';

const ChipStack = ({ seatNumber, bets, results, betTypes, chipSize = '3rem', stackType = 'regular' }) => {
  const [stack, setStack] = useState();
  const [stackDisplayValue, setStackDisplayValue] = useState(1);
  const [movingChips, setMovingChips] = useState([]);
  const prevBets = useRef(bets);

  const getStackKey = (category, { seatNumber, betType }) => `${category}_${betType}_${seatNumber}}`;

  const handleStackClicked = useCallback(() => {
    setMovingChips((prev) => [...prev, { id: uuid(), source: 'player', direction: 'in', value: 1 }]);
  }, []);

  const handleAnimationCompleted = useCallback(({ id, value }) => {
    setStackDisplayValue((prev) => (prev ?? 0) + value);
    setMovingChips((prev) => prev.filter((chip) => chip.id !== id));
  }, []);

  const stackTopOffset = useMemo(() => {
    if (!stack) return '0rem';
    return `${Object.values(stack).length * -10}%`;
  }, [stack]);

  const animationVariants = {
    player_in: {
      initial: { opacity: 0.7, y: '5rem' },
      animate: { opacity: 1, y: stackTopOffset },
    },
    dealer_in: {
      initial: { opacity: 0.7, y: '-5rem' },
      animate: { opacity: 1, y: stackTopOffset },
    },
    player_out: {
      initial: { opacity: 0.7, y: stackTopOffset },
      animate: { opacity: 1, y: '5rem' },
    },
    dealer_out: {
      initial: { opacity: 0.7, y: stackTopOffset },
      animate: { opacity: 1, y: '-5rem' },
    },
  };

  const stackTopValue = useMemo(
    () => (stack ? Object.values(stack).reduce((sum, value) => sum + value, 0) : null),
    [stack]
  );

  const memoizedBets = useMemo(() => {
    if (!bets) return null;

    const lostBetIds = results
      .filter((result) => result.win === 0 && result.outcome !== OUTCOME.PUSH && result.seatNumber === seatNumber)
      .map((result) => result.publicBetId);

    return bets.filter(
      (bet) => bet.seatNumber === seatNumber && betTypes.includes(bet.betType) && !lostBetIds.includes(bet.publicBetId)
    );
  }, [betTypes, bets, results, seatNumber]);

  const memoizedResults = useMemo(() => {
    if (!results) return null;

    return results.filter(
      (result) => result.seatNumber === seatNumber && betTypes.includes(result.betType) && result.win > 0
    );
  }, [betTypes, results, seatNumber]);

  useEffect(() => {
    if (memoizedBets || memoizedResults) {
      const groupedItems = {};

      memoizedBets?.forEach((item) => {
        const stackKey = getStackKey('bet', item);
        if (!groupedItems[stackKey]) {
          groupedItems[stackKey] = 0;
        }
        groupedItems[stackKey] += item.bet;
      });

      memoizedResults?.forEach((item) => {
        const stackKey = getStackKey('result', item);
        if (!groupedItems[stackKey]) {
          groupedItems[stackKey] = 0;
        }
        groupedItems[stackKey] += item.win;
      });

      setStack(groupedItems);
      prevBets.current = memoizedBets;
    }
  }, [memoizedBets, memoizedResults]);

  return (
    <div className={styles.chipStack} onClick={handleStackClicked}>
      {stack &&
        Object.entries(stack).map(([key, entries], index) => (
          <div
            key={key}
            style={{
              marginTop: `${index * -10}%`,
            }}
            className={styles.stackItem}
          >
            {stackType === 'regular' ? (
              <PerspectiveChip
                value={index === Object.values(stack).length - 1 ? stackTopValue : entries}
                size={chipSize}
              />
            ) : (
              <Chip value={index === Object.values(stack).length - 1 ? stackTopValue : entries} size={chipSize} />
            )}
          </div>
        ))}

      {/* {movingChips.map((chip) => (
        <motion.div
          key={chip.id}
          className={styles.movingChip}
          initial="initial"
          animate="animate"
          variants={animationVariants[`${chip.source}_${chip.direction}`]}
          transition={{ duration: 0.4 }}
          onAnimationComplete={() => handleAnimationCompleted(chip)}
        >
          <PerspectiveChip value={chip.value} size="3rem" />
        </motion.div>
      ))} */}
    </div>
  );
};

export default ChipStack;
