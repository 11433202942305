import { BET_TYPE } from '@/enums/betType';
import { ROUND_STATE } from '@/enums/roundState';
import { SOUND_TYPE } from '@/enums/sound';
import useBet from '@/hooks/useBet';
import { polarToCartesian } from '@/lib/geometryService';
import soundService from '@/lib/soundService';
import { selectHasBetAtSeat } from '@/store/selectors/gameSelectors';
import { Logger } from '@vpmedia/simplify';
import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import BetBehind from './BetBehind';
import styles from './BetPlaceholders.module.scss';
import MainBet from './MainBet';
import PerfectPairs from './PerfectPairs';
import TwentyOnePlusThree from './TwentyOnePlusThree';

const logger = new Logger('BetPlaceholders');

//TODO: rename it to BettingArea
const BetPlaceholders = ({ seatNumber }) => {
  const seat = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.seats?.[seatNumber]);
  const publicPlayerId = useSelector(
    (/** @type {import('@/store/index').RootState} */ state) => state.user.publicPlayerId
  );
  const selectedChip = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.selectedChip);
  const roundState = useSelector((/** @type {import('@/store/index').RootState} */ state) => state.game.roundState);
  const mainBetsExists = useSelector(selectHasBetAtSeat(seatNumber, BET_TYPE.MAIN));
  const { executeAddBetWithBalanceAndLimitCheck } = useBet();

  const { current: glowingCircleId } = useRef(uuid());

  const gap = 5;
  const width = 100;
  const height = 100;
  const cx = height / 2;
  const cy = height / 2;
  const sideBetSpread = 20;
  const sideBetRadius = height / 2 - sideBetSpread;
  const sideBetBaseAngle = (360 - 4 * gap) / 4;
  const userIconSize = 18;
  const chipIconSize = 32;

  const describeArc = useCallback((x, y, radius, spread, startAngle, endAngle) => {
    const innerStart = polarToCartesian(x, y, radius, endAngle);
    const innerEnd = polarToCartesian(x, y, radius, startAngle);
    const outerStart = polarToCartesian(x, y, radius + spread, endAngle);
    const outerEnd = polarToCartesian(x, y, radius + spread, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    let d = [
      'M',
      outerStart.x,
      outerStart.y,
      'A',
      radius + spread,
      radius + spread,
      0,
      largeArcFlag,
      0,
      outerEnd.x,
      outerEnd.y,
      'L',
      innerEnd.x,
      innerEnd.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      1,
      innerStart.x,
      innerStart.y,
      'L',
      outerStart.x,
      outerStart.y,
      'Z',
    ];

    return d.join(' ');
  }, []);

  const getAreaDescription = useCallback(
    (betType) => {
      let areaIndex;
      switch (betType) {
        case BET_TYPE.TWENTYONE_PLUS_THREE:
          areaIndex = 0;
          break;
        case BET_TYPE.BET_BEHIND:
          areaIndex = 1;
          break;
        case BET_TYPE.PERFECT_PAIRS:
          areaIndex = 2;
          break;
      }
      const startAngle = sideBetBaseAngle / 2 + gap + areaIndex * gap + areaIndex * sideBetBaseAngle;

      return describeArc(cx, cy, sideBetRadius, sideBetSpread, startAngle, startAngle + sideBetBaseAngle);
    },
    [cx, cy, describeArc, sideBetBaseAngle, sideBetRadius]
  );

  const executeAddBet = useCallback(
    (betType) => {
      if (
        !seat ||
        roundState !== ROUND_STATE.BETTING_PHASE ||
        (betType !== BET_TYPE.BET_BEHIND && seat.publicPlayerId !== publicPlayerId) ||
        (betType === BET_TYPE.BET_BEHIND && seat.publicPlayerId === publicPlayerId) ||
        (betType !== BET_TYPE.MAIN && !mainBetsExists)
      )
        return;

      try {
        const bet = {
          seatNumber: Number(seatNumber),
          bet: selectedChip,
          betType,
        };

        executeAddBetWithBalanceAndLimitCheck(bet);
      } catch (error) {
        soundService.playSound(SOUND_TYPE.NOT_ALLOWED);
        logger.error('executeAddBet', error);
      }
    },
    [executeAddBetWithBalanceAndLimitCheck, roundState, mainBetsExists, seat, seatNumber, selectedChip, publicPlayerId]
  );

  return (
    <svg data-bet-placeholder={seatNumber} viewBox={`0 0 ${width} ${height}`} className={styles.container}>
      <defs>
        <filter id={glowingCircleId}>
          <fegaussianblur result="coloredBlur" stdDeviation="4"></fegaussianblur>
          <femerge>
            <femergenode in="coloredBlur"></femergenode>
            <femergenode in="coloredBlur"></femergenode>
            <femergenode in="coloredBlur"></femergenode>
            <femergenode in="SourceGraphic"></femergenode>
          </femerge>
        </filter>
      </defs>
      {/* TODO: fix this glowing effect */}
      {/* <svg
            preserveAspectRatio="xMinYMin meet"
            style={{ overflow: 'visible' }}
          >
            <defs>
              <filter
                id={glowingCircleId}
                width="300%"
                height="300%"
                x="-100%"
                y="-100%"
              >
                <feGaussianBlur
                  in="thicken"
                  stdDeviation={40}
                  result="blurred"
                />
              </filter>
            </defs>
            <circle
              cx={cx}
              cy={cy}
              r="22.5"
              fill="rgb(255, 235, 59)"
              filter={`url(#${glowingCircleId})`}
            />
          </svg> */}
      <MainBet
        cx={cx}
        cy={cy}
        chipIconSize={chipIconSize}
        userIconSize={userIconSize}
        seatNumber={seatNumber}
        executeAddBet={executeAddBet}
      />
      <TwentyOnePlusThree
        seatNumber={seatNumber}
        getAreaDescription={getAreaDescription}
        executeAddBet={executeAddBet}
      />
      <BetBehind seatNumber={seatNumber} getAreaDescription={getAreaDescription} executeAddBet={executeAddBet} />
      <PerfectPairs seatNumber={seatNumber} getAreaDescription={getAreaDescription} executeAddBet={executeAddBet} />
    </svg>
  );
};

export default BetPlaceholders;
