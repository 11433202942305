import { SOUND_TYPE } from '@/enums/sound';
import { Howl, Howler } from 'howler';

const soundPath = './sounds/';

const soundMap = {
  [SOUND_TYPE.PRESS]: `${soundPath}/press.mp3`,
  [SOUND_TYPE.BET_ONE_CHIP]: `${soundPath}/bet_one_chip.mp3`,
  [SOUND_TYPE.BET_MULTIPLE]: `${soundPath}/bet_multiple.mp3`,
  [SOUND_TYPE.CHIP_SELECTOR]: `${soundPath}/chip_selector.mp3`,
  [SOUND_TYPE.NOT_ALLOWED]: `${soundPath}/not_allowed.mp3`,
  [SOUND_TYPE.OUT_OF_MONEY]: `${soundPath}/out_of_money.mp3`,
  [SOUND_TYPE.UNDO]: `${soundPath}/undo.mp3`,
};

const soundService = {
  sounds: {},

  loadSounds() {
    Object.keys(soundMap).forEach((sound) => {
      this.sounds[sound] = new Howl({
        src: [soundMap[sound]],
      });
    });
  },

  playSound(soundName) {
    if (this.sounds[soundName]) {
      this.sounds[soundName].play();
    } else {
      console.error(`Sound "${soundName}" not found.`);
    }
  },

  setGlobalVolume(volume = 1) {
    Howler.volume(volume);
  },
};

export default soundService;
