export const ROUND_STATE = Object.freeze({
  WAITING_FOR_PLAYERS: 'waitingForPlayers',
  BETTING_PHASE: 'bettingPhase',
  DEALING_PHASE: 'dealingPhase',
  INSURANCE_PHASE: 'insurancePhase',
  DECISION_PHASE: 'decisionPhase',
  DEALER_TURN: 'dealerTurn',
  RESOLVE_PHASE: 'resolvePhase',
  PAYOUT_PHASE: 'payoutPhase',
  CLOSED: 'closed',
});
