import styles from './HandStateSymbol.module.scss';
import { ROUND_STATE } from '@/enums/roundState';
import { useCallback, useEffect, useMemo, useState } from 'react';
import cl from 'clsx';
import { SEAT_STATE_CONTENT_TYPE } from '@/enums/seatState';
import HandValueContent from './Contents/HandValueContent';
import LastDecisionContent from './Contents/LastDecisionContent';
import OutcomeContent from './Contents/OutcomeContent';
import { OUTCOME } from '@/enums/outcomeType';
import { DECISION_TYPE } from '@/enums/decisionType';
import { isBlackjack, isBust } from '@/lib/cardService';

const HandStateSymbol = ({
  roundState,
  seatNumber,
  handNumber,
  cards,
  outcome,
  playingHand,
  lastDecision,
  seatBelongsToPlayer,
  isHandSplitted = false,
}) => {
  const [contentType, setContentType] = useState();

  const handTurn = useMemo(() => {
    return playingHand?.seatNumber === seatNumber && playingHand?.handNumber === handNumber && seatBelongsToPlayer;
  }, [playingHand, seatNumber, handNumber, seatBelongsToPlayer]);

  const getStateBackground = useCallback(() => {
    if (contentType === SEAT_STATE_CONTENT_TYPE.OUTCOME) {
      return [OUTCOME.BLACKJACK, OUTCOME.WIN, OUTCOME.PUSH].includes(outcome) ? styles.gold : styles.grey;
    }

    if (seatBelongsToPlayer && lastDecision) {
      switch (lastDecision) {
        case DECISION_TYPE.HIT:
          return styles.green;
        case DECISION_TYPE.DOUBLE:
          return styles.orange;
        case DECISION_TYPE.STAND:
        case DECISION_TYPE.TIMEOUT_STAND:
          return styles.red;
        case DECISION_TYPE.SPLIT:
          return styles.blue;
      }
    }

    if (seatBelongsToPlayer) {
      return styles.yellow;
    }

    return styles.white;
  }, [contentType, lastDecision, outcome, seatBelongsToPlayer]);

  const outcomeToDisplay = useMemo(() => {
    if (outcome) return outcome;

    if (isBlackjack(cards)) return OUTCOME.BLACKJACK;
    if (isBust(cards)) return OUTCOME.BUST;

    return null;
  }, [cards, outcome]);

  useEffect(() => {
    if (outcome || (isBlackjack(cards) && !isHandSplitted) || isBust(cards)) {
      setContentType(SEAT_STATE_CONTENT_TYPE.OUTCOME);
      return;
    }

    if (roundState === ROUND_STATE.DECISION_PHASE && lastDecision) {
      setContentType(SEAT_STATE_CONTENT_TYPE.DECISION);
      return;
    }

    if (cards?.length > 1) {
      setContentType(SEAT_STATE_CONTENT_TYPE.HAND_VALUE);
      return;
    }

    setContentType(null);
  }, [cards, roundState, isHandSplitted, lastDecision, outcome]);

  const isVisible = useMemo(() => {
    return cards?.length > 0;
  }, [cards?.length]);

  const isBouncing = useMemo(() => {
    return handTurn && !lastDecision;
  }, [handTurn, lastDecision]);

  if (!isVisible) return null;

  return (
    <>
      {contentType && (
        <div
          data-cy="handStateSymbol"
          className={cl(styles.handStateSymbol, getStateBackground(), isBouncing && styles.bounceAnimation)}
        >
          {contentType === SEAT_STATE_CONTENT_TYPE.OUTCOME && (
            <OutcomeContent outcome={outcomeToDisplay} roundState={roundState} />
          )}

          {contentType === SEAT_STATE_CONTENT_TYPE.HAND_VALUE && <HandValueContent cards={cards} />}

          {contentType === SEAT_STATE_CONTENT_TYPE.DECISION && roundState === ROUND_STATE.DECISION_PHASE && (
            <LastDecisionContent lastDecision={lastDecision} seatBelongsToPlayer={seatBelongsToPlayer} cards={cards} />
          )}
        </div>
      )}
    </>
  );
};

export default HandStateSymbol;
